@import "./../../../../../assets/scss/master";

.panel {
	&.account__type__option {
		background: $white;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
		text-align: left;
		font-style: normal;
		font-size: 14px;
		border-radius: 6px;
		padding: 50px 30px;
		margin-bottom: 30px;
		cursor: pointer;

		&.loading {
			cursor: not-allowed;
		}

		&:hover {
			#{$midNightselectors} {
				color: $white !important;
				background: $main-fill-normal !important;
			}
			p {
				color: $white !important;
			}
			h6:after {
				color: $white !important;
				background: $white !important;
			}
		}
		a {
			color: unset;
		}

		h6 {
			font-size: 14px;
			line-height: 21px;
			font-weight: 600;
			color: $black;
			margin-bottom: 15px;
			#{$midNightselectors} {
				color: $white !important;
			}
			&:after {
				content: "";
				width: 12px;
				height: 7.42px;
				// -webkit-mask-image: url(/static/media/sue.346f86d6.svg);
				// mask-image: url(/static/media/sue.346f86d6.svg);
				display: block;
				margin-left: auto;
				margin-top: -15px;
				transform: rotate(90deg);
				transition: all 0.5s ease;
				background: $black-medium;
				opacity: 1;
				color: $black-medium;
				position: relative;
				#{$midNightselectors} {
					color: $main-fill-normal;
					background: $main-fill-normal;
				}
			}
		}
		p {
			font-size: 12px;
			line-height: 18px;
			color: #37474f;
			margin: 0;
			#{$midNightselectors} {
				color: #c4c4c4;
			}
		}
	}
}
