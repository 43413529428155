@import "../../../assets/scss/master";

.custom-file-uploader {
	width: 100%;

	> label {
		width: 100%;

		p {
			color: #1b1b1b;
			font-size: 12px;
			margin: 0;
			margin-top: 24px;
			#{$midNightselectors} {
				color: #5e5e5e;
			}
			#{$modalMidNightselectors} {
				color: #5e5e5e;
			}

			span {
				font-family: TG-Medium;
				background: linear-gradient(
					95.09deg,
					#d0a23c -10.51%,
					#77502f 109.52%
				);
				-webkit-background-clip: text;
				color: transparent;
			}
		}

		.custom-file-uploader-file-name {
			color: #1b1b1b;
			font-size: 12px;
			text-align: center;
			#{$midNightselectors} {
				color: #5e5e5e;
			}
			#{$modalMidNightselectors} {
				color: #5e5e5e;
			}
		}
	}

	.custom-file-uploader-box {
		border: 3px dashed #eee;
		border-radius: $border-radius;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: $white;
		min-height: 192px;
		width: 100%;
		#{$midNightselectors} {
			background-color: $dark-input;
			border: 3px dashed #333333;
		}
		#{$modalMidNightselectors} {
			background-color: $dark-input;
			border: 3px dashed #333333;
		}

		&.custom-file-uploader-box-error {
			border: 3px dashed #a94442;
		}
	}

	> .error-msg {
		margin-top: 0px;
		display: block;
	}
}
