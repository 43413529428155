@import "../../../../assets/scss/master";

.airtime-summary-form {
	width: 100%;
	padding-top: 60px;
	padding-bottom: 60px;

	.airtime-summary-info {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #eee;
		padding-bottom: 32px;
		padding-top: 32px;
		#{$modalMidNightselectors} {
			border-color: #333333;
		}

		&:first-of-type {
			padding-top: 0;
		}

		span {
			color: #1b1b1b;
			#{$modalMidNightselectors} {
				color: #fff !important;
			}
		}
	}

	> button {
		text-transform: none !important;
		height: 50px;
	}
}
