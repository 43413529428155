.alert {
    border-radius: 0px;
}

.alert-dismissible .close {
    color: #000 !important;
		display: flex;
		align-items: center !important;
		justify-content: center !important;
		width: fit-content !important;
		height: fit-content !important;
		padding: 0px !important;
		top: 50% !important;
		right: 16px !important;
		transform: translateY(-50%);
}

.alert-success {
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #22806c;
    background-color: #f9fefd;
    border: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 2px 8px rgba(73, 190, 133, 0.2);
}

.alert-danger {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.25px;
    color: #fff;
    background-color: #cd1b2c;
    border: 0px;
    border-radius: 4px;
}