@import "../../../../../../assets/scss/master";

.step-card {
	border: 1px solid #ccc;
	padding: 46px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 8px;
	height: 100%;
	opacity: 1;
	// margin-right: 24px;

	// &:last-of-type {
	// 	margin-right: 0px;
	// }

	#{$midNightselectors} {
		background: $black-medium;
		box-shadow: 0px 0px 4px $black;
		border: 1px solid #212121;
	}

	&.step-card-disabled {
		opacity: 0.5;
		pointer-events: none;
		#{$midNightselectors} {
			background: #111;
			box-shadow: none;
		}
	}

	> h5 {
		font-family: TG-Medium;
		margin: 24px 0px 24px;
		text-align: center;
		font-size: 18px;
		color: $black;

		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}

	> p {
		text-align: center;
		max-width: 280px;
		font-size: 12px;
		color: $black;
		line-height: 20px;

		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}

	.step-count {
		color: var(--main-fill-normal);
		font-size: 12px;
		line-height: 20px;
		font-family: TG-Medium;
		display: block;
		margin-bottom: 42px;
		// margin-top: auto;
	}

	> button {
		max-width: 192px;
		text-transform: unset !important;
		margin-top: auto;
	}

	.step-card-status {
		color: var(--main-fill-normal);
		font-family: TG-Medium;
		font-size: 14px;
		margin-top: auto;

		&.failed {
			color: #cc0033;
		}

		&.verified {
			color: var(--main-fill-light);
		}
	}

	.failed-info {
		font-size: 12px;
		text-align: center;
		display: block;
		line-height: 1.6;
		color: $black;
		max-width: 300px;
		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}
}
