// @import "../../assets/scss/master";

.business-reg-container {
	width: 100%;
	// min-height: 100vh;
	padding: 1.5rem;

	.top-logo-container {
		display: flex;
		padding-top: 1.5rem;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.form-container {
		.form-content {
			text-align: center;
			margin-top: 1.5rem;
			> h4 {
				font-size: 18px;
				font-weight: 500;
			}
			p {
				font-size: 16px;
				color: #808080;
				line-height: 24px;
			}
		}
		.forms {
			display: flex;
			margin-top: 2rem;

			max-width: 568px;
		}
		.form-back {
			text-align: center;
			padding-top: 20px;
			cursor: pointer;
			&:hover {
				background: linear-gradient(
					95.09deg,
					#d0a23c -10.51%,
					#77502f 109.52%
				);
				-webkit-background-clip: text;
				color: transparent;
			}
		}
	}
}

.bread-crumb {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 1rem;
	max-width: 568px;
	flex-wrap: wrap;
	gap: 10px;
	.active-text {
		background: linear-gradient(95.09deg, #d0a23c -10.51%, #77502f 109.52%);
		-webkit-background-clip: text;
		color: transparent;
	}

	.normal-text {
		color: #808080;
		font-size: 16px;
		font-weight: 500;
	}
}
