@import "../../assets/scss/master";

.ncto-card-activation {
	opacity: 1;

	.ncto-main-content {
		width: 100%;
		max-width: 498px;
		padding: 89px 16px 74px;
		margin: 0px auto;

		@media (max-width: 767px) {
			padding: 32px 16px;
		}

		.ncto-header-img {
			width: 100%;
			height: 204px;
			object-fit: cover;
			border-radius: 16px;
		}

		.ncto-page-header {
			margin-top: 40px;
			width: 100%;

			h1 {
				font-size: 18px;
				font-weight: 500;
				text-align: center;
			}

			p {
				font-weight: 300;
				font-size: 16px;
				max-width: 481px;
				margin: 8px auto 0px;
				text-align: center;
				line-height: 24px;
				color: $secondary-gray;
			}
		}

		.ncto-form {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 32px;
			margin-top: 40px;
		}

		.btn-mint-default {
			height: 50px;
			border-radius: 15px;
		}

		.ncto-confirm-phone-form {
			width: 100%;
			display: flex;
			gap: 18px;
			align-items: center;
			margin-top: 40px;
			margin-bottom: 32px;

			@media (max-width: 359px) {
				justify-content: center;
				flex-wrap: wrap;
			}

			> span {
				font-size: 16px;
				font-weight: 500;
				white-space: nowrap;

				@media (max-width: 500px) {
					font-size: 13px;
				}
			}
		}

		.custom-pin-input {
			background-color: transparent;
			padding: 0px;
		}

		.pincode-input-container {
			column-gap: 18px !important;

			input {
				background: #e4e5e7 !important;
				width: 60px !important;
				height: 52px !important;
				border: 1px solid transparent !important;
				border-radius: 15px !important;
				font-size: 30px !important;
				caret-color: $main-fill-normal;
				color: $black !important;

				&:focus {
					border-color: #000000 !important;
				}

				@media (max-width: 767px) {
					flex: 1 !important;
				}

				@media (max-width: 500px) {
					width: 32px !important;
					height: 32px !important;
					border-radius: 8px !important;
					font-size: 18px !important;
				}
			}
		}

		.ncto-home-button {
			margin: 100px auto 0px;
			font-size: 16px;
			color: $black;
			width: fit-content;
			display: block;

			@media (max-width: 500px) {
				margin-top: 56px;
				font-size: 14px;
			}
		}

		.ncto-active-card-form {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 32px;
			margin-top: 40px;

			&__otp-container {
				width: 100%;

				> p {
					text-align: center;
					font-weight: 500;
					color: #111827;

					@media (max-width: 500px) {
						font-size: 14px;
					}
				}
			}

			&__otp {
				display: flex;
				gap: 10px;
				align-items: center;
				justify-content: center;

				> .custom-pin-input {
					align-items: flex-start;
					width: fit-content;
					flex-shrink: 0;
				}

				> button {
					outline: none;
					border: none;
					width: fit-content;
					height: fit-content;
					background-color: transparent;
					flex-shrink: 0;

					img {
						width: 24px;
						height: 24px;
					}
				}

				.pincode-input-container {
					padding-left: 0px !important;
					padding-right: 0px !important;
				}

				input {
					caret-color: $black;
				}
			}

			&__otp-input {
				display: block;
				margin-top: 24px;
				text-align: center;
				font-size: 14px;
			}
		}
	}
}
