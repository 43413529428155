@import "../../../assets/scss/master";

#sidebar {
	width: 250px;
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	z-index: 999;
	background: $white;
	color: $white;
	transition: all 0.3s;
	#{$midNightselectors} {
		background: $black-medium;
	}

	&.active {
		margin-left: -250px;
	}

	ul {
		&.components {
			padding: 20px 0;
		}

		li {
			&.active,
			&:hover {
				opacity: 1;
			}

			margin-bottom: 32px;

			&:last-of-type {
				margin-bottom: 0px;
			}

			a,
			p {
				font-size: 14px;
				// text-transform: capitalize;
				letter-spacing: 0.35px;
				color: $black;
				padding: 8px 20px;
				font-weight: 900;
				// padding-left: 1.6rem;
				opacity: 1;
				border-left: 4px solid transparent;
				display: flex;
				align-items: center;
				transition: opacity 1.2s;
				margin-bottom: 0;
				margin-left: 16px;

				.side-text {
					padding-top: 0px !important;
					#{$midNightselectors} {
						color: $white;
					}
				}
				.svg-icon polygon {
					#{$midNightselectors} {
						fill: $white;
					}
				}

				&:not(.active) {
					#{$midNightselectors} {
						span.side-icon {
							background-color: $white;
						}
					}
				}

				&:hover {
					opacity: 1;
					cursor: pointer;
				}

				&.active {
					color: $main-fill-normal;
					opacity: 1;
					border-radius: 15px 0px 0px 15px;
					background-color: var(--main-fill-normal);
					span {
						&.side-icon {
							background-color: $white;
							opacity: 1;
						}
						&.side-text {
							color: $white !important;
						}
					}
				}

				&.active:after {
					opacity: 1;
				}

				i {
					margin-right: 10px;
				}
			}
			// a {
			// 	&:after {
			// 		content: "";
			// 		display: block;
			// 		width: 8px;
			// 		height: 8px;
			// 		background: $main-fill-normal;
			// 		border-radius: 5px;
			// 		opacity: 0;
			// 		transition: all 0.3s;
			// 	}
			// }

			&.active > a {
				color: $black;
				background: #6d7fcc;
			}
		}

		.dropdown-toggle {
			&:after {
				content: "";
				width: 12px;
				height: 7.42px;
				// -webkit-mask-image: url(/static/media/sue.346f86d6.svg);
				// mask-image: url(/static/media/sue.346f86d6.svg);
				display: block;
				margin-left: auto;
				transform: rotate(180deg);
				transition: all 0.5s ease;
				background: $black-medium;
				opacity: 1;
				color: $black-medium;
				position: relative;
				#{$midNightselectors} {
					color: $white !important;
					background: $white;
				}
			}
			&::after {
				right: 0 !important;
			}
			&.active:after {
				transform: rotate(0deg);
				background: $main-fill-normal !important;
				color: $main-fill-normal !important;
				#{$midNightselectors} {
					background: $main-fill-normal !important;
					color: $main-fill-normal !important;
				}
			}
			&[aria-expanded="true"]:after {
				transform: rotate(0deg);
			}
		}

		.dropdown-menu {
			position: relative !important;
			top: unset !important;
			left: 0 !important;
			padding: unset !important;
			float: unset !important;
			transform: unset !important;
			top: unset !important;
			left: unset !important;
			will-change: unset !important;
			background-color: unset !important;
			border: none !important;

			.dropdown-item {
				color: $black !important;
				padding: 15px 20px 10px 55px !important;
				border-left: 4px solid transparent;
				#{$midNightselectors} {
					color: $white !important;
				}

				&:link {
					color: $black;
				}
				&:link,
				&:hover {
					background-color: unset !important;
				}

				&.active {
					color: $main-fill-normal !important;
					// background-color: rgba(208, 162, 60, 0.1) !important;
					display: flex;
					border-radius: 0 !important;
					// border-left: 4px solid $main-fill-normal;
					#{$midNightselectors} {
						color: $main-fill-normal !important;
					}

					// &:after {
					// 	content: "";
					// 	display: block;
					// 	width: 8px;
					// 	height: 8px;
					// 	background: $main-fill-normal;
					// 	border-radius: 5px;
					// 	opacity: 1;
					// 	transition: all 0.3s;
					// 	margin-left: 32px;
					// }
				}
			}

			// a {
			// 	&:after {
			// 		display: none;
			// 	}
			// }
		}
	}
}

a[aria-expanded="true"] {
	color: $black;
}

@media (max-width: 768px) {
	#sidebar {
		margin-left: -250px;

		&.active {
			margin-left: 0;
		}
	}
}
