@import "../../../../../../assets/scss/master";

.mkt-delivery-panel {
	background-color: $white;
	border: 1px solid $input-border;
	height: auto;
	// max-width: 658px;
	border-radius: 8px;
	flex: 1;
	display: flex;
	flex-direction: column;

	#{$midNightselectors} {
		background-color: $black-medium;
		border-color: transparent;
	}

	@media (max-width: 1024px) {
		margin-left: 0px;
		margin-top: 24px;
		max-width: 100%;
	}

	&__header {
		padding: 40px 16px 16px;
		border-bottom: 1px solid #eee;
		#{$midNightselectors} {
			border-color: $black-stroke;
		}

		h2 {
			margin: 0px;
			font-size: 16px;
			color: $black;
			font-family: TG-Semibold;
			#{$midNightselectors} {
				color: $white;
			}
		}
	}

	&__content {
		padding: 20px 36px 21px;
		display: flex;
		flex-direction: column;
		flex: 1;

		@media (max-width: 500px) {
			padding: 20px 16px 21px;
		}

		.mkt-address-item {
			width: 100%;
			min-height: 166px;
			border: 1px solid $input-border;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px 16px 27px;
			margin-bottom: 24px;

			#{$midNightselectors} {
				background-color: #1a1919;
				border-color: $border-medium;
			}

			&:last-of-type {
				margin-bottom: 0px;
			}

			&:hover,
			&.selected {
				border-color: $main-fill-normal !important;
			}

			&__content {
				display: flex;
				flex: 1;
				margin-top: 27px;
				margin-left: 10px;
				justify-content: space-between;

				> div {
					&:first-child {
						display: flex;
						flex-direction: column;

						h4 {
							font-size: 16px;
							font-family: TG-Bold;
							margin: 0px;
							margin-bottom: 16px;
							color: $black;
							#{$midNightselectors} {
								color: $white;
							}
						}

						p {
							font-size: 16px;
							color: $black;
							margin-bottom: 12px;
							#{$midNightselectors} {
								color: $white;
							}

							&:last-of-type {
								font-size: 12px;
								max-width: 216px;
								margin-bottom: 0px;
								margin-top: auto;
							}
						}
					}

					&:last-child {
						display: flex;
						flex-direction: column;

						button {
							padding: 0px;
							background-color: transparent;
							border: none;
							display: flex;
							align-items: center;
							width: fit-content;
							height: fit-content;
							font-size: 12px;
							color: $black;
							line-height: 0px;
							margin-left: auto;
							#{$midNightselectors} {
								color: $white;
							}

							&:first-of-type {
								svg {
									margin-left: 6px;
								}
							}

							&:last-of-type {
								margin-top: auto;
								svg {
									margin-left: 0px;
								}
							}

							svg {
								path {
									fill: $black;
									#{$midNightselectors} {
										fill: $white;
									}
								}
							}
						}
					}
				}
			}
		}

		.mkt-empty-delivery {
			height: 300px;
			text-align: center;
			color: $black;
			display: flex;
			align-items: center;
			justify-content: center;
			#{$midNightselectors} {
				color: $white;
			}
		}
	}
}

.new-address-btn {
	margin-top: 98px;
	text-transform: none !important;
	display: flex !important;
	align-items: center;
	justify-content: center;
	border-radius: 15px !important;
	height: 50px;
	line-height: 0 !important;

	svg {
		width: 16px;
		height: 16px;
		margin-right: 10px;

		@media (max-width: 768px) {
			width: 12px;
			height: 12px;
		}
	}
}

.mkt-address-form {
	overflow-y: auto;

	label {
		color: $black;
		font-family: TG-Light;
		font-size: 14px;

		#{$modalMidNightselectors} {
			color: #bbbbbb;
		}
	}

	.form-control {
		height: 50px;
		font-size: 14px;
	}

	.form-group {
		margin-bottom: 0px;
	}

	.mkt-address-modal-input {
		height: 50px;
		font-size: 14px;
		margin-bottom: 0px;

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			border-color: $dark-input !important;
		}
	}

	.react-select__control {
		height: 50px;
		outline: none;
		border-radius: 15px !important;

		#{$modalMidNightselectors} {
			border-color: $dark-input;
		}

		input {
			color: $black !important;
			#{$modalMidNightselectors} {
				color: $white !important;
			}
		}
	}

	.mkt-form-switch {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			margin: 0px;
			font-size: 15px;
			#{$modalMidNightselectors} {
				color: #69747a;
			}
		}

		.es-switch {
			height: 28px;
			width: 48px;
			.slider {
				&::before {
					width: 24px;
					height: 24px;
					top: 50%;
					left: 2px;
					transform: translateY(-50%);
					box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.251475),
						0px 2px 6px rgba(0, 0, 0, 0.404256);
				}
			}

			input {
				&:checked + .slider:before {
					left: unset;
					right: 2px;
				}
			}
		}
	}

	button {
		text-transform: none !important;
		height: 50px;
		margin-top: 0px;
	}
}
