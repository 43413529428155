@import "../../../assets/scss/master";

.ncto-activation-success-modal {
	width: 100%;
	padding: 36px 16px;

	&__success-icon {
		width: 48px;
		height: 48px;
		margin: 0 auto;
	}

	> h2 {
		color: #111827;
		font-weight: 600;
		font-size: 18px;
		text-align: center;
		margin-top: 24px;
		line-height: 24px;
	}

	> p {
		text-align: center;
		margin: 16px auto 0px;
		font-weight: 300;
		font-size: 16px;
		color: #111827;
		line-height: 24px;
		max-width: 313px;
	}

	> button {
		margin-top: 32px;
		height: 50px;
		border-radius: 15px !important;
	}
}
