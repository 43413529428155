@import "newDesignStyles/general/variables.scss";
@import "newDesignStyles/general/auth.scss";
@import "newDesignStyles/general/buttons.scss";
@import "newDesignStyles/inputs/inputs.scss";

// CSS Reset
body {
	font-family: "Inter", sans-serif !important;
	color: #111827 !important;
	background-color: #f4f4f4 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-family: inherit !important;
	color: inherit;
}

ul {
	padding: 0px;

	li {
		list-style-type: none;
	}
}

.toasts-container,
.toast,
.right-toast,
.left-toast {
	font-family: "Inter", sans-serif !important;
}
