@import "../../../../../../../assets/scss/master";

.quick-actions {
	min-height: 223px;
	border-radius: 6px;
	background: $white;
	#{$midNightselectors} {
		background: $black-medium;
		box-shadow: 0px 0px 4px $black;
	}
	padding: 22px 30px;

	h4 {
		color: var(--main-fill-normal) !important;
		margin-bottom: 35px;
	}

	.quick-actions-row {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 20px;

		@media screen and (max-width: 450px) {
			grid-template-columns: repeat(2, 1fr);
		}

		> div {
			flex-shrink: 0;

			&:first-of-type,
			&:nth-of-type(3) {
				.action-icon-wrapper {
					> span {
						width: 17px;
					}
				}
			}
		}
	}

	.action-icon {
		background-color: whitesmoke;
		transition: all 0.5s;
		height: 86px;
		border-radius: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		#{$midNightselectors} {
			background: #1a1919;
		}

		.action-icon-wrapper {
			width: 36px;
			height: 36px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			background-color: rgb(226, 220, 220);
			#{$midNightselectors} {
				background: rgba(208, 162, 60, 0.1);
			}

			> span {
				display: inline-block;
				width: 22px;
				height: 20px;
				background-color: var(--main-fill-normal);
			}
		}

		> h6 {
			color: $black;
			font-size: 12px;
			margin-top: 11px;
			margin-bottom: 0px;
			font-weight: 500;
			#{$midNightselectors} {
				color: $white;
			}
		}
	}
}
