@import "../../../../../../assets/scss/master";

.order-history-panel {
	background: $white;
	border-radius: 8px;
	padding: 40px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #eee;
	min-height: 467px;
	width: 100%;

	#{$midNightselectors} {
		background: $black-medium;
		border: 1px solid #212121;
	}
	.order-history-card {
		align-items: center;
		display: flex;
		flex-direction: row;
		border: 1px solid #eee;
		width: 100%;
		max-width: 875px;
		height: auto;
		padding: 10px 40px;
		border-radius: 5px;
		margin-bottom: 10px;
		cursor: pointer;

		#{$midNightselectors} {
			background-color: $black-light;
			border: 1px solid #212121;
		}
		.details {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;

			div {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
			}

			.title {
				font-family: TG-Light;
				color: $black;
				font-weight: 500;
				font-size: 14px;

				#{$midNightselectors} {
					color: $white;
				}
			}
			.sub-title {
				font-family: TG-Light;
				color: $black;
				font-weight: 500;
				font-size: 12px;

				#{$midNightselectors} {
					color: $white;
				}
			}
			.icon {
				margin-right: 15px;
			}
			.status-badge {
				display: flex;
				padding-top: 1px;
				padding-left: 10px;
				padding-right: 10px;
				border-radius: 2px;
				height: 20px;
				font-weight: 500;
				font-size: 12px;
				font-family: TG-Light;
				margin-top: 4px;
			}

			.pending {
				background: #999999;
				color: $white;
			}

			.processing {
				background: #359be0;
				color: $white;
			}

			.delivered {
				background: #4abe86;
				color: $white;
			}

			.cancelled {
				background: #f24a51;
				color: $white;
			}
		}
		.amount {
			font-family: TG-Light;
			color: #d0a23c;
			font-weight: 600;
			font-size: 14px;
		}
	}

	.order-loading {
		margin-top: 32px;
		min-height: 300px;
	}
}
