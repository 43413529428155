@import "../../../../../assets/scss/master";

.emergency-savings-page {
	.new-emergency-savings-btn {
		width: fit-content !important;
		height: 40px;
		font-size: 14px;
		font-family: TG-Light !important;
		text-transform: capitalize !important;
		display: flex;
		align-items: center;
		border-radius: 8px !important;

		> * {
			line-height: 0px;
		}
	}

	.es-loading {
		min-height: 400px;
		margin-top: 33px;
	}

	.es-groom {
		border-radius: 8px;
		border: 1px solid #eee;

		#{$midNightselectors} {
			border-color: $black-stroke;
		}

		.es-empty {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			padding: 20px;
			height: 100%;
			min-height: inherit;

			h3 {
				font-size: 16px;
				color: $black;
				font-family: TG-Semibold;
				text-align: center;
				margin-top: 16px;
				#{$midNightselectors} {
					color: $white;
				}
			}

			p {
				font-size: 14px;
				color: $black;
				text-align: center;
				max-width: 296px;
				margin: 16px 0px 32px;

				#{$midNightselectors} {
					color: $gray;
				}
			}

			> button {
				width: 100%;
				max-width: 300px;
				text-transform: capitalize !important;
				font-family: TG-Light;
			}
		}
	}

	.es-list-wrapper {
		margin-top: 33px;

		.es-list-horizontal-scroll {
			overflow-x: scroll;
			display: flex;

			.es-saving-item {
				border: 1px solid $gray;
				min-height: 290px;
				border-radius: 8px;
				padding: 37px 30px;
				min-width: 513px;
				// flex: 1;
				margin-right: 20px;
				cursor: pointer;
				#{$midNightselectors} {
					background-color: $black-medium;
					border-color: $black-stroke;
				}

				&.selected,
				&:hover {
					border-color: $main-fill-normal !important;
				}

				&:last-child {
					margin-right: 0px;
				}

				@media (max-width: 700px) {
					min-width: 400px;
				}

				&__header {
					display: flex;
					justify-content: space-between;
					align-items: center;

					h2 {
						color: $main-fill-normal;
						font-size: 18px;
						font-family: TG-Semibold;
						text-transform: capitalize;
						margin: 0px;
					}
				}

				&__info {
					margin-top: 49px;

					&:last-child {
						margin-top: 32px;
					}

					h4 {
						color: $black;
						font-size: 14px;
						font-family: TG-Light;
						#{$midNightselectors} {
							color: $gray;
						}
					}

					p {
						color: $black;
						font-size: 20px;
						font-family: TG-Semibold;
						margin: 0px;
						margin-top: 8px;
						#{$midNightselectors} {
							color: $white;
						}
					}
				}
			}
		}
	}

	.es-list-transactions {
		margin-top: 26px;
		border: 1px solid $gray;
		min-height: 290px;
		max-height: 450px;
		border-radius: 8px;
		padding: 26px 0px 0px;
		display: flex;
		flex-direction: column;
		#{$midNightselectors} {
			background-color: $black-medium;
			border-color: $black-stroke;
		}

		h2 {
			color: $main-fill-normal;
			font-size: 18px;
			font-family: TG-Semibold;
			padding: 0px 30px 26px;
			border-bottom: 1px solid $gray;
			#{$midNightselectors} {
				border-color: $border-medium;
			}

			@media (max-width: 400px) {
				padding: 0px 16px 26px;
			}
		}

		&__table {
			padding-bottom: 41px;
			overflow-y: scroll;
			flex: 1;

			.es-list-transactions-table-row {
				display: flex;
				justify-content: space-between;
				// align-items: center;
				margin-top: 42px;
				padding: 0px 30px;
				@media (max-width: 400px) {
					padding: 0px 16px;
				}

				> div {
					h3 {
						font-size: 18px;
						color: $black;
						font-family: TG-Semibold;
						margin: 0px;
						#{$midNightselectors} {
							color: $white;
						}
					}

					p {
						font-family: TG-Semibold;
						font-size: 12px;
						color: $gray;
						margin: 8px 0px;
						#{$midNightselectors} {
							color: rgba(255, 255, 255, 0.4);
						}
					}

					&:last-child {
						display: flex;
						flex-direction: column;
						align-items: center;

						p {
							font-size: 18px;
							font-family: TG-Semibold;
							border-left: none !important;
							margin: 0px;
							margin-bottom: 8px;
							line-height: 1.1;

							&.credit {
								color: $credit;
							}

							&.debit {
								color: $debit;
							}
						}

						> span {
							width: 86px;
							height: 28px;
							border-radius: 8px;
							border: 1px solid transparent !important;
							color: $white;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 10px;
							font-family: TG-Semibold;

							&.success {
								background-color: $credit;
							}

							&.failed {
								background-color: #cc2500;
							}

							&.pending {
								background-color: $main-fill-normal;
							}
						}
					}
				}
			}
		}
	}

	.es-details-container {
		display: flex;

		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.es-saving-card {
			background-color: $white;
			border: 1px solid #eee;
			height: 100%;
			max-height: 450px;
			border-radius: 8px;
			padding: 60px 30px;
			flex: 1;
			max-width: 50%;
			#{$midNightselectors} {
				background-color: $black-medium;
				border-color: transparent;
			}

			@media (max-width: 1024px) {
				max-width: 100%;
				max-height: 100%;
			}

			@media (max-width: 700px) {
				padding: 30px 20px;
			}

			&__header {
				display: flex;
				align-items: center;
				margin-bottom: 45px;

				> span {
					display: flex;
					width: 64px;
					height: 64px;
					border-radius: 50%;
					background-color: #d03c6a;
					align-items: center;
					justify-content: center;
					margin-right: 16px;
				}

				h3 {
					color: $black;
					font-size: 24px;
					font-family: TG-Semibold;
					margin: 0px;
					margin-top: 12px;
					#{$midNightselectors} {
						color: $white;
					}
				}
			}

			> .d-flex {
				p {
					font-size: 14px;
					margin: 0px;
					color: $black;
					#{$midNightselectors} {
						color: $white;
					}
				}

				h4 {
					color: $black;
					font-size: 20px;
					font-family: TG-Semibold;
					margin: 0px;
					margin-top: 8px;
					#{$midNightselectors} {
						color: $white;
					}
				}

				.es-switch {
					height: 28px;
					width: 48px;
					.slider {
						&::before {
							width: 24px;
							height: 24px;
							top: 50%;
							left: 2px;
							transform: translateY(-50%);
							box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.251475),
								0px 2px 6px rgba(0, 0, 0, 0.404256);
						}
					}

					input {
						&:checked + .slider:before {
							left: unset;
							right: 2px;
						}
					}
				}
			}

			&__footer {
				display: flex;
				margin-top: 34px;
				width: 100%;
				align-items: center;
				justify-content: space-between;

				@media (max-width: 400px) {
					flex-direction: column;
				}

				> button {
					width: 49%;
					@media (max-width: 400px) {
						width: 100%;
					}

					&:first-of-type {
						@media (max-width: 400px) {
							margin-bottom: 10px;
						}

						&::after {
							content: "";
							width: 13px;
							height: 13px;
							margin-left: 15px;
							flex-shrink: 0;
							// margin-bottom: 2px;
							background: url("../../../../../assets/img/white_plus_icon.svg")
								no-repeat center;
							background-size: cover;
							#{$midNightselectors} {
								background: url("../../../../../assets/img/plus_black.svg")
									no-repeat center;
							}
						}
					}
				}
			}
		}

		.es-list-transactions {
			flex: 1;
			margin-top: 0px;
			margin-left: 21px;
			border: 1px solid #eee;
			max-height: 450px;
			background-color: $white;
			display: flex;
			flex-direction: column;
			padding-bottom: 0px;
			#{$midNightselectors} {
				background-color: $black-medium;
				border-color: $black-stroke;
			}

			@media (max-width: 1024px) {
				margin-left: 0px;
				margin-top: 21px;
			}

			h2 {
				border-color: #eee;
				#{$midNightselectors} {
					border-color: $border-medium;
				}
			}

			&__table {
				overflow-y: auto;
				flex: 1;

				~ div {
					height: 59px;
					padding-top: 0px;
					padding-bottom: 0px;
				}
			}
		}
	}
}

.es-automatic-deposit {
	width: 100%;
	padding-top: 32px;
	padding-bottom: 32px;

	p {
		font-size: 18px;
		color: $black;
		#{$modalMidNightselectors} {
			color: $gray;
		}
	}

	button {
		border-radius: $border-radius !important;
		text-transform: capitalize !important;
	}

	&__footer {
		margin-top: 48px;
		display: flex;
		justify-content: space-between;

		> button {
			width: 49% !important;
			text-transform: capitalize !important;
			border-radius: 15px !important;
			height: 50px !important;

			&:last-child {
				background-color: $dark-input !important;
				border: 1px solid transparent;
			}
		}
	}

	form {
		width: 100%;
		padding: 40px 0px;

		label,
		.custom-control-label {
			color: $black !important;
			font-family: TG-Light;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: $white !important;
				border-color: transparent !important;
			}
		}

		.form-control {
			height: 50px;
			font-size: 14px;
		}

		.form-group {
			margin-bottom: 0px;
		}

		.es-input {
			height: 50px;
			font-size: 14px;
			margin-bottom: 0px;

			#{$modalMidNightselectors} {
				background-color: $dark-input !important;
				color: $white !important;
				border-color: transparent !important;
			}

			&.has-error {
				border-color: #a94442 !important;
			}

			&.disabled {
				cursor: not-allowed;
			}
		}

		.es-frequency {
			display: flex;
			width: 100%;
			justify-content: space-between;

			@media (max-width: 899px) {
				flex-wrap: wrap;
			}

			> * {
				flex: 1;

				&:last-child {
					margin-right: 0px;
					margin-bottom: 0px;
				}

				@media (max-width: 400px) {
					flex: auto;
					margin-right: 0px;
				}

				@media (min-width: 900px) {
					margin-bottom: 0px;
				}
			}
		}

		> button {
			border-radius: 15px !important;
			margin-top: 32px;
			height: 50px !important;
			text-transform: capitalize !important;
		}
	}

	&.funding {
		h2 {
			color: $black;
			font-size: 18px;
			#{$modalMidNightselectors} {
				color: $gray;
			}
		}

		form {
			padding-bottom: 20px;
		}
	}

	&__debit-account {
		display: flex;
		align-items: center;
		padding: 23px 30px;
		border-radius: $border-radius;
		background-color: $white;
		border: 1px solid $gray;
		margin-top: 54px;
		margin-bottom: 48px;
		cursor: pointer;
		#{$modalMidNightselectors} {
			background-color: $dark-input;
			border-color: transparent;
		}

		&:hover,
		&.selected {
			border-color: $main-fill-normal !important;
		}

		@media (max-width: 600px) {
			padding: 20px;
		}

		h4 {
			font-size: 14px;
			color: $black;
			margin: 0px;
			font-family: TG-Medium;
			#{$modalMidNightselectors} {
				color: $white;
			}
		}

		p {
			color: $black;
			font-size: 14px;
			margin: 0px;
			margin-top: 12px;
			#{$modalMidNightselectors} {
				color: $gray;
			}
		}
	}
}

.es-success-modal {
	margin-top: 24px;
	width: 100%;
	max-width: 220px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding: 20px 16px 41px;
	text-align: center;

	&.showCancelBtn {
		max-width: 384px;
	}

	> h3 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 308px;
		margin-top: 24px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	&__footer {
		width: 100%;
		margin-top: 32px;
		display: flex;
		justify-content: space-between;

		> button {
			flex: 1;
			text-transform: capitalize !important;
			border-radius: 15px !important;
			height: 50px !important;
		}
	}
}
