@import "./../../../../../assets/scss/master";

.basic__info {
	.check-container input:checked ~ .mycheck {
		background-color: #3e62be !important;
		border-color: #3e62be !important;
	}
	.mycheck {
		border: 1px solid $white !important;
		border-radius: 4px !important;
		&:nth-of-type(1) {
			margin-right: 5px;
		}
	}
}
