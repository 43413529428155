@import "../../../../../../../assets/scss/master";

.cart-view {
  display: flex;
  flex-direction: column;

  &__content {
    .cv-top-section{
      display: flex;
      flex-direction: column;
      >h4 {
        font-weight: 600;
        font-size: 18px;
        font-family: TG-Light;

        #{$midNightselectors} {
            color:$white;
          }
      }
      >.sub-text{
        font-family: TG-Light;
        font-weight: 300;
        font-size: 13px;

        #{$midNightselectors} {
            color: $white;
          }
          >span{
            font-weight: 600;
          }
      }
      .member-icon {
        position: relative;
        height: 48px;
        width: 48px;
        padding-top: 10px ;
        display: flex;
        flex-direction: column;
        border-radius: 100px;
        border: 2px solid #D1A23C;

        >p{
          text-align: center;
          font-family: TG-Light;
          font-weight: 600;
          font-size: 15px;
          #{$midNightselectors} {
              color: $white;
            }
        }
        .logo{
          position: absolute;
          bottom: 0;
          right: -4px;
          height: 15px;
        }
      }
      
    }
    .cv-content-section{
      display: flex;
      flex-direction: row;
      align-items: flex-start;


      @media (max-width: 1149px) {
          flex-direction: column;
          align-items: flex-start;
      }
          
      &__content {
        .cv-items-details{
          border-radius: 8px;
          background-color: $white;
          border: 1px solid $input-border;
          display: flex;
          flex: 1;
          flex-direction: column;
          #{$midNightselectors} {
            background-color: $black-medium;
            border-color: transparent;
          }

          @media (max-width: 1149px) {
            margin-left: 0px;
            width: 100%;
            flex-direction: column;
            margin-top: 24px;
            max-width: 100%;
            
          }
          &__header {
            padding: 40px 50px 16px;
            
            border-bottom: 1px solid #eee;

            #{$midNightselectors} {
              border-color: $black-stroke;
            }

            h2 {
              margin: 0px;
              font-size: 16px;
              color: $black;
              font-family: TG-Semibold;

              #{$midNightselectors} {
                color: $white;
              }
            }
          }
          &__content {
            padding: 18px 18px 18px;
            max-height: 600px;
            overflow-y: auto;

              .cv-items-details-card {
                display: flex;
                flex: 1;
                padding: 10px 17px;
                border-radius: 4px;
                flex-direction: column;
                border: 1px solid #eee;
                margin-bottom: 15px;
  
                #{$midNightselectors} {
                  background-color: #1A1919;
                  border-color: transparent;
                }

            
              }
              .product-container{
                display: flex;
                flex-direction: row;
                position: relative;
                 align-items: center;

                >.product {
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  justify-items: flex-end;
                  align-items: center;
                  >.img {
                      width: 64px;
                      height: 64px;
                     
                      border-radius: 4px;
                      border: 1px solid #eee;
                  
                      #{$midNightselectors} {
                      border: 1px solid $input-border;
                      }
                      >img{
                        object-fit: contain;
                        width: 100%;
                        height:100%;
                        border-radius: 4px;
                      }
                  
                    }

                    >.product-info-container{
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      flex:1;
                      margin-left: 16px;

                      @media (max-width: 400px) {
                          display: flex;
                          flex-direction: column;
                          align-items: flex-start;
                        }

                      .price {
                          max-width: 130px;
                          flex-direction: row;
                          display: flex;
                          font-family: TG-Light;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 16px;
                      
                          #{$midNightselectors} {
                            color: $white;
                          }
                      
                          >.amount {
                            color: #D1A23C;
                            font-weight: 600;
                            margin-right: 20px;
                            font-size: 14px;

                            @media (max-width: 400px) {
                              margin-top: 8px;
                            }
                          }
                        }
                        .product-info {
                           
                            flex-direction: column;
                            display: flex;
                            flex: 1;
                            justify-content: space-between;
                            align-items: flex-start;
                        
                        
                            >p {
                              padding: 0;
                              margin: 0;
                              margin-bottom: 10px;
                              font-family: TG-Bold;
                              font-weight: 700;
                              font-size: 14px;
                              line-height: 14px;
                              max-width: 300px;
                        
                              #{$midNightselectors} {
                                color: $white;
                              }
                            }
                        
                           
                          }
                    }
                }
                >.icon {
                  cursor: pointer;
                }
              }
              .dropdown-container{
                display: flex;
                flex-direction: column;
               >div{
                padding-bottom: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-bottom-style: dashed;
                border-bottom:2px solid $input-border;

                #{$midNightselectors} {
                    border-bottom:2px solid $white;
                    border-bottom-style: dashed;
                  }
                >.members {
                  display: flex;
                  align-items: center;
                  flex-direction: row;
                    p {
                      margin: 0;
                      padding: 0;
                      font-family: TG-Light;
                      font-weight: 500;
                      font-size: 12px;
                      #{$midNightselectors} {
                        color: $white;
                      }
                    }
                    .user-name {
                      padding-left: 12px;
                    }

                    .member-icon {
                      position: relative;
                      height: 48px;
                      width: 48px;
                      padding-top: 10px;
                      display: flex;
                      flex-direction: column;
                      border-radius: 100px;
                      border: 2px solid #D1A23C;

                      >p {
                        text-align: center;
                        font-family: TG-Light;
                        font-weight: 600;
                        font-size: 15px;

                        #{$midNightselectors} {
                          color: $white;
                        }
                      }
                      .logo {
                        position: absolute;
                        bottom: 0;
                        right: -4px;
                        height: 15px;
                      }
                    }
                    
                  }
                  >.price-details{
                    >p{
                      margin: 0;
                      padding: 0;
                      font-family: TG-Light;
                      font-weight: 600;
                      font-size: 12px;
                    
                      #{$midNightselectors} {
                        color: $white;
                      }
                    }
                    .percentage{
                      font-weight: 400;
                      color:#D1A23C !important;
                    }

                  }
               }
              }
          }
          
        }

      .cv-summary-details {
        border-radius: 8px;
        margin-left: 24px;
        display: flex;
        flex: 1;
        padding: 00px 17px;
        background-color: $white;
        border: 1px solid $input-border;
        flex-direction: column;
        max-width: 402px;

        #{$midNightselectors} {
          background-color: $black-medium;
          border-color: transparent;
        }
        @media (max-width: 1149px) {
          margin-left: 0px;
          width: 100%;
          flex-direction: column;
          margin-top: 24px;
          max-width: 100%;
        }
        &__header {
          padding: 40px 30px 16px;
          >p{
            margin: 0px;
            font-size: 12px;
            color: $black;
            font-family: TG-Light;
          
            #{$midNightselectors} {
              color: $white;
            }
          }
         >div{
          flex-direction: row;
            display: block;
            justify-content: space-between;
          h2 {
              margin: 0px;
              font-size: 16px;
              color: $black;
              font-family: TG-Semibold;
          
              #{$midNightselectors} {
                color: $white;
              }
            }
         }
          .status-badge {
            display: flex;
            padding-top: 1px;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 2px;
            height: 20px;
            font-weight: 500;
            font-size: 12px;
            font-family: TG-Light;
            margin-top: 4px;
          }

          .pending {
            background: #999999;
            color: $white;
          }

          .processing {
            background: #359BE0;
            color: $white;
          }

          .delivered {
            background: #4ABE86;
            color: $white;
          }

          .cancelled {
            background: #F24A51;
            color: $white;
          }
        }

        &__content {
          .cost-summary {
            display: flex;
            flex: 1;
            padding: 10px 17px;
            border-radius: 4px;
            flex-direction: column;
            border: 1px solid #eee;
            margin-bottom: 30px;
          
            #{$midNightselectors} {
              background-color: #1A1919;
              border-color: transparent;
            }
           >.cost-summary-items{
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            padding: 10px 0px;
            font-weight: 400;
            font-size: 14px;
            font-family: TG-Light;
            #{$midNightselectors} {
              color: $white;
            }

            >p{
              margin: 0;
              padding: 0;
            }
           }
           .final-cost{
            font-size: 15px;
            font-weight: 700;
           }
          }
          .address-summary {
            display: flex;
            flex: 1;
            padding: 20px 17px;
            border-radius: 4px;
            flex-direction: row;
            border: 1px solid #eee;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 14px;
            font-family: TG-Light;
          
            #{$midNightselectors} {
              background-color: #1A1919;
              border-color: transparent;
              color: $white;
            }

            >p {
              margin: 0;
              margin-right: 10px;
              padding: 0;
            }
            >.d-address{
              display: flex;
              flex-direction: column;
              flex: 1;
              word-wrap: break-word;
              margin-top: 0px;
            }
          }
        }
       
      }
      }
    }

  }
}