@import "../../../../../../../assets/scss/master";

.dashboard-transactions {
	min-height: 190px;
	border-radius: 8px;
	background: $white;
	#{$midNightselectors} {
		background: $black-medium;
		box-shadow: 0px 0px 4px $black;
	}
	padding: 22px 30px;
	display: flex;
	flex-direction: column;

	> h4 {
		color: var(--main-fill-normal) !important;
		margin-bottom: 12px;
	}

	.dashboard-transactions-content {
		flex: 1;
		display: flex;
		flex-direction: column;

		.dashboard-transaction-loader {
			> div {
				font: bolder 64px "TG-Medium" !important;
			}
		}

		.dashboard-transaction-error {
			display: flex;
			align-items: center;
			flex-direction: column;
			flex: 1;
			justify-content: center;

			> h6 {
				color: #9ba6af;
				#{$midNightselectors} {
					color: var(--gray-fill-light);
				}
			}

			> button {
				padding: 0px;
				width: auto;
			}
		}

		.dashboard-transaction-grid {
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(194px, 1fr));
			gap: 8px;
		}
	}
}
