@import "../../../../../../assets/scss/master";

.setup-disclaimer {
	border: 2px solid var(--main-fill-normal);
	padding: 16px 18px;
	width: 100%;
	border-radius: 8px;

	h4 {
		font-size: 14px;
		color: #1b1b1b;
		margin: 0px;
		line-height: 24px;
		#{$midNightselectors} {
			color: #bbbbbb;
		}
		#{$modalMidNightselectors} {
			color: #bbbbbb;
		}
	}

	p {
		font-size: 14px;
		color: #1b1b1b;
		line-height: 24px;
		margin: 0px;
		#{$midNightselectors} {
			color: #bbbbbb;
		}
		#{$modalMidNightselectors} {
			color: #bbbbbb;
		}
	}
}
