@import "../../../../../../../assets/scss/master";

.account-setup-banner {
	background-color: rgba(248, 248, 248, 0.9);
	border: 1px solid var(--main-fill-normal);
	border-radius: $border-radius;
	padding: 16px 19px;
	display: flex;
	align-items: baseline;
	justify-content: center;
	cursor: pointer;

	@media (max-width: 445px) {
		align-items: center;
	}

	.account-setup-info-icon {
		margin-right: 8px;
		flex-shrink: 0;
	}

	> span {
		font-size: 12px;
		color: $black;
		font-family: TG-Medium;
		display: block;
	}

	.account-setup-right-icon {
		margin-left: 8px;
		flex-shrink: 0;
	}
}
