@import "../../../../../../assets/scss/master";

.mkt-cart {
	> h1 {
		font-size: 18px;
		font-family: TG-Semibold;
		color: $black;
		margin: 0px;
		#{$midNightselectors} {
			color: $gray;
		}
	}

	> p {
		margin: 0px;
		margin-top: 4px;
		font-size: 12px;
		color: $black;
		#{$midNightselectors} {
			color: $secondary-gray;
		}
	}

	&__content {
		display: flex;
		margin-top: 24px;

		@media (max-width: 1024px) {
			display: block;
		}

		.mkt-cart-items {
			flex: 1;
		}
	}

	.mkt-loading {
		min-height: 300px;
		margin-top: 24px;
	}

	.mkt-error-loading {
		> div {
			max-width: 318px;
		}
	}
		.mkt-empty-state {
			padding:100px ;
			background-color: $white;
				border: 1px solid $input-border;
				height: auto;
				border-radius: 8px;
					#{$midNightselectors} {
						background-color:$black-medium;
						border-color: transparent;
				}
			>h6 {
				font-size: 16px;
				color: $black;
				font-weight: 600;
				#{$midNightselectors} {
						color: $white;
				}
			}
			>span {
				text-align: center;
				font-size: 14px;
				color: $black;
				font-weight: 400;
				margin-bottom: 20px;
			
				#{$midNightselectors} {
					color: #BBBBBB;
				}
			}
			>button {
				width:300px;
				margin: 1px auto;
			}
		}
}
