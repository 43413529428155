@import "../../../../../assets/scss/master";

.progressTimeline {
	position: relative;
	list-style: none;
	padding: 0px;

	.roundIcon {
		circle,
		path.sekkle {
			#{$midNightselectors} {
				fill: $black;
			}
		}
	}

	li {
		p {
			font-size: 13px;
			font-family: TG-Medium;
			font-weight: 100;
			letter-spacing: 0.25px;
			color: #37474f;
			margin-bottom: 0px;
			#{$midNightselectors} {
				color: $white;
			}
		}
	}

	> li:not(:last-child):after {
		display: block;
		content: "";
		width: 4px;
		height: 40px;
		border-radius: 8px;
		background: $main-fill-normal;
		z-index: 1;
		margin: 8px 0px;
		margin-left: 13px;
		#{$midNightselectors} {
			background: $black;
		}
	}
}

.link-prev {
	width: 100%;
	height: 100%;
	opacity: 0.8;
	font-family: "TG-Medium";
	font-weight: 100;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #37474f;
	cursor: pointer;
	#{$midNightselectors} {
		color: $white;
	}
}
