@import "../../../assets/scss/master";

.confirm-back-modal {
	width: 100%;
	padding: 36px 16px;

	h3 {
		font-size: 18px;
		font-weight: 600;
	}

	p {
		margin-top: 16px;
		font-size: 16px;
		line-height: 24px;
	}

	&__footer {
		display: flex;
		margin-top: 32px;
		gap: 16px;

		> button {
			flex: 1;
		}
	}
}
