@import "../../assets/scss/master";

.reg-business {
	opacity: 1;
	// margin-top: 88px;

	display: flex;
	flex-direction: column;
	#{$midNightselectors} {
		background-color: black;
	}

	height: 100%;

	.start {
		display: flex;
		flex-direction: column;
		width: 360px;
		margin: 0px auto;
		margin-top: 100px;

		#{$midNightselectors} {
			background: black;
			color: $white;
		}

		.top {
			text-align: center;
			margin-bottom: 15px;
			> h3 {
				margin-top: 20px;
				font-size: 32px;
				color: $black;
				font-weight: 600;
				font-family: TG-Light;
				text-align: center;
				#{$midNightselectors} {
					color: $white;
				}
			}
			> .sub-text {
				font-family: TG-Light;
				font-weight: 400;
				font-size: 16px;
				text-align: center;

				#{$midNightselectors} {
					color: #c4c4c4;
				}
			}
		}
		button {
			border-radius: 15px;
			height: 54px;
		}
	}

	.business-type {
		width: 100%;
		display: flex;
		height: max-content;
		flex-direction: column;

		flex: 1;
		margin: 0px auto;
		background: #f5f5f5 !important;

		#{$midNightselectors} {
			background: $black !important;
		}

		> .inner-div {
			width: 100%;
			display: flex;
			height: max-content;
			flex-direction: column;
			max-width: 991px;
			flex: 1;
			margin: 0px auto;
		}

		#{$midNightselectors} {
			background: $black;
			color: $white;
		}
		@media (max-width: 400px) {
			margin: 0px auto;
			padding: 0 32px;
		}

		@media (max-width: 991px) {
			margin: 0px auto;
			padding: 0 32px;
		}

		.back-btn-div {
			margin-top: 40px;
			text-align: center;

			> h4 {
				margin-top: 50px;
				font-size: 18px;
				color: $black;
				font-weight: 600;
				font-family: TG-Light;

				#{$midNightselectors} {
					color: $white;
				}
			}

			> p {
				font-family: TG-Light;
				font-weight: 400;
				font-size: 14px;

				#{$midNightselectors} {
					color: #c4c4c4;
				}
			}
		}
		.type-list {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			flex-direction: row;
			padding-top: 20px;
			> .type-card {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
				padding: 24px 16px;
				gap: 12px;
				width: 320px;
				// height: 398px;
				border: 1px solid transparent;
				border-radius: 8px;
				background: $white;
				box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
					0px 1px 2px -1px rgba(16, 24, 40, 0.1);

				#{$midNightselectors} {
					background-color: black;
					border: 1px solid $main-fill-normal;
				}

				@media (max-width: 991px) {
					margin: 0px auto;
					margin-bottom: 50px;
					padding: 24 32px;
				}

				.icon-wrapper {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					.icon {
						display: flex;
						flex-direction: column;
						align-items: center;
						// justify-content: center;
						padding-top: 4px;
						height: 36px;
						width: 36px;
						border-radius: 100px;
						background: #f5f5f5;

						#{$midNightselectors} {
							background: #393536;
						}
					}
				}

				> .type-card-wrapper {
					> h4 {
						margin-top: 12px;
						font-size: 16px;
						color: $black;
						font-weight: 600;
						font-family: TG-Light;
						text-align: center;

						#{$midNightselectors} {
							color: $white;
						}
					}
					> p {
						font-family: TG-Light;
						font-weight: 400;
						font-size: 14px;
						text-align: center;
						color: #757575;
						#{$midNightselectors} {
							color: #c4c4c4;
						}
					}
					> .fee {
						background-color: #f2f4f6;
						padding: 0.4rem 0.6rem;
						border-radius: 0.5rem;
						margin-bottom: 0.7rem;
						display: flex;
						justify-content: space-between;
						flex-direction: row;
						font-size: 16px;
						color: #37474f;
						#{$midNightselectors} {
							color: #dddddd;
						}
						.text {
							font-family: TG-Light;
							font-weight: 400;
							margin: 0;
							padding: 0;
							color: #808080;
						}
						.cost {
							font-family: TG-Semibold;
							font-weight: 600;
							margin: 0;
							padding: 0;
						}
					}
					> .hint {
						display: flex;
						flex-direction: row;
						> p {
							padding-left: 10px;
							font-size: 12px;
							font-style: italic;
							color: $black;
							font-weight: 400;
							font-family: TG-Light;

							#{$midNightselectors} {
								color: $white;
							}
						}
					}
				}

				button {
					width: 100%;
					// max-width: 240px;
					border-radius: 15px;
					height: 54px;
					background-color: $black;
					#{$midNightselectors} {
						background-color: $main-fill-normal;
					}
				}
			}
		}
	}

	.reg-layout {
		height: 100%;
		display: flex;
		flex-direction: row;
		position: relative;
		flex: 1;

		> .left-step {
			position: relative;
			padding-top: 100px;
			width: 100%;
			height: 100%;
			max-width: 464px;

			background: #ffffff;

			#{$midNightselectors} {
				background: #111111;
				color: $white !important;
			}

			@media (max-width: 600px) {
				margin-left: 0px !important;
				padding-top: -20px;
				display: none;
				max-width: 320px;
			}

			@media (max-width: 991px) {
				margin-left: 0px !important;
				padding-top: -20px;
				max-width: 360px;
			}

			> .logo {
				text-align: center;
				margin-bottom: 50px;
			}
			> .done {
				border-color: $main-fill-normal !important ;

				#{$midNightselectors} {
					border-color: $main-fill-normal !important ;
				}
			}

			.is-active {
				border-color: #e5e5e5 !important;
				#{$midNightselectors} {
					border-color: $white !important ;
				}
			}

			> .step {
				flex-direction: row;
				display: flex;
				align-items: flex-start;
				max-width: 320px;
				border-left: 2px solid;
				margin: 0 auto;
				height: 90px;

				&:last-child {
					border-left: 0px solid;
				}
				> .circle {
					text-align: center;
					height: 30px;
					width: 30px;
					object-fit: contain;
					margin-left: -16px;
					border-radius: 50px;
					border: 2px solid #e5e5e5;
					background: $white;

					#{$midNightselectors} {
						border: 2px solid white;
						background: #111111;
					}
				}
				> .active {
					border-color: $main-fill-normal;

					#{$midNightselectors} {
						border-color: $main-fill-normal;
					}
				}

				> .complected {
					border-color: $main-fill-normal;
					background: $main-fill-normal;

					#{$midNightselectors} {
						border-color: $main-fill-normal;
						background: $main-fill-normal;
					}
				}

				> .details {
					padding-left: 20px;
					flex: 1;
					> h3 {
						margin: 0;
						font-size: 20px;
						color: $black;
						font-weight: 600;
						font-family: TG-Semibold;
						color: $black;

						#{$midNightselectors} {
							color: $white;
						}
					}
					> .sub-title {
						margin: 0;
						font-size: 14px;
						color: $black;
						font-weight: 400;
						font-family: TG-Light;
						color: #808080;
						#{$midNightselectors} {
							color: #808080;
						}
					}
				}
			}
		}
		> .right-forms {
			display: flex;
			flex-direction: column;
			padding-top: 30px;
			padding-bottom: 50px;
			max-height: max-content;
			overflow-y: auto;
			flex: 1;
			height: 100%;
			background: #f5f5f5;

			#{$midNightselectors} {
				background: black;
				color: $white;
			}
			.content-container {
				padding: 30px 20px;
				padding-bottom: 10px;
				background-color: #f9f9f9;
				border: 1px solid #dfdfdf;
				border-radius: 8px;
				flex-direction: column;
				display: flex;
				width: 100%;
				margin: 0 auto;
				max-width: 379px;

				#{$midNightselectors} {
					background-color: transparent;
					border: 1px solid transparent;
				}
				> .forms {
					padding-top: 30px;
					.header {
						margin-bottom: 30px;
						> h4 {
							margin-top: 10px;
							font-size: 18px;
							color: $black;
							font-weight: 600;
							font-family: TG-Light;

							#{$midNightselectors} {
								color: $white;
							}
						}
						> p {
							font-family: TG-Light;
							font-weight: 400;
							font-size: 14px;
							margin-bottom: 30px;
							color: #757575;

							#{$midNightselectors} {
								color: #c4c4c4;
							}
						}
					}
				}
			}
		}
	}
}

.back-home {
	padding-bottom: 20px;
	font-size: 16px;
	// max-width: 100px;
	margin: 0 auto;
	margin-top: 50px;
	cursor: pointer;
	color: $black;
	font-weight: 400;
	font-family: TG-Light;
	text-align: center;
	transition: all 350ms;

	&:hover {
		background: linear-gradient(95.09deg, #d0a23c -10.51%, #77502f 109.52%);
		-webkit-background-clip: text;
		color: transparent;
	}

	#{$midNightselectors} {
		color: $white;
	}

	#{$modalMidNightselectors} {
		color: $white;
	}
}

.back-home-modal {
	font-size: 14px;
	max-width: 200px;
	margin: 0 auto;
	margin-top: 15px;
	cursor: pointer;
	color: $black;
	font-weight: 600;
	font-family: TG-Light;
	text-align: center;

	&:hover {
		color: #77502f;
	}

	#{$midNightselectors} {
		color: $white;
	}

	#{$modalMidNightselectors} {
		color: $white;
	}
}

.reg-business-form {
	label {
		color: $black;
		font-family: TG-Light;
		font-size: 14px;

		#{$midNightselectors} {
			color: $white;
		}
	}
	.remove-upload {
		width: 100%;
		// border: solid 2px;
		border-radius: 8px;
		height: 45px;
		// border-color: #dfdfdf !important;
		background-color: #fff;

		span {
			background: linear-gradient(
				95.09deg,
				#d0a23c -10.51%,
				#77502f 109.52%
			);
			-webkit-background-clip: text;
			color: transparent;
		}

		#{$midNightselectors} {
			border-color: $dark-input !important;
			color: $white !important;
		}
	}
	.text-area {
		height: 100px !important;
		max-height: 100px;
	}

	.form-control {
		height: 50px;
		font-size: 14px;
	}

	.form-group {
		margin-bottom: 0px;
	}
	.hint {
		display: flex;
		flex-direction: row;
		> p {
			margin: 0;
			padding-left: 10px;
			font-size: 12px;
			padding-top: 5px;
			color: #757575;
		}
	}

	.red-business-input {
		height: 50px;
		font-size: 14px;
		margin-bottom: 0px;

		#{$midNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			border-color: $dark-input !important;
		}
	}

	.react-select__control {
		height: 50px;
		outline: none;
		border-radius: 15px !important;

		#{$midNightselectors} {
			border-color: $dark-input;
		}

		input {
			color: $black !important;
			#{$midNightselectors} {
				color: $white !important;
				background-color: $dark-input !important;
			}
		}
	}
	button {
		border-radius: 15px;
		height: 54px;
	}
}

.reg-business-form-modal {
	.back {
		font-family: TG-Light;
		font-weight: 400;
		font-size: 14px;
		text-align: center;
		cursor: pointer;

		#{$modalMidNightselectors} {
			color: $white;
		}
	}
	label {
		color: $black;
		font-family: TG-Light;
		font-size: 14px;

		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	.text-area {
		height: 100px !important;
		max-height: 100px;
	}

	.form-control {
		height: 50px;
		font-size: 14px;
	}

	.form-group {
		margin-bottom: 0px;
	}

	.hint {
		display: flex;
		flex-direction: row;

		> p {
			margin: 0;
			padding-left: 10px;
			font-size: 12px;
			padding-top: 5px;
			color: #757575;
		}
	}

	.red-business-input {
		height: 50px;
		font-size: 14px;
		margin-bottom: 0px;

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			border-color: $dark-input !important;
		}
	}

	.react-select__control {
		height: 50px;
		outline: none;
		border-radius: 15px !important;

		#{$modalMidNightselectors} {
			border-color: $dark-input;
		}

		input {
			color: $black !important;

			#{$modalMidNightselectors} {
				color: $white !important;
				background-color: $dark-input !important;
			}
		}
	}

	button {
		width: 100%;
		border-radius: 15px;
		height: 54px;
	}
}

.terms-modal {
	padding: 20px 40px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	button {
		height: 50px;
		font-weight: 400 !important;
		text-transform: capitalize !important;
	}
	> .content {
		// height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		> .modal-title {
			text-align: center;
			padding-bottom: 2rem;
			> .title-header {
				font-size: 16px;
				font-weight: 600;
				text-align: center;
				color: #111827;
				#{$modalMidNightselectors} {
					color: $white;
				}
			}
			> p {
				font-family: TG-Light;
				font-weight: 400;
				font-size: 14px;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}

		.custom-check {
			padding-left: 20px !important;

			.custom-control-label:before {
				#{$modalMidNightselectors} {
					border-color: #fff !important;
				}
			}

			.custom-control-input:checked ~ .custom-control-label::before {
				#{$modalMidNightselectors} {
					border: 1px solid $main-fill-normal !important;
					background-color: transparent !important;
				}
			}

			label {
				color: $black !important;
				font-family: TG-Light;
				font-weight: 400 !important;
				font-size: 12px;
				> a {
					// color: $main-fill-normal !important;

					background: linear-gradient(
						95.09deg,
						#d0a23c -10.51%,
						#77502f 109.52%
					);
					-webkit-background-clip: text;
					color: transparent;

					#{$modalMidNightselectors} {
						color: $main-fill-normal !important;
					}
				}
			}
		}
	}
}

.processing-fee-modal {
	padding: 20px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background: #f9f9f9;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}

	.red-business-input {
		height: 50px;
		font-size: 14px;
		margin-bottom: 0px;

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			border-color: $dark-input !important;
		}
	}
	button {
		margin-bottom: 25px;
		height: 50px;
		border-radius: 15px !important;
		font-weight: 400 !important;
		background-color: #e9e5e0 !important;

		#{$modalMidNightselectors} {
			// color: $main-fill-normal !important;
			border: none;
			background-color: #282727 !important;
		}

		> span {
			background: linear-gradient(
				95.09deg,
				#d0a23c -10.51%,
				#77502f 109.52%
			);
			-webkit-background-clip: text;
			color: transparent;
			font-size: 14px;
		}
	}
	.pay {
		background-color: #000 !important;
		color: $white !important;
		#{$modalMidNightselectors} {
			background-color: $main-fill-normal !important;
			color: $white !important;
		}
	}

	> .content {
		padding-top: 30px;
		text-align: center;
		max-width: 376px;
		height: 150px;
		> .modal-title {
			font-size: 16px;
			margin-bottom: 1rem;
			color: #111827;
			font-weight: 600;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}
		> h4 {
			font-family: TG-Light;
			font-weight: 600;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}

		> p {
			margin: 0 auto;
			max-width: 308px;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
			> span {
				font-weight: 600;
				color: $black;
				font-family: TG-Semibold;
				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}
	}
}

.pay-mintyn-modal {
	padding: 20px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	background: #f9f9f9;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}

	.reg-business-input {
		height: 50px;
		font-size: 14px;
		margin-bottom: 0px;

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			border-color: $dark-input !important;
		}
	}
	button {
		margin-bottom: 50px;
		height: 50px;
		border-radius: 15px !important;
		font-weight: 400 !important;
		text-transform: capitalize !important;
		font-weight: 400 !important;
	}

	> .content {
		padding-top: 10px;
		text-align: center;
		max-width: 376px;
		padding-bottom: 30px;
		> .modal-title {
			text-align: center;
			> .title-header {
				font-size: 16px;
				font-weight: 600;
				text-align: center;
				color: #111827;
				#{$modalMidNightselectors} {
					color: $white;
				}
			}
			> p {
				font-family: TG-Light;
				font-weight: 400;
				font-size: 14px;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}

		> .sub-title {
			color: #111827;
			font-size: 16px;
			font-weight: 600;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}

		> .sub-text {
			margin: 0 auto;
			max-width: 100% !important;
			font-family: TG-Light;
			font-weight: 300;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}
	}
	.is-active-list {
		border: 1px solid;
		border-color: $main-fill-normal !important;

		#{$modalMidNightselectors} {
			border-color: transparent !important;
		}
	}
	> .account-list {
		// width: 340px;
		width: 100%;
		display: flex;
		margin-bottom: 20px;
		padding: 20px;
		justify-content: flex-start;
		// gap: 0.2rem;
		flex-direction: row;
		align-items: flex-start;
		border-radius: 15px;
		border-color: $white;
		box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
		background-color: $white;

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			background: #1a1919;
			box-shadow: none;
			border-color: transparent;
		}

		> div {
			display: flex;
			flex-direction: column;
			height: 40px;
			> p {
				font-family: TG-Light;
				font-weight: 500;
				font-size: 12px;
				margin: 0px;
				padding: 0;
				color: #9e9e9e;

				#{$modalMidNightselectors} {
					color: #808080;
				}
			}
			> .name {
				font-family: TG-Light;
				font-weight: 600;
				font-size: 15px;
				margin: 0px;
				padding: 0;
				color: #111827;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}
		> .check-option {
			flex-direction: column;
			display: flex;
			padding-top: 9px;
		}
	}
}
.confirm-pay-modal {
	padding: 20px 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	background: #f9f9f9;
	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}
	> .content {
		> .modal-title {
			text-align: center;
			> .title-header {
				font-size: 16px;
				margin-bottom: 0;
				font-weight: 600;
				text-align: center;
				color: #111827;
				#{$modalMidNightselectors} {
					color: $white;
				}
			}
			> p {
				font-family: TG-Light;
				font-weight: 400;
				font-size: 14px;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}
	}
	.reg-business-input {
		height: 50px;
		font-size: 14px;
		margin-bottom: 0px;

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			border-color: $dark-input !important;
		}
	}

	button {
		margin-bottom: 10px;
		height: 50px;
		border-radius: 15px !important;
		font-weight: 400 !important;
		text-transform: capitalize !important;
		font-weight: 400 !important;
	}

	> .content {
		padding-top: 30px;
		text-align: center;
		max-width: 376px;
		padding-bottom: 30px;

		> h4 {
			font-family: TG-Light;
			font-weight: 700;
			font-size: 20px;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}

		> p {
			margin: 0 auto;
			max-width: 308px;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}

			> span {
				color: #4e4e4e;
				font-weight: 600;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}

		> .sub-text {
			margin: 0 auto;
			max-width: 100% !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}
	}
}

.submitted-modal {
	padding: 20px 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	background: #f9f9f9;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}

	button {
		margin-bottom: 50px;
		height: 50px;
		border-radius: 15px !important;
		font-weight: 400 !important;
		text-transform: capitalize !important;
		font-weight: 400 !important;
	}

	> .content {
		padding-top: 30px;
		text-align: center;
		max-width: 376px;
		padding-bottom: 70px;

		.icon {
			width: 53.33px;
			height: 53.33px;
			margin: 0 auto;
			padding-top: -30px;
			border-radius: 100px;
			background: rgba(208, 162, 60, 0.1);
			#{$modalMidNightselectors} {
				background: rgba(208, 162, 60, 0.1);
			}
		}
		> h4 {
			margin-top: 30px;
			font-family: TG-Light;
			font-weight: 700;
			font-size: 20px;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}

		> p {
			margin: 0 auto;
			padding-top: 20px;
			max-width: 331px;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: $white;
			}

			> span {
				color: #4e4e4e;
				font-weight: 600;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}

		> .sub-text {
			margin: 0 auto;
			max-width: 100% !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}
	}
}
.range {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	height: 10px;
	border-radius: 100px;
	> div {
		background: linear-gradient(95.09deg, #d0a23c -10.51%, #77502f 109.52%);
		height: 100%;
		border-radius: 100px;
	}
}
.custom-check {
	padding-left: 40px !important;

	.custom-control-label:before {
		#{$modalMidNightselectors} {
			border-color: #fff !important;
		}
	}

	.custom-control-input:checked ~ .custom-control-label::before {
		#{$modalMidNightselectors} {
			border: 1px solid $main-fill-normal !important;
			background-color: transparent !important;
		}
	}

	label {
		color: $black !important;
		font-family: TG-Light;
		font-weight: 400 !important;
		font-size: 15px !important;

		#{$modalMidNightselectors} {
			color: $white !important;
		}

		#{$midNightselectors} {
			color: $white !important;
		}
	}
}
.reg-company {
	.input-tip {
		> p {
			margin: 0;
			font-size: 12px;
			padding-top: 0px;
			margin-bottom: 0px;

			#{$midNightselectors} {
				color: $white;
			}
		}

		.tip {
			margin: 0;
			padding-left: 0px;
			font-size: 12px;
			padding-top: 0px;
			font-style: italic;
			margin-bottom: 10px;
			color: #757575;

			#{$midNightselectors} {
				color: #757575;
			}
		}
	}
}

.requirements-modal {
	padding: 10px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;

	button {
		margin-bottom: 15px;
		height: 50px;
		width: 100%;
		border-radius: 15px !important;
		font-weight: 400 !important;
		text-transform: capitalize !important;
		font-weight: 400 !important;
	}

	.cancel {
		margin-bottom: 10px;
		height: 40px;
		width: 100px !important;
		border-radius: 15px !important;
		font-weight: 400 !important;
		text-transform: capitalize !important;
		font-weight: 400 !important;
		background-color: $white;
		color: $black !important;
		background-color: transparent !important;

		#{$modalMidNightselectors} {
			background-color: #111111;
			color: $white !important;
		}
	}

	> .content {
		padding-top: 10px;
		padding-bottom: 30px;
		// border: 2px dashed #515050;
		margin-bottom: 10px;
		border-radius: 4px;

		#{$modalMidNightselectors} {
			background-color: #282727;
		}

		.modal-top-content {
			text-align: center;
			h3 {
				font-size: 18px;
				font-weight: 600;
				color: #111827;
			}
			> h4 {
				font-weight: 300;
				text-align: center;
				font-size: 16px;
			}
		}

		> h4 {
			font-family: TG-Light;
			font-weight: 400;
			text-align: center;
			font-size: 16px;
			max-width: 387px;
			line-height: 24px;
			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}
		div {
			padding: 2px 25px;
			margin-top: 20px;
			h4 {
				font-family: TG-Light;
				font-size: 16px;
				max-width: 387px;
				line-height: 24px;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
			> div {
				margin: 0;
				padding: 0;
				padding-left: 10px;
				margin-bottom: 10px;
				> li {
					font-family: TG-Light;
					font-size: 12px;
					line-height: 24px;

					#{$modalMidNightselectors} {
						color: #a0a0a0;
					}
				}
			}
		}
	}

	.tip {
		margin: 0;
		padding: 0;
		font-family: TG-Light;
		font-size: 12px;
		font-style: italic;
		max-width: 387px;
		line-height: 24px;
		margin-bottom: 20px;

		#{$modalMidNightselectors} {
			color: #b3b3b3;
		}
	}
}

.requirements-reg {
	.tip {
		margin: 0;
		font-size: 12px;
		padding-top: 0px;
		margin-bottom: 0px;

		#{$midNightselectors} {
			color: #b3b3b3;
		}
	}
}
