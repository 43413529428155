@import "../../../../assets/scss/master";

.groom-panel {
	position: relative;
	background: $white;
	width: 100%;
	min-height: calc(100vh - 250px);
	height: 100%;
	margin-bottom: 50px;
	// background-image: url(https://res.cloudinary.com/dswxtf2ay/image/upload/v1582742829/Mint_InternetBanking/faint-logo_kwq5gj.svg);
	// background-repeat: no-repeat;
	// background-position: 102% 104%;
	// background-size: 250.67px 188px;
	#{$midNightselectors} {
		background: $black-medium;
	}
}

.groom-panel-2 {
	background: $white;
	border-radius: 8px;
	border: 1px solid #eee;
	#{$midNightselectors} {
		background: $black-medium;
		border: 1px solid #212121;
	}

	.groom-panel-header {
		padding: 24px 34px;
		border-bottom: 1px solid #eee;
		#{$modalMidNightselectors} {
			border-bottom: 1px solid #212121;
		}

		#{$midNightselectors} {
			border-bottom: 1px solid #212121;
		}

		> p {
			color: var(--mint-midnight);
			font-family: TG-Medium;
			font-size: 14px;
			margin: 0px;
			#{$midNightselectors} {
				color: #fff;
			}
		}
	}
}
