@import "../../../../assets/scss/master";

.customPagination {
	padding: 15px 0;
	ul.pagination {
		margin: 1rem 0;
		width: fit-content;
		margin-left: auto;
		justify-content: flex-end;
		flex-wrap: wrap;
		li.page-item {
			margin-bottom: 5px;
			a.page-link {
				border-radius: 8px !important;
				min-width: 35px;
				text-align: center;
			}
			&:not(.active) {
				a.page-link {
					border: 1px solid #eee;
					font-size: 13px;
					font-family: TG-Light;
					#{$midNightselectors} {
						color: $white;
						background: transparent;
						border-color: $border-medium !important;
					}
				}
			}
		}
	}
}
