.midnight {
	.low-form {
		label {
			color: rgb(255, 255, 255) !important;
		}
	}

	.low-form .form-control,
	.form-control.primary,
	.low-form span.input-group-text,
	.react-datepicker__input-container input {
		background-color: $dark-input !important;
		border-color: $dark-input;
		color: $white;
	}

	.react-select__control {
		background-color: $dark-input;
		border-color: $dark-input;
		border-radius: $border-radius !important;
	}
	.react-select__single-value {
		color: $white;
	}

	.react-select__control input {
		color: #fff !important;
	}

	.hold-breadcrumbs {
		background: #231f20;
	}

	.panel {
		&.verify-account,
		&.account__type__option {
			background: $black-medium;
			box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.03);
		}
	}

	h6.blon {
		color: rgb(255, 255, 255);
	}

	.mycheck {
		background-color: $dark-input;
		border-color: $main-fill-normal;
	}

	svg {
		circle.folderIconCircle {
			fill: #4f4b4c;
		}
		path.folderIconSketch {
			fill: #c4c4c4;
		}
	}

	.verif-center {
		h6 {
			color: rgb(255, 255, 255);
		}
		svg.shield {
			circle {
				fill: #4f4b4c;
			}
			.lockBack {
				fill: transparent;
			}
		}
	}

	.step-box {
		border: 1px solid #4f4b4c;
		background: transparent;

		p {
			color: rgb(255, 255, 255);
		}
	}

	.all-fet {
		color: rgb(255, 255, 255);
	}

	.desct {
		background: #231f20;
		p {
			color: $white;
		}
	}

	.my-deet {
		color: rgb(255, 255, 255);
	}

	.check-container:hover input ~ .mycheck {
		background-color: #231f20;
	}

	.file-container {
		border-color: #393536;
	}

	.whybvn--box {
		background: transparent;
		border: 1px solid #393536;
		ul {
			li {
				color: rgba(255, 255, 255, 0.7);
			}
		}

		.dropdown-toggle::after {
			border-color: rgba(255, 255, 255, 0.7);
		}

		span.textToggle {
			color: rgba(255, 255, 255, 0.7);
		}
	}
	.round-money {
		background: $dark-input;
		border-color: $black-medium;
	}

	.progress-label,
	.apexcharts-legend-text {
		color: #d3d2d2 !important;
	}
	g.apexcharts-datalabels-group text {
		fill: #d3d2d2 !important;
	}
	.card {
		.card-header {
			h5 {
				.btn {
					color: $white !important;
					&::after {
						background: $white !important;
					}
				}
			}
			background: transparent !important;
			border-color: #444749 !important;
		}
		.card-body {
			color: $white !important;
		}
		background: transparent;
	}
	#setTab.nav-tabs {
		.nav-link {
			color: #c4c4c4 !important;
			&:not(.active) span {
				background-color: #c4c4c4 !important;
			}
		}
	}
	.center-img {
		svg {
			ellipse {
				fill: $black;
			}
			g {
				path {
					&:first-child {
						fill: $black;
						// fill: red;
					}
				}
			}
		}
	}
	.greyworm,
	.sweet_subText {
		color: $white !important;
	}
	.trans-row {
		background: #4f4b4c !important;
	}
	.debit {
		border-color: #ff4c54 !important;
	}
	.taylor {
		h6 {
			color: #f2f2f2;
		}
		p {
			color: #c4c4c4;
		}
	}
	.block-circle {
		background: #393536;
		color: #c4c4c4;
	}
	.dayuh {
		label {
			color: #d3d2d2;
		}
		p {
			color: $white;
		}
	}
	.lb-help,
	.yoloMonk,
	.right-side span {
		color: $main-fill-normal;
	}
	.react-select {
		&__menu {
			background: #393536;
			color: #c4c4c4;
		}
	}
	.breadcrumb-container {
		.step-number {
			color: #afafaf;
			border-color: #afafaf;
		}
	}
	#transcations.table tbody tr td {
		color: #d3d2d2 !important;
	}

	.custom-control-label {
		&::before {
			border-color: $white !important;
			background-color: transparent !important;
		}
	}
}
