@import "../../../assets/scss/master";

.custom-radio-button {
	display: block;
	position: relative;
	padding-left: 35px;
	height: auto;
	cursor: pointer;
	color: $white;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 0px;
	// flex-shrink: 0;

	.inner-label {
		align-items: center;
		margin-left: 10px;
		display: flex;
		position: relative;
		top: 3px;
		color: $black;
		font-family: TG-Medium;

		#{$midNightselectors} {
			color: $white;
		}

		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		vertical-align: super;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 18px;
		width: 18px;
		background-color: $white;
		border-radius: 50%;
		border: 1px solid $main-fill-normal;

		#{$midNightselectors} {
			background-color: transparent;
			border: 1px solid $white;
		}

		#{$modalMidNightselectors} {
			background-color: transparent;
			border: 1px solid $white;
		}
	}
}

.custom-radio-button input:checked ~ .checkmark {
	// background-color: #cccccc;
	// border: 1px solid $main-fill-normal;
	#{$midNightselectors} {
		// border-color: $main-fill-normal;
		border-color: linear-gradient(
			95.09deg,
			#d0a23c -10.51%,
			#77502f 109.52%
		);
	}

	#{$modalMidNightselectors} {
		border-color: linear-gradient(
			95.09deg,
			#d0a23c -10.51%,
			#77502f 109.52%
		);
	}
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.custom-radio-button input:checked ~ .checkmark:after {
	display: block;
}

.custom-radio-button .checkmark:after {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: linear-gradient(95.09deg, #d0a23c -10.51%, #77502f 109.52%);
}
