@import "../../../../../../assets/scss/master";

.mkt-quantity-selector {
	display: flex;
	align-items: center;
	cursor: default;
	width: fit-content;

	button {
		border-radius: 3px 0px 0px 3px;
		border: 1px solid $input-border;
		height: 25px;
		width: 25px;
		padding: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:disabled {
			cursor: not-allowed;
		}
		#{$midNightselectors} {
			background-color: #393939;
			border: none;
		}

		svg {
			width: 15px;
			height: 15px;

			path {
				fill: $black;
				#{$midNightselectors} {
					fill: $white;
				}
			}
		}

		&:last-of-type {
			border-radius: 0px 3px 3px 0px;

			svg {
				width: 8px;
				height: 8px;
			}
		}
	}

	> span {
		width: 43px;
		height: 25px;
		color: $black;
		text-align: center;
		font-size: 10px;
		font-family: TG-Semibold;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid $input-border;
		border-bottom: 1px solid $input-border;
		#{$midNightselectors} {
			background-color: #1c1b1b;
			color: $white;
			border-color: transparent;
		}
	}
}
