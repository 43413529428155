@import "../../../../assets/scss/master";

.complete-profile {
	> h1 {
		font-size: 18px;
		color: $black;
		font-family: TG-Medium;

		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}

	> p {
		max-width: 472px;
		color: $black;
		font-size: 12px;
		line-height: 20px;

		#{$midNightselectors} {
			color: var(--gray-fill-light);
		}
	}
}
