@import "../../../../../assets/scss/master";

.securityQuestion__section {
	text-align: center;
	color: #37474f;
	padding: 35px 0;

	h6 {
		font-family: "TG-Medium";
		font-weight: 100;
		font-size: 14px;
		letter-spacing: 0.02em;
		text-transform: capitalize;
	}

	p {
		font-size: 13px;
		line-height: 22px;
		letter-spacing: 0.25px;
	}

	#{$modalMidNightselectors} {
		color: $white;
	}
}
