.btn-primary {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 20px;
	border-radius: 15px;
	background-color: $primary;
	color: $white;
	// font-weight: 500;
	font-size: 18px;
	height: 50px;
	width: fit-content;
	text-align: center;
	justify-content: center;
	border: none !important;

	&.small {
		font-size: 16px;
	}

	&:hover,
	&:focus,
	&:active {
		background-color: $primary !important;
		box-shadow: none !important;
	}

	&:disabled {
		background-color: #aaaaac !important;
		cursor: not-allowed;
	}

	.lds-spinner-small {
		margin: 0px auto;
	}

	@media (max-width: 767px) {
		font-size: 16px;
	}
}

.btn-text-gold {
	width: fit-content;
	height: fit-content;
	padding: 0px;
	border: none;
	border-radius: 0px;
	background-color: transparent;
	background: linear-gradient(95.09deg, #d0a23c -10.51%, #77502f 109.52%);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: 500;
	font-size: 16px !important;
}

.btn-text-primary {
	width: fit-content;
	height: fit-content;
	padding: 0px;
	border: none;
	border-radius: 0px;
	background-color: transparent;
	font-weight: 500;
	font-size: 16px !important;

	&:hover {
		text-decoration: underline;
		text-underline-offset: 2px;
	}
}
