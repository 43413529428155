@import "../../../assets/scss/master";

.deactivate-account-view {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 72px 16px;
	flex-direction: column;

	@media (min-width: 1024px) {
		padding: 108px 16px;
	}

	> h1 {
		font-size: 32px;
		color: $black;
		font-family: TG-Semibold;
		text-align: center;

		#{$midNightselectors} {
			color: $white;
		}

		@media (max-width: 767px) {
			font-size: 28px;
		}
	}

	&__form {
		width: 100%;
		max-width: 488px;
		margin: 48px auto 0px;
		display: flex;
		flex-direction: column;
		gap: 24px;

		.deactivate-account-form-group {
			label {
				color: $black;

				#{$midNightselectors} {
					color: #bbb;
				}
			}

			.form-control {
				height: 50px;
				font-size: 14px;
			}

			.form-group {
				margin-bottom: 0px;
			}

			.deactivate-account-input {
				height: 50px;
				font-size: 14px;
				margin-bottom: 0px;
				width: 100%;
				padding: 8px 16px;

				#{$midNightselectors} {
					background-color: $dark-input !important;
					color: $white !important;
					// border-color: $border-medium !important;
				}
			}
		}

		> button {
			margin-top: 24px;
			height: 54px;
			font-size: 16px !important;
			font-family: TG-Semibold;
			color: $white;
			background-color: #cc2500 !important;
			text-transform: capitalize !important;
		}
	}
}
