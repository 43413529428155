.shineLoader {
    width: 250px;
    height: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    animation: ShineAnimation 3s infinite ease-in-out;
    -webkit-animation: ShineAnimation 3s infinite ease-in-out;
}

#mintInfinite {
    font: bolder 8.5vw 'TG-Medium';
    text-align: center;
    margin: 0;
    background-size: contain;
    margin: auto;
    width: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: ShineAnimation 3s infinite ease-in-out;
    -webkit-animation: ShineAnimation 3s infinite ease-in-out;
}

@keyframes ShineAnimation {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.18);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}