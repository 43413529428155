@import "../../../assets/scss/newDesignStyles/general/variables.scss";

.gender-select {
	width: 100%;

	&__options {
		display: flex;
		gap: 7px;
	}

	&__field {
		cursor: pointer;
		border-radius: 15px;
		height: 50px;
		flex: 1;
		background: #9e9e9e;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		font-size: 18px;

		&.selected {
			background: linear-gradient(
				95.09deg,
				#d0a23c -10.51%,
				#77502f 109.52%
			);
		}
	}

	.error-msg {
		color: $error !important;
		font-size: 14px;
		margin-top: 4px;
	}
}
