@import "../../../../../../assets/scss/master";

.setup-card {
	background-color: $white;
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 24px 32px;
	height: 100%;
	display: flex;
	flex-direction: column;

	#{$midNightselectors} {
		background-color: $black-medium;
		border: 1px solid #212121;
	}

	&.setup-card-disabled {
		opacity: 0.5;
		pointer-events: none;
		#{$midNightselectors} {
			background: #111;
			box-shadow: none;
		}
	}

	> h5 {
		font-size: 18px;
		color: $black;
		font-family: TG-Medium;
		margin-bottom: 16px;
		line-height: 24px;

		#{$midNightselectors} {
			color: $white;
		}
	}

	> p {
		max-width: 472px;
		color: $black;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 24px;

		#{$midNightselectors} {
			color: #bbb;
		}
	}

	> button {
		max-width: 180px;
		text-transform: capitalize !important;
	}

	.setup-card-status {
		color: var(--main-fill-normal);
		font-family: TG-Medium;
		font-size: 14px;

		&.verified {
			color: var(--main-fill-light);
		}
	}
}
