@import "../../../../../assets/scss/master";

.billsList {
	position: absolute;
	width: 604px;
	height: 101px;
	left: 538px;
	top: 269px;

	background: $white;
	border: 1px solid #e8f1f8;
	box-sizing: border-box;
	border-radius: 4px;
}
