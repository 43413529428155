@import "../../../../../../assets/scss/master";

.pta-summary {
	width: 100%;
	padding-top: 20px;
	padding-bottom: 60px;

	.pta-summary-info {
		display: flex;
		justify-content: space-between;
		margin-top: 40px;

		&:first-of-type {
			margin-top: 0px;
		}

		span {
			color: #1b1b1b;
			font-family: TG-Light;
			font-size: 13px;
			#{$modalMidNightselectors} {
				color: #bbbbbb;
			}

			&:last-child {
				font-family: TG-Medium;
				#{$modalMidNightselectors} {
					color: $white;
				}
			}

			&.amount {
				color: var(--main-fill-normal) !important;
			}
		}
	}

	.pta-summary-footer {
		margin-top: 16px;
		display: flex;
		justify-content: space-between;
		// border-top: 1px solid #eee !important;
		padding-top: 60px;
		// #{$modalMidNightselectors} {
		// 	border-color: #272626 !important;
		// }

		> button {
			width: 48% !important;
			height: 50px !important;
			font-size: 14px !important;
			text-transform: capitalize;
			font-family: TG-Semibold;
			border-radius: 15px;

			&:first-of-type {
				color: $black;
				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}
	}
}

// Decline and Approve modals
.pta-request-status {
	width: 100%;
	padding-top: 20px;
	padding-bottom: 48px;

	&__footer {
		margin-top: 32px;
		display: flex;
		justify-content: space-between;

		> button {
			width: 48% !important;
			height: 50px !important;
			font-size: 14px !important;
			text-transform: capitalize !important;
			font-family: TG-Semibold;
			border-radius: 15px;

			&:first-of-type {
				color: var(--main-fill-normal);
			}
		}
	}
}

// Success modal
.pta-success-modal {
	width: 100%;
	padding-top: 36px;
	padding-bottom: 55px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> h2 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 308px;
		margin: 24px auto 0px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> button {
		text-transform: capitalize !important;
		max-width: 340px;
		height: 50px;
		margin-top: 42px;
	}
}
