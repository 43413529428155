@import "../../../../../../assets/scss/master";

.mkt-cart-item-card {
	width: 100%;
	border-radius: 8px;
	padding: 28px 26px;
	background-color: $white;
	border: 1px solid $input-border;
	height: auto;
	margin-bottom: 12px;
	#{$midNightselectors} {
		background-color: $black-medium;
		border-color: transparent;
	}

	&:last-of-type {
		margin-bottom: 0px;
	}

	&__left {
		display: flex;
		align-items: center;
		width: 100%;

		@media (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;
		}

		.mkt-cart-item-img {
			width: 146px;
			height: 146px;
			margin-right: 24px;

			// @media (max-width: 500px) {
			// 	width: 100%;
			// 	height: 100%;
			// }

			@media (max-width: 767px) {
				margin-right: 0px;
				margin-bottom: 24px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 8px;
			}
		}

		> div {
			&:last-child {
				flex: 1;
				.mkt-cart-item-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 20px;

					@media (max-width: 767px) {
						flex-direction: column;
						align-items: flex-start;
					}

					> h3 {
						font-size: 18px;
						color: $black;
						font-family: TG-Semibold;
						max-width: 245px;
						margin: 0px;

						#{$midNightselectors} {
							color: $white;
						}
					}

					> h4 {
						margin: 0px;
						color: $main-fill-normal;
						font-size: 24px;
						font-family: TG-Medium;
						margin-top: auto;
						margin-left: 10px;

						@media (max-width: 767px) {
							margin-top: 16px;
						}

						@media (max-width: 500px) {
							font-size: 20px;
						}
					}
				}

				.mkt-cart-item-footer {
					display: flex;
					justify-content: space-between;
					align-items: center;

					@media (max-width: 767px) {
						width: fit-content;
					}

					.mkt-cart-quantity-selector {
						button {
							width: 32px;
							height: 32px;
							border-radius: 4px 0px 0px 4px;

							svg {
								width: 25px;
								height: 25px;
							}

							&:last-of-type {
								border-radius: 0px 4px 4px 0px;

								svg {
									width: 16px;
									height: 16px;
								}
							}
						}

						> span {
							height: 32px;
							font-size: 14px;
							width: 48px;
						}
					}

					> button {
						padding: 0px;
						height: fit-content;
						width: fit-content;
						border: none;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
						margin-left: auto;

						@media (max-width: 767px) {
							margin-left: 10px;
						}

						&:disabled {
							cursor: not-allowed;
						}

						svg {
							rect {
								fill: #faf5eb;
								#{$midNightselectors} {
									fill: #2c271c;
								}
							}
						}
					}
				}
			}
		}
	}
}
