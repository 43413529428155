@import "../../../../assets/scss/master";

.rounded-bordered {
	background: #fafafa;
	border: 2px dashed #0000000f;
	box-sizing: border-box;
	border-radius: 8px;
	#{$modalMidNightselectors} {
		background: $dark-input;
		border: 2px dashed #333333;
	}
}

.pin-input {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	input {
		margin: 8px;
		width: 39px;
		height: 35px;
		text-align: center;
		font-size: 20px;
		background: transparent;
		border: 0px;
		color: $black;
		background: rgba(208, 162, 60, 0.15);
		border: 1.5px solid $main-fill-normal;
		border-radius: 5.7px;
		box-sizing: border-box;
		outline: none;
		font-family: text-security-disc;
		-webkit-text-security: square !important;

		#{$modalMidNightselectors} {
			color: $white;
		}
	}
}

/* also need keyframes and -moz-keyframes */

@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}
	@-moz-keyframes #{$animation-name} {
		@content;
	}
	@-ms-keyframes #{$animation-name} {
		@content;
	}
	@-o-keyframes #{$animation-name} {
		@content;
	}
	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

@include keyframes(shake-body) {
	8%,
	41% {
		-webkit-transform: translateX(-10px);
	}

	25%,
	58% {
		-webkit-transform: translateX(10px);
	}

	75% {
		-webkit-transform: translateX(-5px);
	}

	92% {
		-webkit-transform: translateX(5px);
	}

	0%,
	100% {
		-webkit-transform: translateX(0);
	}
}

.invalid {
	/* also need animation and -moz-animation */
	@include animation("shake-body 0.5s linear");
}
