
@import "../../../../../../assets/scss/master";

.mkt-repayment-schedule {
  background-color: $white;
  border: 1px solid $input-border;
  height: 572px;
  border-radius: 8px;
  flex: 1;
  display: flex;
  max-width: 658px;
  margin-top: 30px;
  flex-direction: column;

  #{$midNightselectors} {
    background-color: $black-medium;
    border-color: transparent;
  }

  @media (max-width: 1024px) {
    margin-left: 0px;
    margin-top: 24px;
    max-width: 100%;
  }

  &__header {
    padding: 40px 16px 16px;
    border-bottom: 1px solid #eee;

    #{$midNightselectors} {
      border-color: $black-stroke;
    }

    h2 {
      margin: 0px;
      font-size: 16px;
      color: $black;
      font-family: TG-Semibold;

      #{$midNightselectors} {
        color: $white;
      }
    }
  }

  &__content {
    padding: 32px 36px 21px;
    flex: 1;

    @media (max-width: 500px) {
      padding: 20px 16px 21px;
    }

    .mkt-repayment-form {
      max-width: 534px;
      margin: 0 auto;

      label {
        color: $black;
        font-family: TG-Light;
        font-size: 14px;

        #{$midNightselectors} {
          color: $white;
        }
      }

      .form-control {
        height: 50px;
        font-size: 14px;
      }

      .form-group {
        margin-bottom: 0px;
      }

      .mkt-repayment-input {
        height: 60px;
        font-size: 14px;
        margin-bottom: 0px;

        #{$midNightselectors} {
          background-color: $dark-input !important;
          color: $white !important;
          border-color: $border-medium !important;
        }
      }
      .custom-check {
        padding-left: 40px;
        .custom-control-label:before {
            #{$midNightselectors} {
              border-color: #fff !important;
            }
          }
        
          .custom-control-input:checked~.custom-control-label::before {
            #{$midNightselectors} {
              border: 1px solid $main-fill-normal !important;
              background-color: transparent !important;
            }
          }
        
          label{
            color: $black !important;
            font-family: TG-Light;
            font-weight:400 !important ;
            font-size: 12px;
        
            #{$midNightselectors} {
              color: $white !important;
            }
          }
      }

      .mkt-repayment-form-info {
        font-style: italic;
        font-size: 14px;
        margin: 0px;
        margin-top: 12px;
        color: $black !important;

        #{$midNightselectors} {
          color: $white !important;
        }
      }

      .btn-mint-default {
        border-radius: 15px !important;
        height: 50px !important;
      }
    }
  }
}

.mkt-repayment-modal {
  background-color: $white;
  border: 0px solid $input-border;
  height: auto;
  border-radius: 8px;
  flex: 1;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;

  #{$modalMidNightselectors} {
    background-color: $black-medium;
    border-color: transparent;
  }

  @media (max-width: 1024px) {
    margin-left: 0px;
    margin-top: 24px;
    max-width: 100%;
  }

  button {
    text-transform: none !important;
    height: 50px;
    margin-top: 30px;
    border-radius: 15px !important;
  }

  .button-outline {
    margin-right: 17px;
    color: $main-fill-normal !important;
    background-color: transparent !important;
    border: solid 2px $main-fill-normal;
    ;

  }


  &__content {
    padding: 0px 36px 21px;
    flex: 1;

    @media (max-width: 500px) {
      padding: 0px 16px 21px;
    }

  .mkt-repayment-modal-form {
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 20px 21px;

    .pin-container{
      display:  flex;
      flex-direction: column;
      border: 1px solid $input-border;
      border-style: dashed;
      padding: 30px 30px;
      border-radius: 8px;
    
      #{$modalMidNightselectors} {
        border: 1px solid #333333;
        background-color: $dark-input !important;
        border-style: dashed;
        color: $white;
      }
      >.sub-text {
      text-align: center;
      margin-bottom: 15px;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        font-family: TG-Light;

        #{$modalMidNightselectors} {
          color: $white;
        }
      }
      >div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
    .btn-container {
      display: flex;
      padding: 0;
      flex-direction: row;
      @media (max-width: 500px) {
          flex-direction: column-reverse;
        }
    }

    label {
      color: $black;
      font-family: TG-Light;
      font-size: 14px;

      #{$modalMidNightselectors} {
        color: #bbbbbb;
      }
    }

    .form-control {
      height: 50px;
      font-size: 14px;
    }

    .form-group {
      margin-bottom: 0px;
    }

    .mkt-repayment-modal-input {
      height: 50px;
      width: 50px;
      font-size: 20px;
      text-align: center;
      margin-bottom: 0px;
      background: rgba(208, 162, 60, 0.15) !important;
      border-radius: 5.7px !important;
      border-color: $main-fill-normal !important;

      #{$modalMidNightselectors} {
        color: $white !important;
        border-color: $main-fill-normal !important;
      }
    }


  }

  }



}