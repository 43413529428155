@import "../../../assets/scss/master";

.intl-tel-input {
	display: block !important;
	border: 0 !important;
	border-radius: 15px !important;
	// overflow: hidden;
	.selected-flag {
		// border-radius: 4px 0 0 4px !important;
		border-top-left-radius: 15px !important;
		border-bottom-left-radius: 15px !important;
		background-color: #fafafac2 !important;
		outline: none !important;
		
		#{$midNightselectors} {
			background-color: $dark-input !important;
			border: 1px solid $dark-input;
		}

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			border: 1px solid $dark-input !important;
		}
		.selected-dial-code {
			color: #6c747d !important;
			font-size: 14px !important;
			font-weight: bolder;

			#{$midNightselectors} {
				color: $white !important;
			}
		}
		.iti-arrow {
			display: none;
		}
		// .iti-flag {
		// 	margin-top: 13px;
		// }
	}
	.country-list {
		border: 1px solid $black !important;
		border-radius: 3px;
		.country {
			#{$midNightselectors} {
				color: $white !important;
				background: $dark-input !important;
			}
			&:hover,
			&.highlight {
				#{$midNightselectors} {
					background-color: $black !important;
					color: $white;
				}
			}
		}
		.divider {
			padding-bottom: 0 !important;
			margin-bottom: 0 !important;
			border-bottom: 1px solid $black !important;
		}
	}
	input {
		background-color: $white !important;
	}
}
