main.dashboard {
	font-family: "TG-Light";
}

a:hover {
	text-decoration: none;
}

.mt-5x {
	margin-top: 6rem;
}

.mt-45 {
	margin-top: 2rem;
}

.navbar {
	padding: 15px 10px;
	background: transparent;
	border: none;
	border-radius: 0;
	/* box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); */
}

.navbar-btn {
	box-shadow: none;
	outline: none !important;
	border: none;
}

.line {
	width: 100%;
	height: 1px;
	border-bottom: 1px dashed #ddd;
	margin: 40px 0;
}

i,
span {
	display: inline-block;
}

/* ---------------------------------------------------
     STYLE
----------------------------------------------------- */

.wrapper {
	display: flex;
	align-items: stretch;
}

a[data-toggle="collapse"] {
	position: relative;
}

.dropdown-toggle::after {
	display: block;
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
}

ul ul a {
	font-size: 0.9em !important;
	padding-left: 30px !important;
	background: #6d7fcc;
}

ul.CTAs {
	padding: 20px;
}

ul.CTAs a {
	text-align: center;
	font-size: 0.9em !important;
	display: block;
	border-radius: 5px;
}

a.download {
	background: #fff;
	color: #7386d5;
}

a.article,
a.article:hover {
	background: #6d7fcc !important;
	color: #fff !important;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

#sidebarCollapse {
	display: none;
}

.dally {
	margin-bottom: 10px;
}

@media (min-width: 1200px) {
	.dally {
		padding-right: 0px;
	}
}

@media (max-width: 1200px) {
	.dally {
		padding-right: 15px;
	}
}

@media (min-width: 769px) {
	#overlay {
		display: none !important;
	}
}

@media (max-width: 768px) {
	#BigsidebarCollapse {
		display: none;
	}
	#sidebarCollapse {
		display: block;
	}
}

@media (max-width: 768px) {
	.dally {
		padding-right: 15px;
	}
	/* #sidebarCollapse span {
		display: none;
	} */
}

.thumb {
	width: 36px;
	height: 36px;
	border-radius: 18px;
	overflow: hidden;
	/* background: aliceblue; */
	background: #21262a;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
	margin: 0 0.75rem;
	-webkit-appearance: none;
	appearance: none;
}

.thumb img {
	width: 36px !important;
	height: 36px !important;
	object-fit: cover;
}

.account-lead h6 {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #d6fae9;
}

.account-lead h2 {
	font-weight: 600;
	font-size: 32px;
	line-height: 45px;
	letter-spacing: 0.65px;
	color: #ffffff;
}

.flex-savings {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.savings-box {
	/* width: 276px; */
	margin-top: 10px;
	margin-bottom: 4px;
	/* height: 185px; */
	height: 100%;
	/* min-height: 185px; */
	height: 205px;
	padding: 20px;
	background: #ffffff;
	/* border: 1px solid #E8F1F8; */
	box-sizing: border-box;
	box-shadow: 0px 4px 10px rgba(232, 241, 248, 0.5);
	border-radius: 4px;
}

.savings-box:not(:last-child) {
	margin-right: 10px;
}

.round-money {
	width: 40px;
	height: 40px;
	background: #ffffff;
	border: 1px solid #e8f1f8;
	box-sizing: border-box;
	border-radius: 20px;
	overflow: hidden;
}

.round-money.plus {
	border: 0px;
	background: #fbfbfe;
}

.round-money {
	display: flex;
	justify-content: center;
	align-items: center;
}

button.save-btn {
	height: 30px;
	border: 1.4px solid #d1a23c;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 2px 10px;
	font-family: TG-Medium;
	font-weight: 100;
	font-size: 12px;
	text-align: center;
	color: #d1a23c !important;
	outline: none;
	box-shadow: none;
	margin: auto 0px auto 0px;
	margin-left: auto;
}

/*  */

#balanceTab {
	border-bottom: 0px;
	display: inline-flex;
}

#balanceTab li {
	white-space: nowrap;
}

#balanceTab li:first-child .nav-link {
	border-radius: 4px 0px 0px 4px;
	/* white-space:nowrap; */
}

#balanceTab li:last-child .nav-link {
	border-radius: 0px 4px 4px 0px;
	/* white-space:nowrap; */
}

#balanceTab .nav-link {
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.02em;
	padding: 10px 25px;
	/* color: #37474F; */
	color: var(--gray-fill-light);
	background: #ffffff;
	border: 0px;
	font-family: "TG-Medium";
	/* border: 1px solid #EAECF0; */
	/* box-sizing: border-box; */
	border-radius: 0px;
}

#balanceTab .nav-link.active {
	background: #4abe86;
	color: #fff;
}

.lighter {
	font-family: "TG-Light";
	font-size: 13px;
	font-weight: 900;
	letter-spacing: 0.5px;
}

.spending-card {
	margin-top: 5px;
	padding: 20px 40px;
	background: #ffffff;
	box-shadow: 0px 4px 10px rgba(232, 241, 248, 0.5);
	border-radius: 3px;
	min-height: 300px;
}

#spendTab {
	border-bottom: 0px;
	display: inline-flex;
}

#spendTab li:first-child .nav-link {
	padding-left: 0px;
}

#spendTab li {
	white-space: nowrap;
}

#spendTab .nav-link {
	border: 0px;
	border-radius: 0px;
	font-weight: 500;
	font-size: 12px;
	line-height: 21px;
	text-align: center;
	letter-spacing: 0.04em;
	color: #69747a;
	font-family: "TG-Medium";
	opacity: 0.8;
}

#spendTab .nav-link.nav-link.active {
	opacity: 1;
	background: transparent;
	color: var(--sub-fill-dark);
	font-family: "TG-Medium";
}

.head-wyx {
	height: 40px;
}

/* SPENDING CENTER */

.block-circle {
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	letter-spacing: 0.02em;
	color: #69747a;
	width: 36px;
	height: 36px;
	left: 358px;
	top: 1373px;
	text-align: center;
	background: #faf5eb;
	border-radius: 50%;
	overflow: hidden;
}

.vertical-center {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.progress {
	height: 4px;
}

.progress-label {
	font-style: normal;
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 12px;
	line-height: 21px;
	/* identical to box height */
	/* text-align: center; */
	letter-spacing: 0.02em;
	color: #37474f;
	margin-bottom: 2px;
}

.progress-bar {
	background-color: #d1a23c !important;
}

.spending-res {
	padding: 15px 0px;
}

.btn.scary {
	outline: none;
	box-shadow: none;
}

#overlay {
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	cursor: pointer;
}

.btn-close {
	color: #fff;
	font-size: 50px;
	position: absolute;
	top: 0px;
	right: 10px;
}

button.btn-close:hover {
	cursor: pointer !important;
}

.dash-sub {
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 15px;
	line-height: 21px;
	text-align: center;
	/* letter-spacing: 0.25px; */
	color: #37474f;
}

.lb-help {
	font-size: 12px;
	/* line-height: 18px; */
	/* letter-spacing: 0.15px; */
	font-weight: 100;
	font-family: TG-Medium;
	float: right;
	text-align: right;
	color: var(--sub-fill-dark);
}

.lb-help:hover {
	opacity: 0.6;
	cursor: pointer;
}

.fitted {
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.fitted p {
	line-height: normal;
}

.mint-list {
	height: 230px;
	/* overflow: scroll; */
}

.mint-row {
	display: inline-flex;
	align-items: center;
	width: 100%;
	padding: 12px 5px;
	padding-left: 0px;
}

.mint-row:hover {
	cursor: pointer;
	/* background: var(--sub-fill-light); */
}

.mint-row:hover > .right-side span {
	color: var(--sub-fill-dark) !important;
}

.left-side {
	display: inline-flex;
}

.taylor {
	/* display: flex;
    flex-direction: column;
    align-items: center; */
}

.taylor h6 {
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 12px;
	color: #37474f;
	text-align: left;
}

.taylor p {
	font-size: 11px;
	line-height: 18px;
	color: #69747a;
}

.right-side {
	margin-left: auto;
	text-align: right;
}

.right-side span {
	font-family: "TG-Medium";
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.15px;
	text-align: right;
	color: #37474f;
}

/* Fund Account */
.shun {
	overflow: hidden;
	margin-top: 15px;
}

.apexcharts-toolbar {
	display: none !important;
}

.savings-plans {
	padding: 20px;
}

/* */

/* . */

.prog-rec {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	/* border: 2px solid #359BE0; */
}

.prog-rec div {
	background: #ffffff;
}

/* .prog-rec img{
    display: block;
    margin: auto;
} */

div.plan-status {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

div.plan-status img {
	margin-left: 10px;
}

span.active-plan {
	text-transform: capitalize;
	text-align: right;
	color: #d1a23c;
	font-family: "TG-Medium";
	font-weight: bolder;
	font-size: 12px;
	letter-spacing: 0.18px;
	line-height: 18px;
	/* identical to box height */
}

.mypay {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.mypay span {
	font-weight: 100;
	font-family: "TG-Medium";
	color: #359be0;
}

/* SVG Cirle Style */

.single-chart {
	width: 33%;
	justify-content: space-around;
}

.circular-chart {
	display: block;
	/* margin: 10px auto; */
	width: 40px;
	height: 40px;
}

.circle-bg {
	fill: none;
	stroke: rgb(248, 231, 196);
	stroke-width: 2;
}

.circle {
	fill: #faf5eb;
	stroke-width: 2;
	stroke-linecap: round;
	animation: progress 1s ease-out forwards;
}

@keyframes progress {
	0% {
		stroke-dasharray: 0 100;
	}
}

.circular-chart.green .circle {
	stroke: #77502f;
}

.bilTruck {
	position: absolute;
	top: 2px;
	width: 36.82px;
	height: 32.82px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bilTruck img {
	width: 15px;
}

g.apexcharts-datalabels-group text {
	font-family: "TG-Medium";
	font-weight: 500;
	font-size: 18px;
	/* line-height: 15px; */
	text-align: center;
	letter-spacing: 0.02em;
	fill: #37474f !important;
}

g.apexcharts-datalabels-group text:nth-child(2) {
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.02em;
	fill: #ff4c54;
}

.apexcharts-legend.center.position-right {
	display: none;
}

.savings-brew {
	padding-top: 60px;
}

.mural {
	padding-top: 50px;
	width: 100%;
	/* border-bottom: 1px solid #E8F3F8; */
	/* height: 396px; */
}

.dayuh {
	padding: 1rem !important;
}

.dayuh label {
	font-style: normal;
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 11.5px;
	line-height: 18px;
	letter-spacing: 0.25px;
	text-transform: uppercase;
	color: #69747a;
}

.dayuh p {
	font-weight: 500;
	font-family: "TG-Medium";
	font-size: 14px;
	line-height: 21px;
	color: #37474f;
}

.withAction {
	width: 100%;
	font-style: normal;
	font-family: "TG-Medium";
	text-transform: uppercase;
	font-weight: 100;
	font-size: 14px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #d1a23c !important;
	padding: 10px;
	background: transparent;
	/* background: linear-gradient(0deg, #FFFFFF, #FFFFFF); */
	border: 1px solid #d1a23c;
	box-sizing: border-box;
	border-radius: 4px;
}

.withAction img,
button.create-acc img {
	margin-right: 10px;
	width: 14px;
	height: 14px;
}

.zero-text {
	margin-top: 10px;
	font-size: 12px !important;
	line-height: 18px !important;
	color: #69747a !important;
}

span.editFrequency:after {
	content: "";
	width: 14.67px;
	height: 14.67px;
	margin-left: 2px;
	margin-bottom: 5px;
	background-color: #d1a23c;
	-webkit-mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjY2NjAyIDMuMzMzMzNDMi40ODkyIDMuMzMzMzMgMi4zMTk2NCAzLjQwMzU3IDIuMTk0NjEgMy41Mjg2QzIuMDY5NTkgMy42NTM2MiAxLjk5OTM1IDMuODIzMTkgMS45OTkzNSA0VjEzLjMzMzNDMS45OTkzNSAxMy41MTAxIDIuMDY5NTkgMTMuNjc5NyAyLjE5NDYxIDEzLjgwNDdDMi4zMTk2NCAxMy45Mjk4IDIuNDg5MiAxNCAyLjY2NjAyIDE0SDExLjk5OTNDMTIuMTc2MiAxNCAxMi4zNDU3IDEzLjkyOTggMTIuNDcwOCAxMy44MDQ3QzEyLjU5NTggMTMuNjc5NyAxMi42NjYgMTMuNTEwMSAxMi42NjYgMTMuMzMzM1Y5Ljc3MzMzQzEyLjY2NiA5LjQwNTE0IDEyLjk2NDUgOS4xMDY2NyAxMy4zMzI3IDkuMTA2NjdDMTMuNzAwOSA5LjEwNjY3IDEzLjk5OTMgOS40MDUxNCAxMy45OTkzIDkuNzczMzNWMTMuMzMzM0MxMy45OTkzIDEzLjg2MzggMTMuNzg4NiAxNC4zNzI1IDEzLjQxMzYgMTQuNzQ3NUMxMy4wMzg1IDE1LjEyMjYgMTIuNTI5OCAxNS4zMzMzIDExLjk5OTMgMTUuMzMzM0gyLjY2NjAyQzIuMTM1NTggMTUuMzMzMyAxLjYyNjg3IDE1LjEyMjYgMS4yNTE4IDE0Ljc0NzVDMC44NzY3MjkgMTQuMzcyNSAwLjY2NjAxNiAxMy44NjM4IDAuNjY2MDE2IDEzLjMzMzNWNEMwLjY2NjAxNiAzLjQ2OTU3IDAuODc2NzI5IDIuOTYwODYgMS4yNTE4IDIuNTg1NzlDMS42MjY4NyAyLjIxMDcxIDIuMTM1NTggMiAyLjY2NjAyIDJINi4yMjYwMkM2LjU5NDIxIDIgNi44OTI2OCAyLjI5ODQ4IDYuODkyNjggMi42NjY2N0M2Ljg5MjY4IDMuMDM0ODYgNi41OTQyMSAzLjMzMzMzIDYuMjI2MDIgMy4zMzMzM0gyLjY2NjAyWiIgZmlsbD0iIzRBQkU4NiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjUyNzkgMC44NjIyNTRDMTEuNzg4MyAwLjYwMTkwNSAxMi4yMTA0IDAuNjAxOTA1IDEyLjQ3MDggMC44NjIyNTRMMTUuMTM3NCAzLjUyODkyQzE1LjM5NzggMy43ODkyNyAxNS4zOTc4IDQuMjExMzggMTUuMTM3NCA0LjQ3MTczTDguNDcwNzUgMTEuMTM4NEM4LjM0NTczIDExLjI2MzQgOC4xNzYxNiAxMS4zMzM3IDcuOTk5MzUgMTEuMzMzN0g1LjMzMjY4QzQuOTY0NDkgMTEuMzMzNyA0LjY2NjAyIDExLjAzNTIgNC42NjYwMiAxMC42NjdWOC4wMDAzM0M0LjY2NjAyIDcuODIzNTEgNC43MzYyNSA3LjY1Mzk0IDQuODYxMjggNy41Mjg5MkwxMS41Mjc5IDAuODYyMjU0Wk01Ljk5OTM1IDguMjc2NDdWMTAuMDAwM0g3LjcyMzIxTDEzLjcyMzIgNC4wMDAzM0wxMS45OTkzIDIuMjc2NDdMNS45OTkzNSA4LjI3NjQ3WiIgZmlsbD0iIzRBQkU4NiIvPgo8L3N2Zz4K);
	mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yLjY2NjAyIDMuMzMzMzNDMi40ODkyIDMuMzMzMzMgMi4zMTk2NCAzLjQwMzU3IDIuMTk0NjEgMy41Mjg2QzIuMDY5NTkgMy42NTM2MiAxLjk5OTM1IDMuODIzMTkgMS45OTkzNSA0VjEzLjMzMzNDMS45OTkzNSAxMy41MTAxIDIuMDY5NTkgMTMuNjc5NyAyLjE5NDYxIDEzLjgwNDdDMi4zMTk2NCAxMy45Mjk4IDIuNDg5MiAxNCAyLjY2NjAyIDE0SDExLjk5OTNDMTIuMTc2MiAxNCAxMi4zNDU3IDEzLjkyOTggMTIuNDcwOCAxMy44MDQ3QzEyLjU5NTggMTMuNjc5NyAxMi42NjYgMTMuNTEwMSAxMi42NjYgMTMuMzMzM1Y5Ljc3MzMzQzEyLjY2NiA5LjQwNTE0IDEyLjk2NDUgOS4xMDY2NyAxMy4zMzI3IDkuMTA2NjdDMTMuNzAwOSA5LjEwNjY3IDEzLjk5OTMgOS40MDUxNCAxMy45OTkzIDkuNzczMzNWMTMuMzMzM0MxMy45OTkzIDEzLjg2MzggMTMuNzg4NiAxNC4zNzI1IDEzLjQxMzYgMTQuNzQ3NUMxMy4wMzg1IDE1LjEyMjYgMTIuNTI5OCAxNS4zMzMzIDExLjk5OTMgMTUuMzMzM0gyLjY2NjAyQzIuMTM1NTggMTUuMzMzMyAxLjYyNjg3IDE1LjEyMjYgMS4yNTE4IDE0Ljc0NzVDMC44NzY3MjkgMTQuMzcyNSAwLjY2NjAxNiAxMy44NjM4IDAuNjY2MDE2IDEzLjMzMzNWNEMwLjY2NjAxNiAzLjQ2OTU3IDAuODc2NzI5IDIuOTYwODYgMS4yNTE4IDIuNTg1NzlDMS42MjY4NyAyLjIxMDcxIDIuMTM1NTggMiAyLjY2NjAyIDJINi4yMjYwMkM2LjU5NDIxIDIgNi44OTI2OCAyLjI5ODQ4IDYuODkyNjggMi42NjY2N0M2Ljg5MjY4IDMuMDM0ODYgNi41OTQyMSAzLjMzMzMzIDYuMjI2MDIgMy4zMzMzM0gyLjY2NjAyWiIgZmlsbD0iIzRBQkU4NiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjUyNzkgMC44NjIyNTRDMTEuNzg4MyAwLjYwMTkwNSAxMi4yMTA0IDAuNjAxOTA1IDEyLjQ3MDggMC44NjIyNTRMMTUuMTM3NCAzLjUyODkyQzE1LjM5NzggMy43ODkyNyAxNS4zOTc4IDQuMjExMzggMTUuMTM3NCA0LjQ3MTczTDguNDcwNzUgMTEuMTM4NEM4LjM0NTczIDExLjI2MzQgOC4xNzYxNiAxMS4zMzM3IDcuOTk5MzUgMTEuMzMzN0g1LjMzMjY4QzQuOTY0NDkgMTEuMzMzNyA0LjY2NjAyIDExLjAzNTIgNC42NjYwMiAxMC42NjdWOC4wMDAzM0M0LjY2NjAyIDcuODIzNTEgNC43MzYyNSA3LjY1Mzk0IDQuODYxMjggNy41Mjg5MkwxMS41Mjc5IDAuODYyMjU0Wk01Ljk5OTM1IDguMjc2NDdWMTAuMDAwM0g3LjcyMzIxTDEzLjcyMzIgNC4wMDAzM0wxMS45OTkzIDIuMjc2NDdMNS45OTkzNSA4LjI3NjQ3WiIgZmlsbD0iIzRBQkU4NiIvPgo8L3N2Zz4K);
	display: inline-block;
	padding-right: 3px;
	vertical-align: middle;
}

span.editFrequency:hover:after {
	background-color: var(--sub-fill-light);
	cursor: pointer;
}

select.unique {
	background-color: #e8f1f8;
	background: #e8f1f8 url(../img/chevron-right.svg) no-repeat center right
		10px;
	font-weight: 900 !important;
	padding-top: 2px;
	padding-left: 12px;
	font-size: 13px;
	width: 100%;
	height: 100%;
	border: 0px !important;
	text-align: -webkit-center;
	color: #69747a;
	border: 1px solid #eaecf0;
	box-sizing: border-box;
	border-radius: 0px 4px 4px 0px;
	-webkit-appearance: none;
	outline: none;
	box-shadow: none;
}

input.form-control.frequency {
	font-weight: 900 !important;
	color: #69747a;
	background: #ffffff;
	border: 1px solid #e8f1f8;
	box-sizing: border-box;
	border-radius: 15px;
	font-size: 13px;
	outline: none;
	box-shadow: none;
	border-radius: 4px 0px 0px 4px;
	border-right: 0px;
}

button.save-freq {
	background: var(--main-fill-normal);
	font-size: 12px;
	width: 100%;
	padding: 2px;
	outline: none;
	box-shadow: none;
	color: #ffffff !important;
	font-family: "TG-Medium";
	margin-top: 10px;
}

span.minText {
	font-size: 12px;
	line-height: normal;
}

.loading-box {
	min-height: 300px;
	justify-content: center;
	min-height: 300px;
	display: flex;
	align-items: center;
}

.loading-box .spinner-grow {
	width: 5rem;
	height: 5rem;
	background-color: var(--main-fill-light);
}

.savings-box:hover {
	cursor: pointer;
}

.dish-center {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.empty-trans {
	background: linear-gradient(0deg, #ffffff, #ffffff);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	min-height: 300px;
	margin: 0;
}

.empty-trans .empty-circle {
	width: 150px;
	height: 150px;
	background: #f5f6f7ab;
	border-radius: 75px;
}

.empty-trans h6,
.notready {
	margin-top: 40px;
	font-size: 18px;
	text-align: center;
	letter-spacing: 0.5px;
	color: #9ba6af;
	text-transform: capitalize;
}

h6.fat-error {
	font-size: 14px;
	color: #a94442;
	text-align: center;
}

/* Incorrect Password */

.dropdown-toggle:after {
	content: none;
}

.apexcharts-legend.center.position-bottom {
	/* top: -40px !important; */
	bottom: auto !important;
}

/* Settings CSS */

#setTab {
	justify-content: center;
	width: 100%;
	border-bottom: 1px solid #e8f1f8;
}

#setTab.nav-tabs .nav-item {
	/* width: 50%; */
	text-align: center;
	padding: 0 15px;
}

#setTab.nav-tabs .nav-link {
	/* font-family: 'TG-Medium'; */
	/* color: var(--gray-fill-normal); */
	color: var(--gray-fill-light);
	border: 2px solid transparent;
	font-size: 14px;
	font-weight: bolder;
	letter-spacing: 0.15px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.8;
	/* transition: all 0.5s; */
	padding-bottom: 16px;
}

#setTab.nav-tabs .nav-link.active {
	/* font-family: 'TG-Medium'; */
	opacity: 1;
	color: #d1a23c !important;
	background-color: transparent;
	border-color: transparent transparent #d1a23c !important;
}

#setTab.nav-tabs .nav-link:focus,
#setTab.nav-tabs .nav-link:hover {
	color: var(--gray-fill-light);
	border-color: transparent;
}

#accordion .card,
.custom-accordion .card {
	border: 0px !important;
	border-radius: 0px;
	font-size: 14px;
}

#accordion .dialog-info,
.custom-accordion .dialog-info {
	border: 0px !important;
}

#accordion .card-header:first-child,
.custom-accordion .card-header:first-child {
	border-bottom: 0.75px solid #e8f1f8;
	background-color: #ffffff;
	padding-left: 0px;
}

#accordion .card-header:first-child h5 button,
.custom-accordion .card-header:first-child h5 button {
	display: flex;
	letter-spacing: 0.35px;
	/* justify-content: center; */
	align-items: center;
	padding-left: 0px;
	font-size: 14px;
	color: #37474f;
	font-weight: bold;
	width: 100%;
	text-align: left;
	text-decoration: none;
	cursor: pointer;
}

#accordion .card-header .btn:hover,
.custom-accordion .card-header .btn:hover {
	opacity: 0.8;
}

/* #accordion .card-header .btn[aria-expanded=true],
.custom-accordion .card-header .btn[aria-expanded=true] {
    color: #76502f; */
/* opacity: 0.8;
    font-weight: lighter;
    font-family: "TG-Medium"; 
    font-weight: 100;
} */

#accordion .card-header .btn::after,
.custom-accordion .card-header .btn::after {
	/* background: #0D067C; */
	background: #69747a;
	content: "";
	width: 12px;
	height: 7.42px;
	-webkit-mask-image: url(../img/sue.svg);
	mask-image: url(../img/sue.svg);
	display: block;
	margin-left: auto;
	transform: rotate(180deg);
	-moz-transition: all 0.5s ease;
	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

#accordion .card-header .btn[aria-expanded="true"]::after,
.custom-accordion .card-header .btn[aria-expanded="true"]::after {
	/* background: #76502f; */
	transform: rotate(0deg);
}

#accordion .card-body .dialog-info label,
.custom-accordion .card-body .dialog-info label {
	font-size: 12px;
	line-height: 18px;
	color: #69747a;
	text-transform: capitalize;
}

#accordion .card-body .dialog-info p,
.custom-accordion .card-body .dialog-info p {
	font-size: 14px;
	line-height: 21px;
	font-family: "TG-Light";
	/* identical to box height */
	color: #37474f;
}

.setElement {
	padding-bottom: 30px;
	/* border-bottom: 0.75px solid #E8F1F8; */
}

hr.sinc {
	margin: 15px 0px;
	border: 0.55px solid #e8f1f8;
}

.setElement label {
	font-size: 12px;
	line-height: 18px;
	color: #69747a;
	margin-bottom: 0px;
	text-transform: capitalize;
}

.setElement h6 {
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 12px;
	text-transform: uppercase;
	line-height: 18px;
	letter-spacing: 0.04em;
	color: #69747a;
}

.btn.limit {
	font-size: 13px;
	/* font-family: TG-Medium; */
	font-weight: bolder;
	padding: 5px;
	width: 100%;
	padding: 8px;
	line-height: 12px;
	text-align: center;
	color: #4abe86;
	border: 1px solid #4abe86;
	box-sizing: border-box;
	border-radius: 4px;
}

.btn.setPrim {
	font-size: 12px;
	font-weight: 900;
	width: 100%;
	text-align: center;
	color: #37474f;
	border: 1.2px solid #eaecf0;
	box-sizing: border-box;
	border-radius: 4px;
}

.apexcharts-legend-text {
	font-size: 12px;
	font-family: TG-Light !important;
}

input.transfew {
	background: #ffffff !important;
	padding-left: 0px;
	font-size: 14px;
	line-height: 21px;
	font-family: "TG-Light";
	color: #37474f;
	border: 0px;
	border-bottom: 1px solid transparent;
	border-color: transparent;
	border-radius: 0px;
	outline: none !important;
	box-shadow: none !important;
	transition: all 0.3s linear;
	-webkit-appearance: none;
}

input.transfew:not(:read-only) {
	border-color: #eaecf0;
}

input.transfew:-moz-read-only,
input.transfew:read-only {
	/* For Firefox */
	/* border: 0px; */
	border-bottom: 1px transparent !important;
	/* border-bottom: 1px solid #EAECF0; */
}

.marTy {
	padding-top: 1rem !important;
}

/* .marTy .row {} */

.center-img {
	display: block;
	margin: auto;
}

/* Custom Icons */

.user-icon {
	display: inline-block;
	background-color: var(--gray-fill-normal);
	-webkit-mask: url(../img/user.svg) no-repeat center;
	mask: url(../img/user.svg) no-repeat center;
	width: 20px;
	height: 20px;
	opacity: 0.8;
	transition: all 0.5s;
}

.credit-icon {
	display: inline-block;
	background-color: var(--gray-fill-normal);
	-webkit-mask: url(../img/credit-card.svg) no-repeat center;
	mask: url(../img/credit-card.svg) no-repeat center;
	width: 22px;
	height: 22px;
	opacity: 0.8;
	transition: all 0.5s;
}

.smartshield {
	display: inline-block;
	background-color: var(--gray-fill-normal);
	-webkit-mask: url(../img/smartshield.svg) no-repeat center;
	mask: url(../img/smartshield.svg) no-repeat center;
	width: 20px;
	height: 20px;
	opacity: 0.8;
	transition: all 0.5s;
}

.bigedit {
	display: inline-block;
	background-color: var(--gray-fill-normal);
	-webkit-mask: url(../img/bigedit.svg) no-repeat center;
	mask: url(../img/bigedit.svg) no-repeat center;
	width: 20px;
	height: 20px;
	opacity: 0.8;
	/* transition: all 0.5s; */
}

#setTab li.nav-item a.nav-link span {
	margin-right: 10px;
}

#setTab li.nav-item a.nav-link:not(.active) span {
	background-color: var(--gray-fill-light);
}

#setTab li.nav-item a.nav-link.active span {
	background-color: #d1a23c !important;
	opacity: 1;
}

#accordion .card-header {
	padding-right: 0px;
}

hr.bunny {
	margin: 15px 0;
	border: 0.55px solid #e8f1f8;
}

h6.grap {
	color: var(--gray-fill-normal);
	font-family: "TG-Medium";
	font-size: 14px;
	line-height: normal;
	text-transform: capitalize;
}

#accordion .form-control[readonly]:focus {
	border-color: transparent;
}

select.smartSec {
	font-weight: 900 !important;
	padding: 0px;
	font-size: 13px;
	width: 100%;
	height: 35px;
	border: 0px !important;
	text-align: -webkit-left;
	color: #69747a;
	border: 1px solid #eaecf0;
	box-sizing: border-box;
	border-radius: 0px;
	outline: none;
	box-shadow: none;
}

select.smartSec[readonly] {
	background: #ffffff;
	-webkit-appearance: none;
}

select.smartSec[readonly] option {
	display: none;
}

.toast {
	font-family: "TG-Light" !important;
	opacity: 1 !important;
}

.toasts-container {
	font-family: "TG-Light";
	top: 20% !important;
	right: 50% !important;
	opacity: 1 !important;
}

.toast.toast-success {
	background-color: #49a254 !important;
	border-color: #49a254 !important;
	color: #fff !important;
}

.toast.toast-error {
	background-color: #C74242 !important;
	border-color: #C74242 !important;
	color: #fff !important;
}

.toast.toast-info {
	background-color: #3BABC5 !important;
	border-color: #3BABC5 !important;
	color: #fff !important;
}

.toast.toast-warning {
	background-color: #DE964A !important;
	border-color: #DE964A !important;
	color: #fff !important;
}

/* SIDE NAV ICONS */

.bigedit {
	display: inline-block;
	background-color: var(--gray-fill-normal);
	-webkit-mask: url(../img/bigedit.svg) no-repeat center;
	mask: url(../img/bigedit.svg) no-repeat center;
	width: 20px;
	height: 20px;
	opacity: 0.8;
	transition: all 0.5s;
}

.side-icon {
	background-color: #000;
	display: inline-block;
	transition: all 0.5s;
	width: 18px;
	height: 18px;
}

.home-icon {
	-webkit-mask: url(../img/home_icon.svg) no-repeat center left;
	mask: url(../img/home_icon.svg) no-repeat center left;
}

.repeat-icon {
	-webkit-mask: url(../img/repeat.svg) no-repeat center left;
	mask: url(../img/repeat.svg) no-repeat center left;
	/* width: 18px;
    height: 18px; */
}

.phone-icon {
	-webkit-mask: url(../img/smartphone.svg) no-repeat center left;
	mask: url(../img/smartphone.svg) no-repeat center left;
}

.savings-icon {
	-webkit-mask: url(../img/savings_wallet_icon.svg) no-repeat center left;
	mask: url(../img/savings_wallet_icon.svg) no-repeat center left;
}

.bar-icon {
	-webkit-mask: url(../img/bar-chart-2.svg) no-repeat center left;
	mask: url(../img/bar-chart-2.svg) no-repeat center left;
}

.globe-icon {
	-webkit-mask: url(../img/globe.svg) no-repeat center left;
	mask: url(../img/globe.svg) no-repeat center left;
}

.bills-icon {
	-webkit-mask: url(../img/bills_icon.svg) no-repeat center left;
	mask: url(../img/bills_icon.svg) no-repeat center left;
}

.send-money-icon {
	-webkit-mask: url(../img/send_money_icon.svg) no-repeat center left;
	mask: url(../img/send_money_icon.svg) no-repeat center left;
}

.settings-icon {
	-webkit-mask: url(../img/settings.svg) no-repeat center left;
	mask: url(../img/settings.svg) no-repeat center left;
	/* width: 18px;
    height: 18px; */
}

.beni-icon {
	-webkit-mask: url(../img/beni-boy.svg) no-repeat center left;
	mask: url(../img/beni-boy.svg) no-repeat center left;
}

.cashmoni-icon {
	-webkit-mask: url(../img/cashmoney.svg) no-repeat center left;
	mask: url(../img/cashmoney.svg) no-repeat center left;
}

.logout-icon {
	-webkit-mask: url(../img/logout_new_icon.svg) no-repeat center left;
	mask: url(../img/logout_new_icon.svg) no-repeat center left;
}

.notis-icon {
	-webkit-mask: url(../img/notification.svg) no-repeat center left;
	mask: url(../img/notification.svg) no-repeat center left;
}

.chart-icon {
	-webkit-mask: url(../img/chart.svg) no-repeat center left;
	mask: url(../img/chart.svg) no-repeat center left;
}

.investment-icon {
	-webkit-mask: url(../img/investment_icon.svg) no-repeat center left;
	mask: url(../img/investment_icon.svg) no-repeat center left;
}

.market-place-icon {
	-webkit-mask: url(../img/market_place_icon.svg) no-repeat center left;
	mask: url(../img/market_place_icon.svg) no-repeat center left;
}

.beneficiary-icon {
	-webkit-mask: url(../img/beneficiary_icon.svg) no-repeat center left;
	mask: url(../img/beneficiary_icon.svg) no-repeat center left;
}

.transaction-history-icon {
	-webkit-mask: url(../img/transaction_history_icon.svg) no-repeat center left;
	mask: url(../img/transaction_history_icon.svg) no-repeat center left;
}

.settings-nav-icon {
	-webkit-mask: url(../img/settings_nav_icon.svg) no-repeat center left;
	mask: url(../img/settings_nav_icon.svg) no-repeat center left;
}

.top-up-icon {
	-webkit-mask: url(../img/top_up_icon.svg) no-repeat center left;
	mask: url(../img/top_up_icon.svg) no-repeat center left;
}

.transer-icon {
	-webkit-mask: url(../img/transfer_icon.svg) no-repeat center left;
	mask: url(../img/transfer_icon.svg) no-repeat center left;
}

.pay-bill-icon {
	-webkit-mask: url(../img/pay-bills_icon.svg) no-repeat center left;
	mask: url(../img/pay-bills_icon.svg) no-repeat center left;
}

.piggy-icon {
	-webkit-mask: url(../img/piggy_icon.svg) no-repeat center left;
	mask: url(../img/piggy_icon.svg) no-repeat center left;
}

.pending-approvals-icon {
	-webkit-mask: url(../img/pending_approvals_icon.svg) no-repeat center left;
	mask: url(../img/pending_approvals_icon.svg) no-repeat center left;
}

.approved-transactions-icon {
	-webkit-mask: url(../img/approved_transactions_icon.svg) no-repeat center
		left;
	mask: url(../img/approved_transactions_icon.svg) no-repeat center left;
}

.chevron-down {
	-webkit-mask: url(../img/chevron-down.svg) no-repeat center left;
	mask: url(../img/chevron-down.svg) no-repeat center left;
}

.top-up-nav-icon {
	-webkit-mask: url(../img/top_up.svg) no-repeat center left;
	mask: url(../img/top_up.svg) no-repeat center left;
}

span.side-text {
	/* line-height: 13px; */
	margin: auto;
	margin-left: 13px;
}

.custom-control-label.forRight::after,
.custom-control-label.forRight::before {
	top: 0.15rem;
	position: absolute;
	left: calc(100% - 15px);
}

.custom-control-label.smallLetters {
	font-size: 12px !important;
	font-weight: bold;
	letter-spacing: 0.25px;
	font-family: TG-Light !important;
	text-transform: capitalize;
	color: #69747a !important;
}

.smart-class {
	width: 100%;
}

#transcations.table thead tr th {
	border: 0px;
	/* text-align: center; */
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.02em;
	padding-left: 20px;
	font-family: "TG-Medium";
	font-weight: 100;
	color: #37474f;
	background-color: #faf5eb;
}

#transcations.table thead tr th:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

#transcations.table thead tr th:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

#transcations.table tbody tr td {
	font-size: 14px;
	padding-left: 20px;
	/* text-align: center; */
	border: 0px;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.02em;
	color: #37474f;
}

#transcations.table tbody tr td:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

#transcations.table tbody tr td:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.trans-row {
	margin: 20px 0px;
	background: #ffffff;
	/* border: 1px solid red; */
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

tr.trans-row td {
	padding: 25px 0;
}

.trans-row:hover {
	box-shadow: none;
	background: #f5f5f5;
	cursor: pointer;
}

#transcations.table {
	border-collapse: separate;
	border-spacing: 0 1em;
}

.credit {
	border-left: 3px solid #d1a23c !important;
}

.debit {
	border-left: 3px solid #231f20 !important;
}

.form-control.transSearch {
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	height: 100%;
	border: 1px solid #eaecf0;
	font-weight: 500;
	font-size: 13px !important;
	line-height: normal;
	letter-spacing: 0.02em;
	color: #37474f;
	background: url(../img/trans-search.svg) no-repeat right 10px center;
	background-size: 20px;
}

.btn.export {
	/* font-family: "TG-Medium"; */
	font-weight: 900;
	font-size: 12px;
	line-height: normal;
	text-align: center;
	color: #4abe86;
	border: 1.4px solid #4abe86;
	box-sizing: border-box;
	border-radius: 4px;
	height: calc(1.5em + 0.75rem + 2px);
}

.btn.shupNew {
	font-weight: bolder;
	font-size: 14px;
	line-height: normal;
	text-align: center;
	color: #ffffff;
	background: #d1a23c;
	border: 0px;
	box-sizing: border-box;
	border-radius: 15px;
	height: 100%;
}

.page-item.active .page-link {
	color: #fff;
	background-color: var(--mint-dark-blue);
	border-color: var(--mint-dark-blue);
	/* border-radius: 3px; */
}

.form-control.sapDate {
	outline: none;
	box-shadow: none;
	-webkit-appearance: none;
	height: 100%;
	border: 1px solid #eaecf0;
	font-weight: 500;
	font-size: 13px;
	line-height: normal;
	letter-spacing: 0.02em;
	color: #37474f;
	/* margin-right: 10px; */
}

/* .form-control.sapDate{

} */

#socials a {
	margin-right: 15px;
}

.sweet_subText {
	letter-spacing: 0.25px;
	padding-left: 0px;
	font-size: 14px;
	color: #37474f;
	font-weight: bold;
}

.page-link {
	line-height: 1.25;
	font-size: 14px;
	color: #4d5c63;
	background-color: #fff;
	border: 0px;
	outline: none !important;
	box-shadow: none !important;
}

.page-link:hover {
	z-index: 2;
	color: #4d5c63;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
	border-radius: 0px;
}

.greyworm {
	width: 100%;
	display: flex;
	align-items: center;
	font-family: "TG-Medium";
	font-weight: lighter;
	font-size: 13px;
	text-align: left;
	letter-spacing: 0.25px;
	text-decoration: none !important;
	color: #37474f !important;
	/* background: #E8F3F8; */
	padding: 15px;
}

.btn-link:hover {
	text-decoration: none;
}

a[disabled] {
	pointer-events: none;
}

.progress-bar {
	-webkit-transition: width 2.5s ease;
	transition: width 2.5s ease;
}

.ball-circle {
	display: inline-block;
	background-color: #94e6be;
	height: 15px;
	width: 15px;
	border-radius: 25px;
	margin-left: 10px;
}

.lds-facebook {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}

.lds-facebook div {
	display: inline-block;
	position: absolute;
	left: 6px;
	width: 13px;
	background: #94e6be;
	animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
	left: 6px;
	animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
	left: 26px;
	animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
	left: 45px;
	animation-delay: 0;
}

@keyframes lds-facebook {
	0% {
		top: 6px;
		height: 51px;
	}
	50%,
	100% {
		top: 19px;
		height: 26px;
	}
}

/* Modal OverLay */

.loading-fx {
	width: 100%;
	height: 100%;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}

.lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 32px 32px;
}

.lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: var(--main-fill-normal);
	margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
	top: 50px;
	left: 50px;
}

.lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
	top: 54px;
	left: 45px;
}

.lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
	top: 57px;
	left: 39px;
}

.lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
	top: 58px;
	left: 32px;
}

.lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
	top: 57px;
	left: 25px;
}

.lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
	top: 54px;
	left: 19px;
}

.lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
	top: 50px;
	left: 14px;
}

.lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
	top: 45px;
	left: 10px;
}

@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

#accordion {
	padding: 10px 20px;
}

#beneficiary.table thead tr th {
	border: 0px;
	/* text-align: center; */
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.02em;
	padding-left: 20px;
	font-family: "TG-Medium";
	font-weight: 100;
	color: #37474f;
	background-color: #faf5eb;
}

#beneficiary.table thead tr th:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

#beneficiary.table thead tr th:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

#beneficiary.table tbody tr td {
	font-size: 14px;
	padding-left: 20px;
	padding-bottom: 0px;
	/* text-align: center; */
	border: 0px;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.02em;
	color: #37474f;
}

#beneficiary.table tbody tr td:first-child {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

#beneficiary.table tbody tr td:last-child {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

/* #beneficiary.table {
    border-collapse: separate;
    border-spacing: 0 0.2em;
} */

.small-bank {
	font-weight: 900;
	text-align: center;
	font-size: 12px;
	line-height: 18px;
	color: #37474f;
}

.btn.remove-bene {
	border: 0px;
	/* border: 1.4px solid #4ABE86; */
	box-sizing: border-box;
	border-radius: 4px;
	background: transparent;
	text-align: center;
	font-family: "TG-Medium";
	font-weight: 100;
	font-size: 12px;
	line-height: 18px;
	/* letter-spacing: 0.02em; */
	color: #d1a23c;
}

.btn.send-money {
	border: 1.4px solid #d1a23c;
	box-sizing: border-box;
	border-radius: 4px;
	background: transparent;
	text-align: center;

	font-family: "TG-Medium";
	font-weight: 100;
	font-size: 12px;
	line-height: normal;
	letter-spacing: 0.15px;
	color: #d1a23c;
	padding: 4px 10px;

	text-transform: lowercase;
}

.myText {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: #37474f;
}

.myText strong {
	font-family: TG-Medium;
}

img.smartToggle {
	width: 18px;
	height: 18px;
}

.notis-end {
	border-bottom: 0.75px solid #e8f1f8;
	padding-bottom: 10px;
}

.notis-end h6 {
	font-size: 16px;
	line-height: 24px;
	color: #0d067c;
}

.notis-end p {
	font-size: 13px;
	line-height: 21px;
	color: #37474f;
	text-align: justify;
}

.timeSheet {
	font-size: 14px;
	line-height: 21px;
	color: #828282;
}

.accNum {
	color: #ffffff;
	font-weight: bolder;
	font-size: 16px;
	letter-spacing: 0.5px;
}

.theMint {
	display: block;
	margin: auto;
}

.mintLeft {
	transform-origin: bottom left;
	-ms-transform-origin: bottom left;
	/* IE 9 */
	-webkit-transform-origin: bottom left;
	/* Safari 3-8 */
	transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
	transition-delay: 0.1s;
	animation: mintFromLeft 3.5s infinite;
	animation-delay: 1s;
	/* animation: fadeInLeft 2s; */
}

.mintRight {
	transform-origin: bottom right;
	-ms-transform-origin: bottom right;
	/* IE 9 */
	-webkit-transform-origin: bottom right;
	/* Safari 3-8 */
	transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
	transition-delay: 0.1s;
	animation: mintFromRight 3.5s infinite;
	animation-delay: 1s;
}

.mintCircle {
	animation: showUp 5s linear infinite;
	animation-delay: 1s;
}

@-webkit-keyframes mintFromLeft {
	0%,
	10% {
		opacity: 1;
		-webkit-transform: rotate(0deg);
	}
	22%,
	30% {
		opacity: 0;
	}
	60%,
	100% {
		opacity: 1;
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes mintFromRight {
	0%,
	10% {
		opacity: 1;
		-webkit-transform: rotate(0deg);
	}
	22%,
	30% {
		opacity: 0;
	}
	60%,
	100% {
		opacity: 1;
		-webkit-transform: rotate(-360deg);
	}
}

@-webkit-keyframes showUp {
	0%,
	10% {
		opacity: 1;
	}
	22%,
	30% {
		opacity: 0;
	}
	90%,
	100% {
		opacity: 1;
	}
}

.kindTell {
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: #ff4c54;
}

/* button{
    overflow: hidden;
}

button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, .5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%;
}

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(40, 40);
    }
}
  
button:focus:not(:active)::after {
   animation: ripple 1s ease-out;
} */

.more {
	font-family: TG-Medium;
}

.bulletLimit {
	padding: 0px;
	/* height: 16px; */
	margin-bottom: 0px;
	color: #69747a !important;
	font-size: 12px !important;
	margin: auto;
}

/* Loan Factors */

.loanBox h6.titl {
	color: #37474f;
	letter-spacing: 0.25px;
	text-align: center;
	font-size: 16px;
	font-family: "TG-Medium";
	font-weight: 100;
}

.repreGroup {
	margin: 10px 0;
	margin-bottom: 25px;
}

.repreGroup label {
	font-family: "TG-Medium";
	font-weight: 100;
	font-size: 15px;
	line-height: 21px;
	color: #37474f;
}

.smart-home {
	padding: 10px;
	width: 100%;
	/* font-family: "objectivityRegular"; */
	font-size: 14px;
	text-align: center;
	color: #36474f;
	background: #ffffff;
	border: 1px solid #e8f1f8;
	border-radius: 4px;
	outline: none;
	box-shadow: none;
}

/* Slider */

.ant-slider {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5;
	list-style: none;
	-webkit-font-feature-settings: "tnum";
	font-feature-settings: "tnum";
	position: relative;
	height: 12px;
	margin: 14px 6px 10px;
	padding: 4px 0;
	cursor: pointer;
	-ms-touch-action: none;
	touch-action: none;
}

.ant-slider-vertical {
	width: 12px;
	height: 100%;
	margin: 6px 10px;
	padding: 0 4px;
}

.ant-slider-vertical .ant-slider-rail {
	width: 4px;
	height: 100%;
}

.ant-slider-vertical .ant-slider-track {
	width: 4px;
}

.ant-slider-vertical .ant-slider-handle {
	margin-bottom: -7px;
	margin-left: -5px;
}

.ant-slider-vertical .ant-slider-mark {
	top: 0;
	left: 12px;
	width: 18px;
	height: 100%;
}

.ant-slider-vertical .ant-slider-mark-text {
	left: 4px;
	white-space: nowrap;
}

.ant-slider-vertical .ant-slider-step {
	width: 4px;
	height: 100%;
}

.ant-slider-vertical .ant-slider-dot {
	top: auto;
	left: 2px;
	margin-bottom: -4px;
}

.ant-slider-with-marks {
	margin-bottom: 28px;
}

.ant-slider-rail {
	position: absolute;
	width: 100%;
	height: 4px;
	background-color: #f5f5f5;
	border-radius: 2px;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.ant-slider-track {
	position: absolute;
	height: 4px;
	background-color: #91d5ff;
	border-radius: 4px;
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.ant-slider-handle {
	position: absolute;
	width: 14px;
	height: 14px;
	margin-top: -5px;
	margin-left: -7px;
	background-color: #fff;
	border: solid 2px #91d5ff;
	border-radius: 50%;
	-webkit-box-shadow: 0;
	box-shadow: 0;
	cursor: pointer;
	-webkit-transition: border-color 0.3s, -webkit-box-shadow 0.6s,
		-webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	transition: border-color 0.3s, -webkit-box-shadow 0.6s,
		-webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	transition: border-color 0.3s, box-shadow 0.6s,
		transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	transition: border-color 0.3s, box-shadow 0.6s,
		transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
		-webkit-box-shadow 0.6s,
		-webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-slider-handle:focus {
	border-color: #46a6ff;
	outline: none;
	-webkit-box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
}

.ant-slider-handle.ant-tooltip-open {
	border-color: #1890ff;
}

.ant-slider:hover .ant-slider-rail {
	background-color: #e1e1e1;
}

.ant-slider:hover .ant-slider-track {
	background-color: #69c0ff;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
	border-color: #69c0ff;
}

.ant-slider-mark {
	position: absolute;
	top: 14px;
	left: 0;
	width: 100%;
	font-size: 14px;
}

.ant-slider-mark-text {
	position: absolute;
	display: inline-block;
	color: rgba(0, 0, 0, 0.45);
	text-align: center;
	word-break: keep-all;
	cursor: pointer;
}

.ant-slider-mark-text-active {
	color: rgba(0, 0, 0, 0.65);
}

.ant-slider-step {
	position: absolute;
	width: 100%;
	height: 4px;
	background: transparent;
}

.ant-slider-dot {
	position: absolute;
	top: -2px;
	width: 8px;
	height: 8px;
	margin-left: -4px;
	background-color: #fff;
	border: 2px solid #e8e8e8;
	border-radius: 50%;
	cursor: pointer;
}

.ant-slider-dot:first-child {
	margin-left: -4px;
}

.ant-slider-dot:last-child {
	margin-left: -4px;
}

.ant-slider-dot-active {
	border-color: #8cc8ff;
}

.ant-slider-disabled {
	cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-track {
	background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
	background-color: #fff;
	border-color: rgba(0, 0, 0, 0.25) !important;
	-webkit-box-shadow: none;
	box-shadow: none;
	cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
	cursor: not-allowed !important;
}

.ant-slider-with-marks {
	margin-bottom: 60px !important;
}

.ant-slider-mark {
	margin-top: 6px !important;
}

.ant-slider-track {
	background-color: #0d067c !important;
}

.ant-slider-handle,
.ant-slider-dot-active {
	border-color: #0d067c !important;
	background-color: #0d067c !important;
}

.ant-slider-rail {
	background-color: #e8f1f8 !important;
}

.ant-slider-dot:last-child {
	display: none !important;
}

.ant-slider-mark span:first-child {
	left: 22px !important;
}

.ant-slider-mark span:last-child {
	left: 95% !important;
}

.specialAnt .ant-slider-mark span:first-child {
	left: 5px !important;
}

.specialAnt .ant-slider-mark span:last-child {
	right: 97% !important;
}

.loanResponse {
	padding: 20px;
	background: #ffffff;
	border: 1px solid #e8f1f8;
	box-sizing: border-box;
	border-radius: 4px;
}

.loanResponse h5 {
	font-size: 15px;
	color: #69747a;
	margin: 10px;
	letter-spacing: 0.25px;
}

.loanResponse h6 {
	font-family: "TG-Medium";
	font-weight: 100;
	font-size: 15px;
	letter-spacing: 0.25px;
	color: #37474f;
	margin: 10px;
}

.internet-error {
	background-color: #d30009;
	text-align: center;
	min-width: 100%;
	border: 0px;
	position: fixed;
	top: 0;
	padding: 4px;
	z-index: 10000;
	/* left: 0%; */
}

.internet-error p {
	margin-bottom: 0px;
	color: #fff;
	font-size: 13px !important;
	font-weight: bold;
	letter-spacing: 0.65px;
	text-transform: uppercase;
}

.abs-white-half {
	display: flex;
	position: fixed;
	bottom: 0;
	background: black;
	width: 100%;
	height: 50%;
	/* color: #fff; */
	font-size: 14px;
	text-align: center;
}

.center-runway {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 20px;
}

.center-runway p {
	font-weight: 800;
	font-size: 16px;
	color: #fff;
	letter-spacing: 0.65px;
	margin: 30px 0;
	text-transform: capitalize;
}

.get-app {
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 0.55px;
	padding: 12px;
}

.gt-app-close {
	background: transparent !important;
	color: #fff !important;
	padding-left: 0px !important;
	text-shadow: none;
}

/* #sidebar li a.dropdown-toggle.active ~ .dropdown-menu {
	display: block;
} */
