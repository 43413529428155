@import "../../../assets/scss/master";

.insufficient-funds-modal {
	padding: 29px 16px 41px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 389px;

	> h3 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 24px 0px 0px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 331px;
		margin-top: 24px;
		margin-bottom: 42px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> button {
		text-transform: capitalize !important;
		border-radius: $border-radius !important;
		height: 50px !important;
	}
}
