@import "../../../../../assets/scss/master";

.bp-categories {
	width: 100%;
	padding: 0px 34px 100px;

	> h2 {
		text-transform: uppercase;
		color: $black;
		font-size: 12px;
		#{$midNightselectors} {
			color: #b3b3b3;
		}
	}

	&__categories {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(124px, 1fr));
		gap: 16px;
		margin-top: 32px;

		.bp-category-box {
			height: 100%;
			min-height: 136px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			border: 1px solid #eee;
			border-radius: 8px;
			#{$midNightselectors} {
				background-color: $dark-input;
				border-color: $dark-input;
			}

			&:hover {
				border-color: $main-fill-normal !important;
			}

			h3 {
				font-size: 12px;
				color: $black;
				margin: 0px;
				margin-top: 16px;
				text-align: center;
				#{$midNightselectors} {
					color: $white;
				}
			}
		}
	}

	// &__category {
	// }

	&__form {
		width: 90%;
		max-width: 488px;
		margin: 0 auto;

		label,
		.custom-control-label {
			color: $black !important;
			font-family: TG-Light;
			font-size: 14px;

			#{$midNightselectors} {
				color: $white !important;
			}
		}

		.form-control {
			height: 50px;
			font-size: 14px;
		}

		.form-group {
			margin-bottom: 0px;
		}

		.bp-input {
			height: 50px;
			font-size: 14px;
			margin-bottom: 0px;

			#{$midNightselectors} {
				background-color: $dark-input !important;
				color: $white !important;
				border-color: $border-medium !important;
			}

			&.has-error {
				border-color: #a94442 !important;
			}

			&.disabled {
				cursor: not-allowed;
			}
		}

		.react-select__control {
			height: 50px;
			outline: none;
			border-radius: 15px !important;

			input {
				color: $black !important;
				#{$midNightselectors} {
					color: $white !important;
				}
			}

			#{$midNightselectors} {
				border-color: $border-medium;
			}
		}

		.custom-option {
			p {
				color: $black;
				margin: 0px;
				font-size: 14px;
				#{$midNightselectors} {
					color: $white;
				}

				&:last-child {
					font-family: TG-Bold;
				}
			}
		}

		.intl-tel-input {
			display: block !important;
			// overflow: unset !important;

			.selected-flag {
				border-top-left-radius: 15px !important;
				border-bottom-left-radius: 15px !important;
				pointer-events: none !important;
			}
		}

		.bp-form-info {
			font-size: 11px;
			color: $black;
			display: block;
			margin-top: 7px;
			#{$midNightselectors} {
				color: $white;
			}
		}

		.bp-recurring-dates {
			display: flex;

			@media (max-width: 500px) {
				flex-direction: column;

				> * {
					margin-right: 0px !important;

					&:last-child {
						margin-top: 1.5rem;
					}
				}
			}

			> * {
				flex: 1;
			}

			.react-datepicker__input-container {
				> input {
					height: 50px;
					font-size: 14px;
					margin-bottom: 0px;

					#{$midNightselectors} {
						background-color: $dark-input !important;
						color: $white !important;
						border-color: $border-medium !important;
					}

					&.disabled {
						cursor: not-allowed;
					}
				}

				.datepicker--has-error {
					border-color: #a94442 !important;
					#{$midNightselectors} {
						border-color: #a94442 !important;
					}
				}
			}
		}

		.bp-frequency {
			display: flex;
			width: 100%;
			justify-content: space-between;

			@media (max-width: 899px) {
				flex-wrap: wrap;
			}

			> * {
				flex: 1;

				&:last-child {
					margin-right: 0px;
					margin-bottom: 0px;
				}

				@media (max-width: 400px) {
					flex: auto;
					margin-right: 0px;
				}

				@media (min-width: 900px) {
					margin-bottom: 0px;
				}
			}
		}

		> .btn {
			text-transform: capitalize !important;
			height: 50px !important;
		}
	}
}

.bp-summary-form {
	width: 100%;
	padding-top: 32px;
	padding-bottom: 32px;
	overflow-y: auto;

	.bp-summary-info {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #eee;
		padding-bottom: 16px;
		padding-top: 16px;
		#{$modalMidNightselectors} {
			border-color: #333333;
		}

		&:first-of-type {
			padding-top: 0;
		}

		span {
			color: #1b1b1b;
			#{$modalMidNightselectors} {
				color: #fff !important;
			}
		}
	}

	> button {
		text-transform: none !important;
		height: 50px;
	}

	&__footer {
		margin-top: 32px;
		display: flex;
		justify-content: space-between;
		width: 100%;

		@media (max-width: 420px) {
			flex-direction: column;
			justify-content: flex-start;
		}

		> button {
			width: 49% !important;
			height: 50px !important;
			font-size: 14px !important;
			text-transform: capitalize !important;
			font-family: TG-Semibold;
			border-radius: 15px;

			@media (max-width: 420px) {
				width: 100% !important;

				&:last-child {
					margin-top: 16px;
				}
			}
		}
	}
}

.bp-success-modal {
	display: flex;
	flex-direction: column;
	min-height: 332px;
	padding: 41px 16px;
	text-align: center;
	align-items: center;

	> h2 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 308px;
		margin-top: 10px;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	.bp-success-modal-body {
		margin-top: 24px;
		width: 100%;
		max-width: 340px;

		.bp-success-modal-info {
			display: flex;
			justify-content: space-between;
			padding-bottom: 12px;
			padding-top: 12px;
			border-bottom: 1px solid #eee;
			#{$modalMidNightselectors} {
				border-color: $border-medium;
			}

			&:first-of-type {
				padding-top: 0;
			}

			&.no-border {
				border: none;
				margin-top: 24px;
				padding-bottom: 0px;

				span {
					display: flex;
					align-items: center;
					line-height: 0;
					color: var(--main-fill-normal) !important;
					font-family: TG-Medium;

					#{$modalMidNightselectors} {
						color: var(--main-fill-normal) !important;
					}
				}
			}

			span {
				color: #1b1b1b;
				font-size: 13px;
				display: block;
				#{$modalMidNightselectors} {
					color: $white !important;
				}

				&:last-of-type {
					text-align: right;
				}
			}
		}
	}

	> button {
		text-transform: none !important;
		max-width: 304px;
		height: 50px;
	}
}

// Remita
.bp-remita {
	width: 90%;
	max-width: 488px;
	margin: 0 auto;
	padding-bottom: 100px;

	h2 {
		font-size: 14px;
		margin: 0px;
		font-family: TG-Medium;
		color: $black;
		margin-bottom: 48px;
		#{$midNightselectors} {
			color: $white;
		}
	}

	&__option {
		display: flex;
		align-items: center;
		padding: 16px;
		height: 96px;
		width: 100%;
		border-radius: 15px;
		background-color: transparent;
		border: 1px solid #eee;
		margin-bottom: 32px;
		#{$midNightselectors} {
			border-color: $dark-input;
			background-color: $dark-input;
		}

		&:last-of-type {
			margin-bottom: 0px;
		}

		&:hover {
			border-color: $main-fill-normal !important;
		}

		.bp-remita-option-info {
			margin-left: 12px;

			h3 {
				font-size: 14px;
				margin: 0px;
				font-family: TG-Medium;
				color: $black;
				#{$midNightselectors} {
					color: $white;
				}
			}

			p {
				color: #1b1b1b;
				font-size: 12px;
				line-height: 16px;
				max-width: 185px;
				margin: 0px;
				margin-top: 5px;
				#{$midNightselectors} {
					color: #808080;
				}
			}
		}
	}
}

.bp-remita-pay-via-rrr {
	width: 90%;
	max-width: 488px;
	margin: 0 auto;
	padding-bottom: 100px;

	h2 {
		font-size: 16px;
		margin: 0px;
		font-family: TG-Semibold;
		color: $black;
		margin-bottom: 8px;
		#{$midNightselectors} {
			color: $white;
		}
	}

	p {
		font-size: 15px;
		line-height: 18px;
		margin: 0px;
		color: $black;
		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}

	> form,
	.review-remita {
		width: 100%;
		margin-top: 48px;
	}
}
