.custom-table th,
.custom-table td {
    font-size: 14px !important;
    line-height: 26px !important;
}

.custom-table thead th {
    border: none !important;
    color: #637285;
    font-family: 'cairoregular';
    background: rgba(233, 240, 247, 0.5);
}

.custom-table td {
    padding-top: 17px !important;
    padding-bottom: 17px !important;
    color: #001526;
    vertical-align: middle !important;
    font-family: 'cairosemibold';
    border-top-color: rgba(233, 240, 247, 1) !important;
}
