@import "../master";

.button__container {
	button {
		display: none;
	}
}

.btn {
	font-size: 14px;
	text-align: center;

	&:disabled {
		cursor: not-allowed !important;
	}

	&.btn__link {
		&--gold {
			color: hsl(41, 61%, 53%);
			font-size: 13.5px;
			font-family: "TG-Medium";
			font-weight: 100;
			letter-spacing: 0.25px;
		}
	}

	&.big-button {
		font-family: "TG-Medium";
		width: 100%;
		padding: 15px;
		background: $main-fill-normal;
		border-radius: 0px 0px 3px 3px;
		border: 0px;
		font-weight: 100;
		letter-spacing: 0.25px;
		line-height: 21px;
		color: $white !important;
	}

	&.create-acc,
	&.btn-mint-default {
		width: 100%;
		padding: 10px;
		text-transform: capitalize;
		font-family: TG-Medium;
		font-weight: bolder;
		color: $white;
		font-size: 14px;
		text-align: center;
		letter-spacing: 0.65px;
		background: #000;
		box-shadow: 0px 5px 65px rgba(0, 0, 0, 0.1);
		border-radius: 15px; // 8px

		#{$midNightselectors} {
			background: $main-fill-normal;
		}
	}

	&.btn-cancel,
	&.cancel {
		font-family: "TG-Medium";
		width: 100%;
		padding: 10px;
		text-transform: uppercase;
		font-size: 14px;
		// color: $main-fill-normal;
		color: #000;
		box-shadow: none;
		border-radius: $border-radius;

		#{$midNightselectors} {
			color: $white;
		}
	}

	&.btn-custom-bordered {
		font-family: "TG-Medium";
		width: 100%;
		padding: 10px;
		text-transform: uppercase;
		font-size: 14px;
		color: $main-fill-normal;
		box-shadow: none;
		border: 1.2px solid $main-fill-normal;
		border-radius: $border-radius;
	}

	&.external__btn--link {
		display: flex !important;
		text-transform: capitalize;
		letter-spacing: 0.25px;
		line-height: 18px !important;
		align-items: center;
		padding-left: 0px !important;
		font-family: "TG-Medium";
		font-size: 13px !important;
		color: #37474f;
		font-weight: lighter;
		width: 100%;
		text-align: left;
		text-decoration: none;
		cursor: pointer;

		&:after {
			/* background: #0D067C; */
			background: #37474f;
			content: "";
			width: 24px;
			height: 24px;
			-webkit-mask-image: url(https://res.cloudinary.com/dswxtf2ay/image/upload/v1591781013/Mint_InternetBanking/icons/external-link_ubejop.svg);
			mask-image: url(https://res.cloudinary.com/dswxtf2ay/image/upload/v1591781013/Mint_InternetBanking/icons/external-link_ubejop.svg);
			display: block;
			margin-left: auto;
			transform: scale(0.8);
		}

		#{$midNightselectors} {
			color: $white;
			opacity: 0.75;
			&:after {
				background: $white;
			}
		}
	}
	&.gradient {
		color: $white;
		padding: 6px 10px;
		border-radius: 8px;
		border: 1px solid $main-fill-normal;
		background: linear-gradient(
			95.09deg,
			$main-fill-normal -10.51%,
			#77502f 109.52%
		);
	}

	&.btn-copy {
		width: 66px;
		height: 30px;
		text-transform: uppercase;
		color: $white;
		font-size: 12px;
		letter-spacing: 0.65px;
		background: #2b2b2b;
		border: 1px solid #808080;
		border-radius: 30px;
	}

	&.btn-setup-step {
		width: 93px;
		height: 39px;
		color: var(--mint-fill-darker);
		border: 1px solid var(--mint-fill-darker);
		font-size: 15px;
		background-color: rgba(209, 162, 60, 0.1);
		border-radius: 30px;
		font-family: TG-Medium;
	}

	&.btn-savings-sm {
		width: 73px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;
		color: $white;
		font-size: 12px;
		font-family: TG-Semibold;
		border-radius: 8px;
		background-color: $main-fill-normal;
		#{$midNightselectors} {
			background-color: $white;
			color: $black;
		}
	}

	&.btn-savings-lg {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;
		color: $white;
		font-size: 14px;
		font-family: TG-Semibold;
		border-radius: 15px;
		background-color: $main-fill-normal;
		#{$midNightselectors} {
			background-color: $white;
			color: $black;
		}
	}

	&.btn-bordered-light {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;
		color: $black;
		font-size: 14px;
		font-family: TG-Semibold;
		border-radius: 15px;
		background-color: transparent;
		border: 1px solid $gray;
		#{$midNightselectors} {
			border-color: $white;
			color: $white;
		}
	}

	&.btn-green {
		width: 100%;
		border: none;
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		color: $white;
		font-size: 12px;
		border-radius: 8px;
		background: #558760;
		font-family: TG-Semibold;
	}

	&.view-all-btn {
		width: fit-content;
		height: fit-content;
		display: flex;
		align-items: center;
		padding: 0px;
		font-size: 16px;
		border: none;
		line-height: 0px;
		color: $black;
		#{$midNightselectors} {
			color: $gray;
		}

		svg {
			margin-left: 8px;

			path {
				fill: $black;
				#{$midNightselectors} {
					fill: $gray;
				}
			}
		}
	}
}

.back-btn {
	background-color: transparent;
	border: none;
	padding: 0px;
	outline: none;
	color: var(--main-fill-normal);
	width: fit-content;
	height: fit-content;

	> img {
		margin-right: 15px;
		width: 9px;
		height: 100%;
	}

	> span {
		display: block;
	}
}

.retry__button {
	letter-spacing: 0.25px;
	font-family: "TG-Medium";
	font-size: 14px;
	font-weight: bold;
	color: #333333 !important;
	margin-top: 8px;
	// float: right;
	// display: inline-block;
	text-decoration: underline !important;
}

.th-details-btn {
	background-color: transparent;
	padding: 0px !important;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none !important;
	border: none !important;
	color: #3e62be;
	font-size: 12px;
	height: fit-content !important;
	text-decoration: underline;
}

@media (max-width: 768px) {
	.btn {
		&.create-acc,
		&.btn-mint-default {
			font-size: 12px !important;
		}
	}
}
