@import "../../../assets/scss/master";

.dashboard-finance-card {
	border: 1px solid #eee;
	border-radius: 8px;
	padding: 27px 19px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: unset;

	#{$midNightselectors} {
		border-color: $black-light;
		background-color: $black-light;
	}

	h3 {
		font-family: TG-Medium;
		color: $main-fill-normal;
		font-size: 14px;
		margin: 0px;
		margin-bottom: 8px;
	}

	h4 {
		color: $black;
		font-family: TG-Medium;
		font-size: 11px;
		margin: 0px;

		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}
}
