@import "../../../../../../../assets/scss/master";

.dashboardIntro {
	@at-root #{&}__heading {
		/* font-style: normal; */
		font-family: "TG-Medium";
		text-transform: capitalize;
		font-weight: 100;
		letter-spacing: 0.25px;
		font-size: 22px;
		line-height: 27px;
		color: $black;
		#{$midNightselectors} {
			color: $white;
		}
	}

	@at-root #{&}__paragraph {
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.35px;
		@at-root #{&}__bigTitle {
			font-weight: bolder !important;
			color: #21262a;
			letter-spacing: 0.4px;
			#{$midNightselectors} {
				color: #d3d2d2;
			}
		}
		@at-root #{&}__accNumber-text {
			letter-spacing: 1px;
			color: #37474f;
		}
	}
}
