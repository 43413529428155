@import "../master";

p.questionAnswer {
	font-size: 13px;
	letter-spacing: 0.25px;
	line-height: 24px;
	#{$midNightselectors} {
		color: antiquewhite;
	}
}

.sub-heading {
	/* font-style: normal; */
	font-family: TG-Medium;
	text-transform: capitalize;
	letter-spacing: 0.35px;
	/* font-weight: 600; */
	font-size: 15px;
	line-height: 27px;
	color: #37474f;
	#{$midNightselectors} {
		color: $white;
	}

	&.text-normal {
		text-transform: unset;
	}
}

.form-heading {
	font-weight: 600;
	letter-spacing: 0.25px;
	font-size: 14.5px;
	line-height: 1.6;
	color: #37474f;
	#{$midNightselectors} {
		color: #c4c4c4;
	}
}

h6.newbus {
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.02em;
	color: #37474f;
	#{$midNightselectors} {
		color: $white;
	}
}

p.split {
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #37474f;
	font-weight: 100;
	font-family: "TG-Medium";
	opacity: 0.8;
	margin-bottom: 8px;
	#{$midNightselectors} {
		color: $white;
		opacity: 0.75;
	}
}

p.bigMoni {
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 16px;
	line-height: 24px;
	color: $main-fill-normal;
	margin-bottom: 0px;
}

.ground {
	margin-top: 20px;
	color: #37474f;
	h6 {
		font-family: "TG-Medium";
		font-size: 13px;
		text-transform: capitalize;
		line-height: 18px;
		letter-spacing: 0.02em;
	}
	p {
		font-weight: bold;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.5px;
	}
	#{$midNightselectors} {
		color: $white;
	}
}

p.saving-target {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: $main-fill-normal !important;
}

p.wording {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #37474f;
	#{$midNightselectors} {
		color: $white;
	}
}

.tiny__message {
	margin: 2rem 0;
	font-size: 13px;
	font-weight: bold;
	// text-align: center;
	// font-style: italic;
	letter-spacing: 0.25px;
	color: #333333;
	.blue-link {
		font-size: 13px;
	}
	#{$midNightselectors} {
		color: #f6f6f6;
	}
}

.formal {
	font-family: $secondary_font_family;
	margin-bottom: 0;
	&--h3 {
		color: $white;
		font-size: 22px;
	}
	&--h6 {
		font-size: 18px;
		color: $black !important;
		#{$midNightselectors} {
			color: #d3d2d2 !important;
		}
	}
}
