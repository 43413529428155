@import "../../../../../../assets/scss/master";

.mkt-product-details {
	> h1 {
		font-size: 18px;
		font-family: TG-Semibold;
		color: $black;
		margin: 0px;
		#{$midNightselectors} {
			color: $gray;
		}
	}

	&__container {
		width: 100%;
		margin-top: 47px;
		display: flex;
		align-items: center;

		@media (max-width: 949px) {
			flex-direction: column;
			align-items: flex-start;
		}

		.product-details-img {
			height: 460px;
			flex: 1;
			max-width: 425px;

			img {
				height: 100%;
				object-fit: cover;
				width: 100%;
				border-radius: 8px;
			}
		}

		.product-details-content {
			max-width: 426px;
			flex: 1;
			margin-left: 100px;

			@media (max-width: 949px) {
				margin-left: 0px;
				margin-top: 40px;
			}

			> h2 {
				font-size: 24px;
				color: $black;
				font-family: TG-Semibold;
				line-height: 28px;

				#{$midNightselectors} {
					color: $white;
				}

				@media (max-width: 400px) {
					font-size: 20px;
				}
			}

			> h3 {
				font-size: 24px;
				color: $black;
				color: $main-fill-normal;
				font-family: TG-Semibold;
				margin: 0px;
				margin-top: 15px;
				@media (max-width: 400px) {
					font-size: 20px;
				}
			}

			&__footer {
				display: flex;
				margin-top: 24px;

				@media (max-width: 400px) {
					flex-direction: column;
				}

				> button {
					text-transform: capitalize !important;
					height: 43px;
					margin-left: 8px;
					flex: 1;

					@media (max-width: 400px) {
						margin-top: 10px;
						width: 160px !important;
						margin-left: 0px;
					}
				}

				.mkt-product-quantity-selector {
					button {
						width: 43px;
						height: 43px;
						border-radius: 15px 0px 0px 15px;

						svg {
							width: 25px;
							height: 25px;
						}

						&:last-of-type {
							border-radius: 0px 15px 15px 0px;

							svg {
								width: 16px;
								height: 16px;
							}
						}
					}

					> span {
						height: 43px;
						font-size: 18px;
						width: 74px;
					}
				}
			}
		}
	}
}
