@import "../../../../../assets/scss/master";

.spendingTags,
#spendingTags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;

	li {
		float: left;
	}

	.tag {
		display: flex;
		margin: 0 10px 10px 0;
		background: $white;
		padding: 7px 22px;
		position: relative;
		border: 1px solid #e8f1f8;
		border-radius: 8px;
		font-weight: lighter;
		font-family: "TG-Medium";
		font-size: 12px;
		text-align: center;
		color: #37474f;
		text-decoration: none;
		height: 40px;
		align-items: center;
		justify-content: center;

		#{$midNightselectors} {
			color: #d3d4d4;
			background: #282727;
			border-color: transparent;
		}

		&.active {
			background-color: $main-fill-normal !important;
			border-color: $main-fill-normal !important;
			color: $white !important;
		}
	}
}
