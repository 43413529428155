@import "../master";

.dialog-head {
	font-weight: lighter;
	font-size: 16px;
	text-transform: uppercase;
	font-family: TG-Medium;
	line-height: 27px;
	text-align: center;
	color: #37474f;
	margin: 20px 0px;
	#{$modalMidNightselectors} {
		color: #e8f1f8;
	}
}

.dialog-info {
	padding: 20px;
	padding-top: 60px;
	margin: auto;
	background: transparent;
	border: 1px solid #e8f1f8;
	box-sizing: border-box;
	border-radius: 4px;

	label {
		font-weight: 100;
		font-family: "TG-Medium";
		font-size: 12px;
		text-transform: uppercase;
		line-height: 18px;
		letter-spacing: 0.04em;
		color: #69747a;
		#{$modalMidNightselectors} {
			color: #fafafa !important;
		}
	}

	#{$modalMidNightselectors} {
		border-color: #393536;
	}

	.dialog-info-detail {
		font-family: "TG-Medium";
		font-size: 20px;
		// color: #69747a;
		#{$modalMidNightselectors} {
			color: #fff !important;
		}
	}
}

.letter-table label {
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 12px;
	text-transform: uppercase;
	line-height: 18px;
	letter-spacing: 0.04em;
	color: #69747a;
}

.dialog-info p,
.letter-table p {
	font-weight: 100;
	font-family: "TG-Medium";
	font-size: 13px;
	line-height: 21px;
	color: #37474f;
	#{$modalMidNightselectors} {
		color: #c4c4c4 !important;
	}
}

// New Transfer Details Pin
.ntdp-form {
	width: 100%;
	padding-top: 60px;

	.ntdp-info {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #eee;
		padding-bottom: 32px;
		padding-top: 32px;
		#{$modalMidNightselectors} {
			border-color: #333333;
		}

		&:first-of-type {
			padding-top: 0;
		}

		span {
			color: #1b1b1b;
			font-family: TG-Medium;
			#{$modalMidNightselectors} {
				color: #fff !important;
			}
		}
	}

	> button {
		text-transform: capitalize !important;
		height: 50px;
	}
}
