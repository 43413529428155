@import "../../../../../../assets/scss/master";


.mkt-payment-panel {
	background-color: $white;
	border: 1px solid $input-border;
	height: auto;
	border-radius: 8px;
	flex: 1;
	display: flex;
	flex-direction: column;

	#{$midNightselectors} {
		background-color:$black-medium;
		border-color: transparent;
	}

	@media (max-width: 1024px) {
		margin-left: 0px;
		margin-top: 24px;
		max-width: 100%;
	}

	&__header {
		padding: 40px 16px 16px;
		border-bottom: 1px solid #eee;
		#{$midNightselectors} {
			border-color: $black-stroke;
		}

		h2 {
			margin: 0px;
			font-size: 16px;
			color: $black;
			font-family: TG-Semibold;
			#{$midNightselectors} {
				color: $white;
			}
		}
	}

	&__content {
		padding: 32px 36px 21px;
		flex: 1;

		@media (max-width: 500px) {
			padding: 20px 16px 21px;
		}

		.mkt-payment-form {
			max-width: 534px;
			margin: 0 auto;

			label {
				color: $black;
				font-family: TG-Light;
				font-size: 14px;

				#{$midNightselectors} {
					color: $white;
				}
			}

			.form-control {
				height: 50px;
				font-size: 14px;
			}

			.form-group {
				margin-bottom: 0px;
			}

			.mkt-payment-input {
				height: 70px;
				font-size: 14px;
				margin-bottom: 0px;

				#{$midNightselectors} {
					background-color: $dark-input !important;
					color: $white !important;
					border-color: $border-medium !important;
				}
			}

			.react-select__control {
				height: 70px;
				outline: none;
				border-radius: 15px !important;
				border: 1px solid $main-fill-normal !important;

				#{$midNightselectors} {
					border-color: $dark-input;
				}

				input {
					color: $black !important;

					#{$midNightselectors} {
						color: $white !important;
					}
				}
			}

			.mkt-payment-form-info {
				font-style: italic;
				font-size: 14px;
				margin: 0px;
				margin-top: 12px;
				color: $black !important;
				#{$midNightselectors} {
					color: $white !important;
				}
			}

			.btn-mint-default {
				border-radius: 15px !important;
				height: 50px !important;
			}
		}
	}
}

.mkt-payment-modal{
	background-color: $white;
	border: 0px solid $input-border;
	height: auto;
	border-radius: 8px;
	flex: 1;
	display: flex;
	margin-bottom: 15px;
	flex-direction: column;

	#{$modalMidNightselectors} {
		background-color: $black-medium;
		border-color: transparent;
	}

	@media (max-width: 1024px) {
		margin-left: 0px;
		margin-top: 24px;
		max-width: 100%;
	}
	button {
		text-transform: none !important;
		height: 50px;
		margin-top: 30px;
		border-radius: 15px !important;
	}
	.button-outline {
		margin-right: 17px;
		color:$main-fill-normal !important;
		background-color: transparent !important;
		border: solid 2px $main-fill-normal;;

	}
	>span {
		display:flex;
		align-items: flex-start;
		flex-direction: row;
		>.sub-text {
				flex: 1;
				font-size: 13px;
				padding-left: 39px;
				font-weight: 400;
				padding-top: 20px;
				color: $black;
				font-family: TG-Light;
		
				#{$modalMidNightselectors} {
					color: #bbbbbb;
				}
			}
			>span{
				cursor: pointer;
				padding-right: 39px;
				align-items: flex-start;
				display: flex;
				flex-direction: row;
				.icon{
					padding-top: 17px; 
					padding-right: 4px;
				}
				.small-text{
						font-size: 12px;
						font-weight: 500;
						color: $black;
						font-family: TG-Light;
					
						#{$modalMidNightselectors} {
							color: $main-fill-normal;
						}
				}
			}
	}

	&__header {
		padding: 40px 16px 16px;
		border-bottom: 1px solid #eee;

		#{$modalMidNightselectors} {
			border-color: $black-stroke;
		}

		h2 {
			margin: 0px;
			font-size: 16px;
			color: $black;
			font-family: TG-Semibold;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}
	
	}

	&__content {
		padding: 32px 36px 21px;
		flex: 1;

		@media (max-width: 500px) {
			padding: 20px 16px 21px;
		}
		.mkt-payment-item {
			width: 100%;
			min-height: 93px;
			border: 1px solid $input-border;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px 16px 27px;
			margin-bottom: 24px;
			cursor: pointer;

			#{$midNightselectors} {
				background-color: #1a1919;
				border-color: $border-medium;
			}

			&:last-of-type {
				margin-bottom: 0px;
			}

			&:hover,
			&.selected {
				border-color: $main-fill-normal !important;
			}

			&__content {
				display: flex;
				flex: 1;
				margin-top: 27px;
				margin-left: 10px;
				justify-content: space-between;

				>div {
					&:first-child {
						display: flex;
						flex-direction: column;

						h4 {
							font-size: 16px;
							font-family: TG-Bold;
							margin: 0px;
							margin-bottom: 16px;
							color: $black;

							#{$modalMidNightselectors} {
								color: $white;
							}
						}

						p {
							font-size: 16px;
							color: $black;
							margin-bottom: 12px;

							#{$modalMidNightselectors} {
								color: $white;
							}

							&:last-of-type {
								font-size: 12px;
								max-width: 216px;
								margin-bottom: 0px;
								margin-top: auto;
							}
						}
					}

					&:last-child {
						display: flex;
						flex-direction: column;
					}
				}
			}
		}
		.confirmed-order {
			.filled-button {
				text-transform: none !important;
				display: flex !important;
				align-items: center;
				justify-content: center;
				border-radius: 15px !important;
				height: 50px;
				line-height: 0 !important;
			}
			.outline-button {
				text-transform: none !important;
				display: flex !important;
				align-items: center;
				background-color: $white;
				color:$main-fill-normal;
				justify-content: center;
				border-radius: 15px !important;
				height: 50px;
				line-height: 0 !important;
				border: $main-fill-normal solid 1px;

				#{$modalMidNightselectors} {
						background-color: $black-medium;
					}
			}
			&__content {
					display: flex;
					flex-direction: column;
					flex: 1;
					margin-top: 15px;
					margin-left: 10px;
					justify-content:space-evenly;
					
					>div {
						display: flex;
						flex-direction: column;
						max-width: 390px;
						
						h4 {
								font-size: 18px;
								font-family: TG-Bold;
								margin: 0px;
								margin-bottom: 16px;
								color: $black;
								margin-top: 15px;
								font-weight: 500;
								text-align: center;
						
								#{$modalMidNightselectors} {
									color: $white;
								}
							}
						
							p {
								font-size: 14px;
								color: $black;
								margin-bottom: 50px;
								text-align: center;
								font-weight: 400;
						
								#{$modalMidNightselectors} {
									color: $white;
								}
							}
					}
				}
		}

		.pay-upfront {
			&__content {
				display: flex;
				flex:1;
				flex-direction: column;
				flex: 1;
				margin-top: 15px;
				>div {
					display: flex;
					flex-direction: column;
					justify-content: center;

					h4 {
						font-size: 16px;
						font-family: TG-light;
						margin: 0px;
						margin-bottom: 16px;
						color: $black;
						margin-top: 15px;
						font-weight: 600;
						text-align: center;

						#{$modalMidNightselectors} {
							color: $white;
						}
					}

					p {
						display: flex;
						font-size: 14px;
						color: $black;
						margin: 0px auto;
						margin-bottom: 50px;
						text-align: center;
						font-weight: 400;
						max-width: 375px;

						#{$modalMidNightselectors} {
							color: $white;
						}
					}
					
				}
				.summary {
					height: auto;
					display: flex;
					flex-direction: column;
					padding: 20px 20px;
					background-color: $white;
					border: 1px solid $input-border;
					border-radius: 8px;

					#{$modalMidNightselectors} {
						background-color: #282727;
						border-color: transparent;
					}

					.div-container {
						justify-content: space-between;
						margin-bottom: 7px;
						.title {
							color: $black;
							font-size: 12px;
							font-family: TG-Light;

							#{$modalMidNightselectors} {
								color: $white;
							}
						}

						.amount {
							color: $black;
							font-size: 14px;
							font-family: TG-Light;
							font-weight: 500;
							#{$modalMidNightselectors} {
								color: $white;
							}
						}

					}

				.amount-to-pay {
					justify-content: space-between;
					border: 1px solid $input-border;
					border-style: dashed;
					padding: 20px 10px;

					border-radius: 8px;
					#{$modalMidNightselectors} {
						border: 1px solid $white ;
						border-style: dashed;
					}

					.title {
						color: $black;
						font-size: 12px;
						font-family: TG-Light;
						font-weight: 600;

						#{$modalMidNightselectors} {
							color: $white;
						}
					}

					.amount {
						color: $black;
						font-size: 14px;
						font-family: TG-Bold;
						font-weight: 700;

						#{$modalMidNightselectors} {
							color: $white;
						}
					}
				}

				}
			}
		}

		.clear-balance {
			&__content {
				display: flex;
				flex: 1;
				flex-direction: column;
				flex: 1;
				margin-top: 15px;
				

				.summary {
					height: auto;
					display: flex;
					padding: 30px 25px;
					flex-direction: column;
					background-color: $white;
					border: 1px solid $main-fill-normal;
					border-radius: 8px;

					#{$modalMidNightselectors} {
						background: #1A1812;
						border-color: $main-fill-normal;
					}

					.div-container {
						justify-content: space-between;
						margin-bottom: 20px;
						.title {
								color: $black;
								font-size: 13px;
								font-family: TG-Light;
						
								#{$modalMidNightselectors} {
									color: $white;
								}
							}
						.amount {
							color: $black;
							font-size: 14px;
							font-family: TG-Light;
							font-weight: 500;

							#{$modalMidNightselectors} {
								color: $white;
							}
						}

					}

					.amount-to-pay {
						justify-content: space-between;

						.title {
							color: $black;
							font-size: 12px;
							font-family: TG-Light;
							font-weight: 600;

							#{$modalMidNightselectors} {
								color: $white;
							}
						}

						.amount {
							color: $black;
							font-size: 14px;
							font-family: TG-Bold;
							font-weight: 700;

							#{$modalMidNightselectors} {
								color: $white;
							}
						}
					}

				}
			}
		}
	}

	.mkt-payment-modal-form {
		overflow-y: auto;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 10px 20px 21px;

		label {
			color: $black;
			font-family: TG-Light;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #bbbbbb;
			}
		}

		.form-control {
			height: 50px;
			font-size: 14px;
		}

		.form-group {
			margin-bottom: 0px;
		}

		.mkt-pay-later-modal-input {
			height: 50px;
			font-size: 14px;
			margin-bottom: 0px;

			#{$modalMidNightselectors} {
				background-color: $dark-input !important;
				color: $white !important;
				border-color: $dark-input !important;
			}
		}

		.react-select__control {
			height: 50px;
			outline: none;
			border-radius: 15px !important;

			#{$modalMidNightselectors} {
				border-color: $dark-input;
			}

			input {
				color: $black !important;

				#{$modalMidNightselectors} {
					color: $white !important;
				}
			}
		}

	}

	.mkt-BNPL-account {
		overflow-y: auto;
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 10px 36px 21px;

		span {
			color: $black;
			font-family: TG-Light;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #bbbbbb;
			}
		}
			.input {
				height: 50px;
				font-size: 14px;
				margin-bottom: 20px;
				border-radius: 15px;
				flex-direction: column;
				display: flex;
				justify-content: center;
				padding-left: 20px;
				border: 1px solid;
				margin-top: 6px;
				border-color: #bbbbbb !important;

				#{$modalMidNightselectors} {
					background-color: $dark-input !important;
					color: $white !important;
					border-color: $dark-input !important;
				}
			}

	}
}