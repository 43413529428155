@import "../../../assets/scss/master";

#content {
	width: calc(100% - 250px);
	background: #f5f6f7;
	border-top: 5px solid #8c6433;
	padding: 0px;
	min-height: 100vh;
	transition: all 0.3s;
	position: absolute;
	top: 0;
	right: 0;
	#{$midNightselectors} {
		background: $black;
	}
	&.active {
		width: 100%;
	}
}

@media (max-width: 768px) {
	#content {
		width: 100%;
	}
}
