@import "../../../../assets/scss/master";

.market-place {
	padding-top: 50px;
	/* Global scroll bar style */
		::-webkit-scrollbar {
			width: 0.4em;
		}
	
		::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
		}
	
		::-webkit-scrollbar-thumb {
			border-radius: 100px;
			background-color: gainsboro;
			outline: 0 solid gainsboro;
		}

	@media (max-width: 400px) {
		padding-top: 32px;
	}

	.mkt-back-button {
		margin-bottom: 40px;
	}

	.mkt-home-wrapper {

		@media (max-width: 991px) {
			flex-direction: column !important;
		}
		.category-title {
			margin-bottom: 30px;

			>h2{
				margin: 0;
				padding: 0;
				margin-bottom: 5px;
				font-family: TG-Semibold;
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;

				#{$midNightselectors} {
						color: $white;
				}
			}
			>p{
				margin: 0;
				padding: 0;
				font-family: TG-Light;
				font-weight: 400;
				font-size: 12px;
			
				line-height: 24px;
				 #{$midNightselectors} {
					color: $white;
				}
			}
		}
		.mkt-home-products {
			z-index: 10;
			@media (max-width: 991px) {
				margin-left: 0px !important;
				padding-top: -20px;
			}
		}
	}

	.left-side-menu {
		width: 240px;
		background-color: $white;
		border-radius: 8px;
		flex-shrink: 0;
		padding: 35px 0px;
		border: 1px solid $input-border;
		height: auto;
		max-height: 869px;
		overflow-y: auto;
		align-self: flex-start;
		position: sticky;
		top: 0;
		left: 0;
		z-index: 15;
		#{$midNightselectors} {
			color: $white;
			background-color: $black-medium;
			border-color: transparent;
		}

		@media (max-width: 991px) {
			margin-bottom: 16px;
			width: 100%;
			padding: 0px;
			max-height: 200px;
		}

		.mkt-categories-list {
			padding: 0px 17px !important;
			@media (max-width: 991px) {
				display: flex !important;
				flex-wrap: wrap;
				align-items: center;
			}

			li {
				color: $gray;
				font-size: 13px !important;
				list-style-type: none;
				margin-top: 25px;

				@media (max-width: 991px) {
					margin-right: 13px;
					margin-top: 16px;
				}

				> .mkt-side-category {
					padding: 0;
					margin: 0;
					color: rgb(145, 143, 143);
					display: flex;
					align-items: center;
					width: fit-content;
					#{$midNightselectors} {
						color: $gray;
					}

					.mkt-side-text {
						line-height: 0px;
						margin-left: 13px;
					}
				}

				> .mkt-side-category:hover,
				.selected {
					color: $black;
					#{$midNightselectors} {
						color: $white;
					}

					svg {
						path {
							fill: $black;
							#{$midNightselectors} {
								fill: $white;
							}
						}
					}

					.mkt-side-text {
						text-decoration: underline;
					}
				}
			}
		}

		.category-title {
			display: flex;
			align-items: center;
			margin-bottom: 0px !important;

			@media (max-width: 991px) {
				margin-right: 13px;
				margin-top: 16px;
			}

			svg {
				path {
					fill: $black;
					#{$midNightselectors} {
						fill: $white;
					}
				}
			}

			> span {
				margin-left: 16px;
				font-size: 15px;
				font-family: TG-Semibold;
				line-height: 0px;
			}
		}
	}

	.topNav {
		position: relative;
		@media (max-width: 991px) {
			flex-direction: column !important;
			position: relative;
		}

		.mkt-search-wrapper {
			@media (max-width: 400px) {
				flex-direction: column !important;
			}

			.custom-search-input {
				flex: 1;

				input {
					border-radius: 4px !important;
				}
			}
		}
			.suggestions {
				display: flex;
				flex-direction: column;
				position: absolute;
				flex: 1;
				max-width: 505px;
				top: 50px;
				border-radius:  5px;
				box-shadow: #111111;
				z-index: 50;
				padding:0px;
				background-color: $white;
				margin-top: 0;
				padding: 10px 20px ;
				display: flex;
				border: 1px solid $input-border;

				#{$midNightselectors} {
					background-color: #111111;
					border: 1px solid  $black-stroke;
				}

			@media (max-width: 400px) {
				flex:1;
				flex-direction: column !important;
			}
			>li{
				list-style: none;
				margin: 0;
				margin-bottom: 6px;
				font-size: 16px;
				color: $black;
				font-weight: 500;
				cursor: pointer;
				font-family: TG-Light;
				#{$midNightselectors} {
					color: $white
				}
			}
			>h6{
				
				text-align: center;
				font-size: 16px;
				color: $black;
				font-weight: 700;
				font-family: TG-Light;
			
				#{$midNightselectors} {
					color: $white;
				}
			}
			>button{
				border: 0px;
				background-color: transparent;
				font-size: 16px;
				color: $black;
				font-family: TG-Semibold;
				#{$midNightselectors} {
					color:$white
				}
			}
			}

		.search-btn {
			font-size: 14px;
			text-transform: capitalize !important;
			margin-left: 10px;
			margin-top: 0;
			font-weight: 500;
			width: 91px;
			height: 45px;

			@media (max-width: 400px) {
				margin-left: 0px;
				margin-top: 10px;
			}
		}

		.icon-container {
			@media (max-width: 991px) {
				margin-top: 16px;
			}

			> .mkt-top-nav-icon-container {
				width: fit-content;
				height: fit-content;
				display: flex;
				position: relative;
				margin-left: 15px;

				@media (max-width: 991px) {
					&:first-child {
						margin-left: 0px;
					}
				}

				.mkt-top-nav-icon {
					width: fit-content;
					height: fit-content;

					svg {
						circle {
							fill: #faf5eb;
							#{$midNightselectors} {
								fill: #2c271c;
							}
						}
					}
				}

				> span {
					position: absolute;
					top: 0;
					right: -5px;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 18px;
					height: 18px;
					background-color: $black;
					color: $white;
					font-size: 12px;
					border-radius: 50%;
					#{$midNightselectors} {
						background-color: $white;
						color: $black;
					}
				}
			}
		}
	}

	.mkt-products {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
		gap: 12px;
		margin-top: 23px;
	}

	.mkt-popular-items {
		
		>.mkt-inner-items {
			margin-top: 28px !important;
		}

		h3 {
			margin-bottom: 0px;
			font-family: TG-Semibold;
			font-size: 16px;
			color: $black;
			#{$midNightselectors} {
				color: $gray;
			}
		}
		.products {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
			gap: 12px;
			margin-top: 23px;
		}
	}

	.mkt-loading {
		width: 100%;
		> div {
			width: 100% !important;
		}

		.row {
			.col-md-5 {
				flex: 1;
				max-width: 100%;
			}
		}
	}

	.mkt-main-loader {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
