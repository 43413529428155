@import "../../../../assets/scss/master";

.top-up-panel {
	background: $white;
	border-radius: 8px;
	padding: 40px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: 1px solid #eee;
	min-height: 467px;
	width: 100%;
	#{$midNightselectors} {
		background: $black-medium;
		border: 1px solid #212121;
	}

	&__header {
		color: $black;
		font-size: 14px;
		#{$midNightselectors} {
			color: $white;
		}
	}

	&__logos {
		display: grid;
		margin-top: 0.5rem;
		grid-template-columns: repeat(4, 1fr);
		gap: 32px;

		@media (max-width: 767px) {
			gap: 16px;
		}

		@media (max-width: 550px) {
			grid-template-columns: repeat(2, 1fr);
		}

		.top-up-panel-logo {
			border: 1px solid #eee;
			border-radius: 15px;
			padding: 17px 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			// margin-right: 32px;
			#{$midNightselectors} {
				border: 1px solid #333333;
				background-color: $black;
			}

			&:last-of-type {
				margin-right: 0px;
			}

			&.selected {
				border: 1px solid var(--main-fill-normal) !important;
			}
		}
	}

	.top-up-form {
		min-width: 488px;
		@media (max-width: 767px) {
			min-width: 270px;
		}

		@media (max-width: 320px) {
			min-width: 100%;
		}

		label {
			color: $black;
			font-family: TG-Light;
			font-size: 14px;

			#{$midNightselectors} {
				color: $white;
			}
		}

		.form-control {
			height: 50px;
			font-size: 14px;
		}

		.form-group {
			margin-bottom: 0px;
		}

		.topup-input {
			height: 50px;
			font-size: 14px;
			margin-bottom: 0px;

			#{$midNightselectors} {
				background-color: $dark-input !important;
				color: $white !important;
				border-color: #333333 !important;
			}

			&.has-error {
				border-color: #a94442 !important;
			}

			&.disabled {
				cursor: not-allowed;
			}
		}

		.react-select__control {
			height: 50px;
			outline: none;
			border-radius: 15px !important;

			#{$midNightselectors} {
				border-color: #333333;
			}
		}

		.topup-global-amount-info {
			margin: 0;
			margin-top: 7px;
			font-size: 12px;
			color: $black;
			#{$midNightselectors} {
				color: $white;
			}

			> span {
				color: var(--main-fill-normal);
				font-family: TG-Medium;
			}
		}

		.intl-tel-input {
			display: block !important;
			overflow: unset !important;

			.selected-flag {
				border-top-left-radius: 15px !important;
				border-bottom-left-radius: 15px !important;
			}
		}
	}
}
