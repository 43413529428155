@import "../../../assets/scss/master";

.custom-currency-input {
	> label {
		color: #000;
		font-size: 14px;

		#{$midNightselectors} {
			color: #fff;
		}

		#{$modalMidNightselectors} {
			color: #fff;
		}

		&.other-bank-label {
			#{$midNightselectors} {
				color: #bbbbbb;
			}

			#{$modalMidNightselectors} {
				color: #bbbbbb;
			}
		}
	}

	.custom-currency-input-container {
		position: relative;

		.custom-currency-container {
			position: absolute;
			color: $black !important;
			left: 12px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			font-family: TG-Light;
			#{$midNightselectors} {
				color: $white !important;
			}

			#{$modalMidNightselectors} {
				color: $white !important;
			}
		}

		input {
			font-size: 14px !important;
			margin-bottom: 0px !important;
			height: 50px;
			transition: none !important;

			&::placeholder {
				#{$midNightselectors} {
					color: var(--page-lead-text);
					font-family: TG-Light;
				}

				#{$modalMidNightselectors} {
					color: var(--page-lead-text);
					font-family: TG-Light;
				}
			}

			#{$midNightselectors} {
				color: #fff !important;
				background-color: $dark-input !important;
				border-color: #333333;
			}

			#{$modalMidNightselectors} {
				color: #fff !important;
				background-color: $dark-input !important;
				border-color: #333333;
			}

			&.has-error {
				border-color: #a94442 !important;
			}
		}
	}
}
