$midNightselectors: " .mintBank.midnight & ";
$modalMidNightselectors: " .midnight & ";
$main-fill-normal: #d1a23c;
$gray-fill-normal: #37474f;
$secondary_font_family: "DM Serif Display";
$border-radius: 15px;
$black: #000000;
$black-medium: #111111;
$black-stroke: #212121;
$gray: #bbbbbb;
$dark-input: #282727;
$white: #ffffff;
$border-medium: #333333;
$black-light: #1a1919;
$debit: #ff466a;
$credit: #4abe86;
$input-border: #ced4da;
$secondary-gray: #808080;
