@import "../../../../../../../assets/scss/master";

.create-savings-goal-page {
	.saving-goal-groom {
		border-radius: 8px;
		border: 1px solid #eee;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 45px 16px;

		#{$midNightselectors} {
			border-color: $black-stroke;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 659px;
			margin: 0 auto 32px;
			width: 100%;

			&.shrink {
				max-width: 488px;
			}

			> .step-count {
				color: $main-fill-normal;
				font-size: 12px;
				font-family: TG-Semibold;
			}
		}
	}

	&__step {
		width: 100%;
		max-width: 659px;
		margin: 0 auto;

		&.shrink {
			max-width: 488px;
		}

		.saving-goal-loading {
			min-height: 400px;
			margin-top: 33px;
		}

		> h2 {
			font-size: 16px;
			font-family: TG-Semibold;
			color: $black;
			#{$midNightselectors} {
				color: $white;
			}
		}

		> p {
			font-size: 15px;
			margin: 0px;
			margin-top: 8px;
			color: $black;
			#{$midNightselectors} {
				color: $gray;
			}
		}

		.cesp-grid {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(183px, 1fr));
			gap: 64px 51px;
			margin-top: 56px;

			&__card {
				cursor: pointer;
				.icon-containa {
					border-radius: 16px;
					background-color: $white;
					width: 100%;
					height: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 13px 10px;
					border: 1px solid $gray;
					#{$midNightselectors} {
						background-color: $dark-input;
						border-color: transparent;
					}

					&.selected,
					&:hover {
						border-color: $main-fill-normal !important;
					}

					svg {
						circle,
						ellipse {
							fill: #faf5eb;
							#{$midNightselectors} {
								fill: #393536;
							}
						}
					}
				}

				> h3 {
					font-size: 16px;
					font-family: TG-Medium;
					color: $black;
					margin-top: 32px;
					text-align: center;
					#{$midNightselectors} {
						color: $white;
					}
				}
			}
		}

		form {
			margin-top: 32px;
			width: 100%;

			label,
			.custom-control-label {
				color: $black !important;
				font-family: TG-Light;
				font-size: 14px;

				#{$midNightselectors} {
					color: $white !important;
				}
			}

			.form-control {
				height: 50px;
				font-size: 14px;
			}

			.form-group {
				margin-bottom: 0px;
			}

			.cesp-input {
				height: 50px;
				font-size: 14px;
				margin-bottom: 0px;

				#{$midNightselectors} {
					background-color: $dark-input !important;
					color: $white !important;
					border-color: $border-medium !important;
				}

				&.has-error {
					border-color: #a94442 !important;
				}

				&.disabled {
					cursor: not-allowed;
				}
			}

			.auto-debit-panel {
				width: 45%;
				height: 50px;
				border-radius: $border-radius;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $white;
				border: 3px solid $gray;
				color: $black;
				font-size: 14px;
				font-family: TG-Medium;
				#{$midNightselectors} {
					background-color: $dark-input !important;
					color: $gray !important;
					border-color: transparent;
				}

				&.selected,
				&:hover {
					border-color: $main-fill-normal !important;
					#{$midNightselectors} {
						color: $white !important;
					}
				}
			}

			.cesp-form-info {
				color: $black;
				font-size: 13px;
				max-width: 488px;
				margin-top: 10px;
				#{$midNightselectors} {
					color: $gray !important;
				}
			}

			.cesp-frequency {
				display: flex;
				width: 100%;
				justify-content: space-between;

				@media (max-width: 899px) {
					flex-wrap: wrap;
				}

				> * {
					flex: 1;

					&:last-child {
						margin-right: 0px;
						margin-bottom: 0px;
					}

					@media (max-width: 400px) {
						flex: auto;
						margin-right: 0px;
					}

					@media (min-width: 900px) {
						margin-bottom: 0px;
					}
				}
			}

			.react-datepicker__input-container {
				> input {
					height: 50px;
					font-size: 14px;
					margin-bottom: 0px;

					#{$midNightselectors} {
						background-color: $dark-input !important;
						color: $white !important;
						border-color: $border-medium !important;
					}

					&.disabled {
						cursor: not-allowed;
					}
				}

				.datepicker--has-error {
					border-color: #a94442 !important;
					#{$midNightselectors} {
						border-color: #a94442 !important;
					}
				}
			}

			.maturity-period-grid {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(109px, 1fr));
				gap: 16px 38px;

				> * {
					&:last-child {
						width: 100%;
						grid-column: span 2;

						> * {
							width: 100%;
						}
					}
				}

				> .auto-debit-panel {
					width: unset;
				}
			}

			.maturity-period-details {
				margin-top: 43px;
				padding-top: 24px;
				border-top: 1px solid #eee;
				#{$midNightselectors} {
					border-color: $border-medium;
				}

				&__item {
					display: flex;
					justify-content: space-between;
					margin-bottom: 24px;

					> span {
						display: block;
						width: fit-content;
						color: $black;
						font-size: 16px;
						#{$midNightselectors} {
							color: #c4c4c4;
						}

						&:last-child {
							color: $black;
							#{$midNightselectors} {
								color: $white;
							}
						}
					}
				}
			}

			.lock-goal {
				display: flex;
				justify-content: space-between;
				margin-top: 4px;

				&__label {
					color: $black;
					display: flex;
					align-items: center;
					line-height: 0;
					#{$midNightselectors} {
						color: #69747a;
					}
				}

				> .lock-switch {
					height: 28px;
					width: 48px;
					.slider {
						&::before {
							width: 24px;
							height: 24px;
							top: 50%;
							left: 2px;
							transform: translateY(-50%);
							box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.251475),
								0px 2px 6px rgba(0, 0, 0, 0.404256);
						}
					}

					input {
						&:checked + .slider:before {
							left: unset;
							right: 2px;
						}
					}
				}
			}
		}
	}

	&__summary {
		margin-top: 40px;

		h3 {
			color: $credit;
			font-size: 24px;
			font-family: TG-Semibold;
			margin: 0px;
		}

		> div {
			&:first-child {
				padding-bottom: 24px;
				border-bottom: 1px solid #eee;
				#{$midNightselectors} {
					border-color: $border-medium;
				}
			}
		}

		.cesp-summary-info {
			display: grid;
			// justify-content: space-between;
			margin-top: 24px;
			margin-bottom: 48px;
			grid-template-columns: repeat(2, 1fr);
			width: 100%;
			gap: 24px;

			> div {
				&:nth-child(even) {
					text-align: right;
				}
			}

			@media (max-width: 500px) {
				grid-template-columns: repeat(1, 1fr);

				> div {
					text-align: left !important;
				}
			}

			h4 {
				color: $black;
				font-size: 16px;
				margin: 0px;
				margin-bottom: 24px;
				#{$midNightselectors} {
					color: #c4c4c4;
				}
			}

			h5 {
				color: $black;
				font-size: 20px;
				margin: 0px;
				#{$midNightselectors} {
					color: $white;
				}
			}
		}

		.custom-checkbox {
			label {
				margin: 0px;
				color: $black !important;
				display: block;
				#{$midNightselectors} {
					color: #69747a !important;
				}
			}
		}
	}
}
