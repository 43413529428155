@import "../master";

.modal-header{
    border-bottom: 0px;
}

.sub__section {
    text-align: center;

    h6 {
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        line-height: normal;
        font-family: "TG-Medium";
        #{$modalMidNightselectors}{
            color: #E8F1F8;
        }
    }

    p {
        font-size: 13px;
        letter-spacing: 0.25px;
        color: $gray-fill-normal;
    }

}
