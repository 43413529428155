@import "../../../../../../../assets/scss/master";

.smile {
    text-align: center;
    color: #37474f;

    #{$modalMidNightselectors}{
        color: #E8F1F8;
    }

    h4 {
        color: rgba(68, 84, 92, 0.9);
        font-size: 16px;
        font-family: "TG-Medium";
        font-weight: 100;
        letter-spacing: 0.35px;
    }

    h6 {
        font-family: "TG-Medium";
        font-weight: 100;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.02em;
    }

    p {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.25px;
    }
}
