@import "../../../../assets/scss/master";

.pending-approvals {
	> h1 {
		font-size: 18px;
		color: $black;
		font-family: TG-Medium;

		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}

	> p {
		max-width: 472px;
		color: $black;
		font-size: 12px;
		line-height: 20px;

		#{$midNightselectors} {
			color: var(--gray-fill-light);
		}
	}

	.pta-filters {
		margin-top: 32px;

		button {
			width: 91px !important;
			height: 45px !important;
			font-size: 14px !important;
			text-transform: capitalize;
			font-family: TG-Light;
			border-radius: 8px;
		}

		.pta-search {
			display: flex;
			align-items: center;

			@media (max-width: 450px) {
				flex-direction: column;
				align-items: flex-start;
			}

			.pta-search-wrapper {
				flex: 1;
				margin-right: 12px;

				@media (max-width: 450px) {
					width: 100%;
					margin-right: 0px;
					margin-bottom: 12px;
				}
			}
		}

		.pta-filter-form {
			display: flex;
			margin-top: 32px;
			flex-wrap: wrap;

			@media (max-width: 499px) {
				flex-direction: column;
			}

			.react-select__control {
				height: 45px;
				width: 180px;
				outline: none;
				border-radius: 8px !important;
				margin-right: 12px;
				font-size: 13px;

				#{$midNightselectors} {
					background-color: $black-medium !important;
					border-color: $border-medium;
				}

				@media (max-width: 499px) {
					margin-right: 0px;
					margin-bottom: 12px;
				}
			}

			.react-datepicker__input-container {
				height: 45px !important;
				width: 177px;
				margin-right: 12px;
				margin-bottom: 0px !important;

				@media (max-width: 499px) {
					margin-right: 0px;
				}

				> input {
					border-radius: 8px !important;
					height: 100% !important;
					font-size: 13px;
					#{$midNightselectors} {
						background-color: $black-medium !important;
						border-color: $border-medium;
					}

					&::placeholder {
						font-size: 13px;
						#{$midNightselectors} {
							color: var(--page-lead-text);
							font-family: TG-Light;
						}
					}
				}
			}

			.pta-filter-apply {
				margin-right: 12px;
				@media (max-width: 499px) {
					margin-right: 0px;
					margin-bottom: 12px;
				}
			}
		}
	}

	.pta-loading {
		margin-top: 32px;
		min-height: 300px;
	}

	.table-responsive {
		border: 1px solid #ced4da !important;
		border-radius: 8px;
		margin-top: 24px;
		#{$midNightselectors} {
			border: 1px solid $black-stroke !important;
			background-color: $black-medium;
		}

		.pta-table {
			thead {
				border-bottom: 1px solid #ced4da;
				#{$midNightselectors} {
					border-bottom: 1px solid $border-medium;
				}
			}

			th,
			td {
				border: none !important;
				font-size: 12px;
				white-space: nowrap;

				#{$midNightselectors} {
					color: $white;
				}
			}

			th {
				padding-top: 32px;
				padding-bottom: 24px;

				&:first-child {
					padding-left: 34px;
				}

				&:last-child {
					padding-right: 34px;
				}
			}

			td {
				padding-top: 32px;
				padding-bottom: 24px;
				vertical-align: middle;

				&:first-child {
					padding-left: 34px;
				}

				&:last-child {
					padding-right: 34px;
				}

				.pta-badge {
					border-radius: 100px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-transform: capitalize;
					padding: 4px 11px;
					text-align: center;
					color: $white;
					font-family: TG-Medium;

					&.pta-transfer {
						background-color: rgba(117, 100, 251, 1);
						#{$midNightselectors} {
							background-color: rgba(117, 100, 251, 0.22);
						}
					}

					&.pta-bills {
						background-color: rgba(9, 191, 103, 1);
						#{$midNightselectors} {
							background-color: rgba(9, 191, 103, 0.22);
						}
					}
				}

				.pta-amount {
					font-family: TG-Semibold;
					color: var(--main-fill-normal);
				}

				.pta-details-btn {
					background-color: transparent;
					padding: 0px !important;
					display: flex;
					align-items: center;
					justify-content: center;
					outline: none !important;
					border: none !important;
					color: #3e62be;
					font-size: 12px;
					height: fit-content !important;
					text-decoration: underline;
				}
			}
		}
	}

	> .customPagination {
		ul {
			width: fit-content;
			margin-left: auto;

			li {
				a {
					border-radius: 8px !important;
					border: 1px solid #eee;
					font-size: 13px;
					font-family: TG-Light;
					#{$midNightselectors} {
						border-color: $border-medium !important;
					}
				}
			}
		}
	}
}
