$primary: #111827;
$white: #fff;
$black: #000;
$green: #22c55e;
$red: #ff466a;
$error: #ee4b2b;
$purple: #7564fb;
$orange: #f78a31;
$border: #e0e0e0;
$disabled: #eee;
