@import "../../../../../../assets/scss/master";

.mkt-cart-summary {
	background-color: $white;
	border: 1px solid $input-border;
	height: 547px;
	// flex-basis: 40%;
	max-width: 402px;
	border-radius: 8px;
	margin-left: 24px;
	flex: 1;
	display: flex;
	flex-direction: column;

	#{$midNightselectors} {
		background-color: $black-medium;
		border-color: transparent;
	}

	@media (max-width: 1024px) {
		margin-left: 0px;
		margin-top: 24px;
		max-width: 100%;
	}

	&__header {
		padding: 40px 10px 16px;
		border-bottom: 1px solid #eee;
		#{$midNightselectors} {
			border-color: $black-stroke;
		}

		h2 {
			margin: 0px;
			font-size: 16px;
			color: $black;
			font-family: TG-Semibold;
			#{$midNightselectors} {
				color: $white;
			}
		}
	}

	&__content {
		padding: 20px 18px 21px;
		display: flex;
		flex-direction: column;
		flex: 1;

		> button {
			margin-top: auto;
			border-radius: 15px !important;
			height: 50px;
			text-transform: none !important;
		}

		> div {
			border: 1px solid $main-fill-normal;
			background-color: rgb(247, 244, 244);
			border-radius: 8px;
			padding: 38px 15px;

			#{$midNightselectors} {
				background-color: #1a1812;
			}

			.mkt-cart-summary-info {
				display: flex;
				justify-content: space-between;
				margin-bottom: 25px;

				&:last-child {
					margin-bottom: 0px;

					> span {
						font-family: TG-Bold;
						font-size: 14px;
						#{$midNightselectors} {
							color: $white;
						}

						&:last-child {
							font-size: 16px;
						}
					}
				}

				> span {
					color: $black;
					font-size: 13px;
					#{$midNightselectors} {
						color: #c4c4c4;
					}

					&:last-child {
						font-family: TG-Medium;
						font-size: 15px;
						text-align: right;
						#{$midNightselectors} {
							color: $white;
						}
					}
				}
			}
		}
	}
}
