@import "../../../../../../assets/scss/master";

.accountSetting {
	margin: 20px 0;
	.form-row {
		margin-bottom: 15px;
	}
	.label--heading {
		font-size: 12px;
		letter-spacing: 0.25px;
		// line-height: 18px;
		color: #69747a;
		margin-bottom: 10px;
		text-transform: capitalize;
		#{$midNightselectors} {
			color: #d3d2d2;
		}
	}

	.label--text {
		line-height: 18px !important;
		color: #37474f !important;
		letter-spacing: 0.25px;
		font-weight: 900;
		font-size: 13px !important;
		#{$midNightselectors} {
			color: #d3d2d2 !important;
		}
	}
}

.accordion__btn--link {
	display: flex !important;
	letter-spacing: 0.25px;
	line-height: 18px !important;
	align-items: center;
	padding-left: 0px !important;
	font-size: 13px !important;
	color: #37474f;
	font-weight: 900;
	width: 100%;
	text-align: left;
	text-decoration: none;
	cursor: pointer;

	&:after {
		/* background: #0D067C; */
		background: #69747a;
		content: "";
		width: 12px;
		height: 7.42px;
		-webkit-mask-image: url(../../../../../../assets/img/sue.svg);
		mask-image: url(../../../../../../assets/img/sue.svg);
		display: block;
		margin-left: auto;
		transform: rotate(90deg);
		-moz-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
		#{$midNightselectors} {
			background: $white;
		}
	}

	#{$midNightselectors} {
		color: $white;
	}
}

.bigback__btn--link {
	display: flex !important;
	letter-spacing: 0.25px;
	line-height: 18px !important;
	align-items: center;
	padding-left: 0px !important;
	font-size: 13px !important;
	color: #37474f !important;
	font-weight: 900;
	width: 100%;
	text-align: left;
	text-decoration: none;
	cursor: pointer;

	&::before {
		/* background: #0D067C; */
		background: #69747a;
		content: "";
		width: 12px;
		height: 7.42px;
		-webkit-mask-image: url(../../../../../../assets/img/sue.svg);
		mask-image: url(../../../../../../assets/img/sue.svg);
		display: block;
		margin-left: auto;
		transform: rotate(-90deg);
		-moz-transition: all 0.5s ease;
		-webkit-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}

	&.primary {
		color: $main-fill-normal !important;
		&::before {
			background: $main-fill-normal !important;
		}
	}

	#{$midNightselectors} {
		color: #d3d2d2 !important;
		&::before {
			background: #d3d2d2 !important;
		}

		&.primary {
			color: $main-fill-normal !important;
			&::before {
				background: $main-fill-normal !important;
			}
		}
	}
}
