@import "../../../../../../../assets//scss/master";

.verification__barner {
	background: $white;
	border: 1px solid #eaecf0;
	box-sizing: border-box;
	border-radius: 4px;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	letter-spacing: 0.02em;
	padding: 14px 25px;
	display: flex;
	align-items: center;
	cursor: pointer;

	span {
		margin: 5px 24px 0 15px;
	}

	.caret__right {
		path {
			fill: $black;
		}
	}
	&.danger {
		color: #d03c6a;
		.info {
			path {
				fill: #d03c6a;
			}
		}
	}
	&.warning {
		color: $main-fill-normal;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	#{$modalMidNightselectors} {
		background: $black-medium;
		border: none;

		.caret__right {
			path {
				fill: $white;
			}
		}
	}
}

@media (max-width: 600px) {
	.verification__barner {
		font-size: 12px;
		margin-top: 20px;
	}
}
