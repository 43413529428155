@import "../../../../../../assets/scss/master";

.business-documentation-page {
	.bdp-step-count {
		font-size: 12px;
		color: $black;
		width: fit-content;


		#{$midNightselectors} {
			color: $white;
		}
	}

	> h1 {
		font-size: 18px;
		color: $black;
		font-family: TG-Medium;

		#{$midNightselectors} {
			color: $white;
		}
	}

	> p {
		max-width: 472px;
		color: $black;
		font-size: 12px;
		line-height: 20px;

		#{$midNightselectors} {
			color: $white;
		}
	}
}
