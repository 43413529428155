@import "../../../../assets/scss/master";

#PageNavigator {
	border-bottom: 1px solid #77502f;
	#{$midNightselectors} {
		border-color: $black-medium;
	}
	.nav-link {
		display: block;
		text-align: center;
		text-decoration: none;
		position: relative;
		text-transform: uppercase;
		color: $white;
		letter-spacing: 0.25px;
		font-size: 13px;
		font-family: "TG-Medium";
		font-weight: 100;
		height: 40px;
		line-height: 30px;
		background: #77502f;
		border: 0px;
		border-radius: 0px;
		padding-left: 30px;
		padding-right: 30px;
		#{$midNightselectors} {
			background: #4f4b4c;
		}
		/* background: linear-gradient(165deg, transparent 29%, #98927C 30%); */

		&:hover {
			background: #77502f;
			color: $white;
		}
	}

	a {
		&.active {
			background: #77502f;
		}

		&:before,
		&.active:before {
			content: "";
			position: absolute;
			z-index: 11;
			left: 100%;
			top: 0%;
			height: 40px;
			line-height: 40px;
			width: 0;
			border-left: 0px solid #77502f;
			border-right: 40px solid transparent;
			border-bottom: 40px solid #77502f;
			border-top: 0px solid transparent;
			#{$midNightselectors} {
				border-left: 0px solid #4f4b4c;
				border-right: 40px solid transparent;
				border-bottom: 40px solid #4f4b4c;
				border-top: 0px solid transparent;
			}
		}
	}
}
