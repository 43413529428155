@import "../../../../../assets/scss/master";

.qr_code {
	p {
		#{$midNightselectors} {
			color: #d3d2d2;
		}
	}

	a {
		color: $main-fill-normal;
		font-weight: 900;
		text-decoration: underline;
	}
	.qrCode__Image {
		width: 150px;
	}
}
