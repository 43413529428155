@import "../../../../assets/scss/master";

.trh-page {
	.trh-loading {
		margin-top: 32px;
		min-height: 300px;
	}

	.th-groom-panel {
		padding: 23px 34px;
		border-radius: 8px;
		border: 1px solid #ced4da !important;
		min-height: auto !important;
		#{$midNightselectors} {
			border: 1px solid $black-stroke !important;
		}

		@media (max-width: 700px) {
			padding: 23px 16px;
		}

		.table-responsive {
			border: 1px solid #ced4da !important;
			border-radius: 8px;
			#{$midNightselectors} {
				border: 1px solid $black-stroke !important;
				background-color: $black-medium;
			}

			.th-table {
				thead {
					border-bottom: 1px solid #ced4da;
					#{$midNightselectors} {
						border-bottom: 1px solid $border-medium;
					}
				}

				th,
				td {
					border: none !important;
					font-size: 12px;
					white-space: nowrap;

					#{$midNightselectors} {
						color: $white;
					}
				}

				th {
					padding-top: 32px;
					padding-bottom: 24px;

					&:first-child {
						padding-left: 34px;
					}

					&:last-child {
						padding-right: 34px;
					}
				}

				td {
					padding-top: 32px;
					padding-bottom: 24px;
					vertical-align: middle;

					&:first-child {
						padding-left: 34px;
					}

					&:last-child {
						padding-right: 34px;
					}

					.is-debit {
						color: $debit;
						font-family: TG-Semibold;
					}

					.is-credit {
						color: $credit;
						font-family: TG-Semibold;
					}

					.th-narration {
						white-space: pre-wrap;
						width: 200px;
					}
				}
			}
		}
	}

	.th-filters {
		margin-top: 32px;
		margin-bottom: 32px;

		button {
			width: 91px !important;
			height: 45px !important;
			font-size: 14px !important;
			text-transform: capitalize;
			font-family: TG-Light;
			border-radius: 8px;
		}

		.th-filter-form {
			display: flex;
			margin-top: 32px;
			flex-wrap: wrap;

			@media (max-width: 499px) {
				flex-direction: column;
			}

			.react-select__control {
				height: 45px;
				width: 180px;
				outline: none;
				border-radius: 8px !important;
				margin-right: 12px;
				font-size: 13px;

				#{$midNightselectors} {
					background-color: $black-medium !important;
					border-color: $border-medium;
				}

				@media (max-width: 499px) {
					margin-right: 0px;
					margin-bottom: 12px;
				}
			}

			.react-datepicker__input-container {
				height: 45px !important;
				width: 177px;
				margin-right: 12px;
				margin-bottom: 0px !important;

				@media (max-width: 499px) {
					margin-right: 0px;
				}

				> input {
					border-radius: 8px !important;
					height: 100% !important;
					font-size: 13px;
					#{$midNightselectors} {
						background-color: $black-medium !important;
						border-color: $border-medium;
					}

					&::placeholder {
						font-size: 13px;
						#{$midNightselectors} {
							color: var(--page-lead-text);
							font-family: TG-Light;
						}
					}
				}
			}

			.pta-filter-apply {
				margin-right: 12px;
				@media (max-width: 499px) {
					margin-right: 0px;
					margin-bottom: 12px;
				}
			}
		}
	}
}

// Receipt modal
.th-summary {
	width: 100%;
	padding-top: 48px;
	padding-bottom: 60px;

	&__info {
		display: flex;
		justify-content: space-between;
		margin-top: 36px;

		&:first-of-type {
			margin-top: 0px;
		}

		> span {
			color: $black;
			font-size: 14px;
			font-family: TG-Medium;
			text-align: right;
			#{$modalMidNightselectors} {
				color: $white;
			}

			&:first-child {
				font-size: 13px;
				font-family: TG-Light;
				text-align: left;

				#{$modalMidNightselectors} {
					color: $gray;
				}
			}

			&.amount {
				color: $main-fill-normal;
			}

			&.narration {
				max-width: 200px;
			}
		}
	}
}
