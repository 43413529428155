@import "../../../assets/scss/master";

.RadioCheckmark__container {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/* width: 183px; */
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--sub-fill-dark) !important;
	background: $white;
	border: 1px solid #eaecf0;
	box-sizing: border-box;
	border-radius: $border-radius;
	#{$midNightselectors} {
		background: $dark-input;
		border-color: $dark-input;
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		right: 10px;
		height: 25px;
		width: 25px;
		background-color: transparent;
	}

	&:hover input ~ .checkmark {
		background-color: transparent;
	}

	// &.active {
	//     border-color: var(--main-fill-normal) !important;
	// }

	input:checked ~ .checkmark:after {
		background: var(--main-fill-normal);
	}
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
		background: #eaecf0;
	}
	input:checked ~ .checkmark:after {
		display: block;
	}
	.checkmark:after {
		content: "";
		width: 22px;
		height: 22px;
		-webkit-mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iMTAiIHN0cm9rZT0iI0VBRUNGMCIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik02IDExLjgxNjlMOC4zNDU0NSAxNEwxNSA4IiBzdHJva2U9IiNFQUVDRjAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
		mask-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iMTAiIHN0cm9rZT0iI0VBRUNGMCIgc3Ryb2tlLXdpZHRoPSIyIi8+CjxwYXRoIGQ9Ik02IDExLjgxNjlMOC4zNDU0NSAxNEwxNSA4IiBzdHJva2U9IiNFQUVDRjAiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=");
		display: inline-block;
		padding-right: 3px;
		vertical-align: middle;
	}
}

.broad {
	position: absolute;
	top: 7px;
	left: 10px;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #faf5eb;
	#{$midNightselectors} {
		background: #4f4b4c;
	}
}
