@import "../../../assets/scss/master";

.custom-pin-input {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 45px 16px;
	background-color: #fff;

	&.has-dashed-border {
		// border: 3px dashed #eee;
		border-radius: 15px;
		#{$midNightselectors} {
			background-color: #282727;
			// border: 3px dashed #333333;
		}
		#{$modalMidNightselectors} {
			background-color: #282727;
			// border: 3px dashed #333333;
		}
	}

	> p {
		font-size: 16px;
		margin-bottom: 24px;
		color: #1b1b1b;
		text-align: center;
		#{$midNightselectors} {
			color: #fff;
		}
		#{$modalMidNightselectors} {
			color: #fff;
		}
	}

	.pincode-input-container {
		@media (max-width: 450px) {
			column-gap: 16px !important;
		}

		.pincode-input-text {
			@media (max-width: 450px) {
				width: 35px !important;
				height: 35px !important;
			}
		}
	}
}
