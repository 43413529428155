@import "./../../../../assets/scss/master";

.customSwitch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;

		&:before {
			position: absolute;
			content: "";
			height: 20px;
			width: 20px;
			left: 4px;
			top: 0px;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
		}
	}

	input {
		&:checked + .slider {
			background-color: $main-fill-normal;
		}

		&:focus + .slider {
			box-shadow: 0 0 1px $main-fill-normal;
		}

		&:checked + .slider:before {
			// -webkit-transform: translateX(32px);
			// -ms-transform: translateX(32px);
			// transform: translateX(32px);
			left: unset;
			right: 4px;
		}
	}

	/* Rounded sliders */

	.slider.round {
		border-radius: 34px;

		&:before {
			border-radius: 50%;
		}
	}
}
