@import "./../../../assets/scss/master";

@mixin side-text {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 18px;
	color: #69747a;
	#{$midNightselectors} {
		color: #c4c4c4 !important;
	}
}
.radio__select {
	.active {
		border: 1px solid $main-fill-normal !important;
	}
	.radio__select__round-type {
		box-sizing: border-box;
		border-radius: $border-radius;
		display: flex;
		align-items: center;
		padding: 16px 25px;
		position: relative;
		background: $white;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1),
			0px 2px 8px rgba(0, 0, 0, 0.03);
		border-radius: 8px;
		margin-bottom: 20px;
		cursor: pointer;

		#{$midNightselectors} {
			background: $dark-input;
		}

		input[type="radio"] {
			display: none;
		}

		input[type="radio"] + *::before {
			content: "";
			display: flex;
			vertical-align: bottom;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			border-style: solid;
			border-width: 0.1rem;
			border-color: gray;
			position: absolute;
			top: 40%;
			left: 25px;
		}

		input[type="radio"]:checked + *::before {
			background: $white;
			border-color: $main-fill-normal;
			border: 5px solid $main-fill-normal;
		}
		.round-type__text {
			margin-left: 33px;

			p {
				font-style: normal;
				font-weight: 600;
				font-size: 12px;
				line-height: 18px;
				color: $black;
				margin: 0;
				#{$midNightselectors} {
					color: $white;
				}
			}

			span {
				@include side-text;
			}
		}
	}
	.side-text {
		@include side-text;
		margin-left: 0;
	}
}
