@import "../../../../assets/scss/master";

.account-statement {
	> h1 {
		font-size: 18px;
		color: $black;
		font-family: TG-Medium;

		#{$midNightselectors} {
			color: #bbbbbb;
		}
	}

	> p {
		max-width: 472px;
		color: $black;
		font-size: 12px;
		line-height: 20px;

		#{$midNightselectors} {
			color: var(--gray-fill-light);
		}
	}

	&__panel {
		border-radius: 8px;
		margin-top: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 98px 20px;
		min-height: 580px;

		#{$midNightselectors} {
			border: 1px solid $black-stroke;
		}

		@media (max-width: 700px) {
			padding: 48px 20px;
			min-height: 400px;
		}
	}

	// Statement type styles
	&__type {
		width: 100%;
		max-width: 542px;

		> h2 {
			font-size: 14px;
			color: $black;
			font-family: TG-Medium;
			margin: 0px;
			margin-bottom: 24px;

			#{$midNightselectors} {
				color: $white;
			}
		}

		.ast-option {
			width: 100%;
			border: 1px solid #eee;
			cursor: pointer;
			height: 82px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			padding-left: 29px;
			padding-right: 29px;
			text-transform: capitalize;

			#{$midNightselectors} {
				background-color: #282727;
				border-color: $border-medium;
				box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1),
					0px 2px 8px rgba(0, 0, 0, 0.03);
			}

			&.selected {
				border-color: #d0a23c !important;
			}

			&:first-of-type {
				margin-bottom: 12px;
			}
		}
	}

	&__form {
		width: 100%;
		max-width: 542px;

		.ast-past {
			width: 100%;

			h2 {
				color: $black;
				font-size: 14px;
				margin-bottom: 8px;

				#{$midNightselectors} {
					color: rgba(187, 187, 187, 1);
				}
			}

			.ast-past-wrapper {
				display: flex;

				@media (max-width: 899px) {
					flex-wrap: wrap;
				}

				.ast-past-badge {
					color: $black;
					font-size: 14px;
					font-family: TG-Medium;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 170px;
					height: 50px;
					border-radius: 30px;
					border: 1px solid #eee;
					margin-right: 16px;
					flex-shrink: 0;
					margin-bottom: 16px;
					cursor: pointer;

					#{$midNightselectors} {
						background-color: $dark-input;
						border-color: $dark-input;
						color: $white;
					}

					&.selected {
						background-color: rgba(62, 98, 190, 1) !important;
						border-color: rgba(62, 98, 190, 1) !important;
						color: $white !important;
					}
				}
			}

			.ast-divider {
				color: $black;
				display: flex;
				align-items: center;
				font-size: 14px;
				font-family: TG-Medium;
				margin-top: 20px;
				margin-bottom: 34px;

				#{$midNightselectors} {
					color: rgba(187, 187, 187, 1);
				}

				> span {
					margin: 0px 24px;
					display: block;
					line-height: 0px;
				}

				&::before,
				&::after {
					content: "";
					flex: 1;
					height: 2px;
					background: linear-gradient(
							95.09deg,
							#d0a23c -10.51%,
							#77502f 109.52%
						)
						no-repeat center;
					background-size: cover;
					flex-shrink: 0;
					vertical-align: middle;
				}
			}

			label {
				color: $black;
				font-family: TG-Medium;
				font-size: 14px;

				#{$midNightselectors} {
					color: $white;
				}
			}

			.ast-form-group {
				display: flex;
				margin-top: 24px;

				> div {
					width: 100%;
				}

				.form-control {
					height: 50px;
					font-size: 14px;
				}

				.form-group {
					margin-bottom: 0px;
				}

				.ast-input {
					height: 50px;
					font-size: 14px;
					margin-bottom: 0px;
					width: 100%;

					#{$midNightselectors} {
						background-color: $dark-input !important;
						color: $white !important;
						border-color: $border-medium !important;
					}
				}

				.react-datepicker__input-container {
					.datepicker--has-error {
						border-color: #a94442 !important;
					}

					> input {
						border: 1px solid #ced4da;
						background-color: transparent !important;

						#{$midNightselectors} {
							border-color: $border-medium;
						}
					}
				}

				.react-select__control {
					height: 50px;
					outline: none;
					border-radius: 15px !important;

					#{$midNightselectors} {
						border-color: $border-medium;
					}
				}
			}

			.ast-form-info {
				font-size: 10px;
				line-height: 16px;
				max-width: 542px;
				display: block;
				color: $black;
				font-family: TG-Light;
				margin-top: 7px;

				#{$midNightselectors} {
					color: $white;
				}
			}

			.ast-submit-btn {
				text-transform: none !important;
				height: 50px;
				margin-top: 24px;
			}
		}
	}
}

// Submit modal styles
.ast-summary {
	width: 100%;
	padding-top: 32px;
	padding-bottom: 32px;

	.ast-summary-footer {
		margin-top: 32px;
		display: flex;
		justify-content: space-between;
		width: 100%;

		> button {
			width: 48% !important;
			height: 50px !important;
			font-size: 14px !important;
			text-transform: capitalize;
			font-family: TG-Semibold;
			border-radius: 15px;
		}
	}
}

.ast-success-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 41px 16px;

	> h2 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 308px;
		margin-top: 24px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> button {
		text-transform: none !important;
		max-width: 340px;
		height: 50px;
	}
}

.ast-disclaimer {
	padding: 51px 16px 43px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	> h2 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 331px;
		margin-top: 24px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}

		span {
			font-family: TG-Bold;
		}
	}

	&__footer {
		margin-top: 60px;
		width: 100%;

		> button {
			width: 48% !important;
			height: 50px !important;
			font-size: 14px !important;
			text-transform: capitalize !important;
			font-family: TG-Semibold;
			border-radius: 15px;
		}
	}
}
