@import "../../../../../../assets/scss/master";

.mkt-delivery-details {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 1149px) {
			flex-direction: column;
			align-items: flex-start;
		}

		h1 {
			font-size: 18px;
			font-family: TG-Semibold;
			color: $black;
			margin: 0px;
			#{$midNightselectors} {
				color: $gray;
			}
		}

		p {
			margin: 0px;
			margin-top: 4px;
			font-size: 12px;
			color: $black;
			#{$midNightselectors} {
				color: $secondary-gray;
			}
		}

		.mkt-delivery-steps {
			display: flex;
			align-items: center;

			@media (max-width: 1149px) {
				margin-top: 16px;
			}

			@media (max-width: 889px) {
				flex-wrap: wrap;
			}

			@media (max-width: 767px) {
				width: 100%;
				justify-content: space-between;
			}

			.mkt-delivery-step {
				display: flex;
				align-items: center;
				margin-right: 12px;

				@media (max-width: 889px) {
					margin-bottom: 12px;
				}

				> span {
					font-size: 13px;
					margin-left: 16px;
					white-space: nowrap;
					color: $black;
					font-family: TG-Medium;
					line-height: 0;
					#{$midNightselectors} {
						color: $white;
					}

					&.highlighted {
						color: $main-fill-normal !important;
					}
				}

				&__count {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					background-color: $gray;
					border: 1.5px solid #ccc;
					display: flex;
					align-items: center;
					justify-content: center;
					#{$midNightselectors} {
						color: $gray;
						background-color: $white;
						border-color: $gray;
					}
				}

				&::after {
					content: "";
					width: 77px;
					height: 1px;
					// border-top: 1px dashed $gray;
					flex-shrink: 0;
					margin-left: 12px;
					background: url("../../../../../../assets/img/dashed-line.svg")
						no-repeat;
					background-size: cover;

					@media (max-width: 1023px) {
						flex-shrink: 0;
					}

					@media (max-width: 889px) {
						width: 10px;
					}

					@media (max-width: 767px) {
						display: none;
					}
				}

				&:last-child {
					margin-right: 0px;
					&::after {
						display: none;
					}
				}
			}
		}
	}

	&__content {
		margin-top: 64px;
		display: flex;

		@media (max-width: 1024px) {
			display: block;
		}

		@media (max-width: 700px) {
			margin-top: 32px;
		}

		> .mkt-cart-summary {
			max-width: 389px;
			@media (max-width: 1024px) {
				max-width: 100%;
			}
		}
	}

	.mkt-loading {
		min-height: 300px;
		margin-top: 24px;
		flex: 1;
	}

	.mkt-error-loading {
		> div {
			max-width: 318px;
		}
	}
}
