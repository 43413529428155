@import "../../../assets/scss/master";

.custom-search-input {
	.custom-search-input-container {
		position: relative;

		.custom-search-icon-container {
			position: absolute;
			color: $black;
			left: 26px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 14px;
			font-family: TG-Light;
			#{$midNightselectors} {
				color: $white;
			}
		}

		input {
			font-size: 14px !important;
			margin-bottom: 0px !important;
			height: 45px;
			border-radius: 8px !important;
			padding-left: 60px !important;

			&::placeholder {
				font-size: 13px;
				#{$midNightselectors} {
					color: var(--page-lead-text);
					font-family: TG-Light;
				}
			}

			#{$midNightselectors} {
				color: #fff !important;
				background-color: #111111 !important;
				border-color: #333333;
			}

			&.has-error {
				border-color: #a94442 !important;
			}
		}
	}
}
