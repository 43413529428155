.public-nav {
	width: 100%;
	// height: 80px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.7rem 3rem;

	@media (max-width: 500px) {
		gap: 10px;
		padding: 0.7rem 12px;
	}

	.logo {
		flex-shrink: 0;

		@media (max-width: 500px) {
			width: 84px;
			height: auto;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	.back-btn {
		@media (max-width: 500px) {
			font-size: 12px;

			img {
				width: 7px;
				margin-right: 8px;
			}
		}
	}

	.btn-link {
		font-size: 12px;
		background-color: #000;
		color: #fff;
		font-weight: 500;
		padding: 0.5rem 1rem;
		border-radius: 0.8rem;
		text-align: center;

		&:hover {
			color: #fff;
		}

		@media (max-width: 500px) {
			font-size: 10px;
		}
	}
}

.modal-container {
	padding: 20px;

	.modal-content-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.modal-text {
			font-family: TG-Light;
			text-align: center;
			font-weight: 400;
			font-size: 14px;
			margin: 10px 0.5rem;

			> span {
				font-weight: 700;
			}
		}
	}
	.btn-container {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}
}
