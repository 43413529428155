@import "../../../assets/scss/master";

.custom-tabs {
	width: 100%;

	> ul {
		width: 100%;
		padding: 0px;
		display: flex;
		list-style: none;
		border-bottom: 1px solid #ccc;
		#{$modalMidNightselectors} {
			border-bottom: 1px solid rgb(58, 58, 58);
		}

		li {
			margin-right: 18px;

			&:last-of-type {
				margin-right: 0px;
			}

			a,
			span {
				color: var(--mint-midnight);
				cursor: pointer;
				padding: 0px 15px 14px;
				display: block;
				position: relative;
				font-size: 12px;
				font-family: TG-Medium;
				#{$midNightselectors} {
					color: #bbbbbb;
				}

				&.active,
				&[data-current="true"] {
					color: var(--mint-underlining) !important;

					&::after {
						content: "";
						height: 1.5px;
						width: 100%;
						background-color: var(--mint-fill-darker);
						position: absolute;
						bottom: 0;
						left: 0;
					}
				}
			}
		}
	}
}
