@import "../../../../../../assets/scss/master";

.mkt-product-card {
	border-radius: 8px;
	padding: 10px 8px;
	background-color: $white;
	border: 1px solid $input-border;
	// max-height: 307px;
	height: auto;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	#{$midNightselectors} {
		background-color: $black-medium;
		border-color: transparent;
	}

	&__image {
		width: 100%;
		position: relative;

		img {
			width: 100%;
			height: 190px;
			border-radius: 8px;
			object-fit: cover;
		}

		.mkt-cart-icon {
			position: absolute;
			bottom: -15px;
			right: 3px;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0px;
			border: none;
			background-color: #eee;
			#{$midNightselectors} {
				background-color: $white;
			}

			&:disabled {
				cursor: not-allowed;
			}
		}
	}

	> p {
		font-size: 13px;
		font-family: TG-Semibold;
		color: $black;
		margin-top: 16px;
		max-width: 204px;
		margin-bottom: 12px;
		#{$midNightselectors} {
			color: $white;
		}
	}

	&__footer {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: auto;

		@media (min-width: 934px) and (max-width: 1007px) {
			flex-direction: column;
			align-items: flex-start;
		}

		> h4 {
			margin: 0px;
			color: $black;
			font-size: 18px;
			font-family: TG-Medium;
			#{$midNightselectors} {
				color: $gray;
			}
			@media (min-width: 934px) and (max-width: 1007px) {
				margin-top: 10px;
			}
		}
	}
}
