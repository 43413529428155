@import "../../../../../../assets/scss/master";

.setup-modals-form {
	overflow-y: auto;

	> .setup-modals-form-lead {
		color: #000;
		font-family: TG-Medium;
		font-size: 14px;
		line-height: 20px;
		margin-bottom: 20px;
		#{$modalMidNightselectors} {
			color: #bbbbbb;
		}
	}

	label {
		color: $black;
		font-family: TG-Medium;
		font-size: 14px;

		#{$modalMidNightselectors} {
			color: #bbbbbb;
		}
	}

	.form-control {
		height: 50px;
		font-size: 14px;
	}

	.form-group {
		margin-bottom: 0px;
	}

	.intl-tel-input {
		.selected-flag {
			background-color: transparent !important;
		}
	}

	.setup-modal-input {
		height: 50px;
		font-size: 14px;
		margin-bottom: 0px;

		#{$modalMidNightselectors} {
			background-color: $dark-input !important;
			color: $white !important;
			border-color: $dark-input !important;
		}
	}

	.react-datepicker__input-container {
		.datepicker--has-error {
			border-color: #a94442 !important;
		}
	}

	.react-select__control {
		height: 50px;
		outline: none;
		border-radius: 15px !important;

		#{$modalMidNightselectors} {
			border-color: $dark-input;
		}
	}

	button {
		text-transform: none !important;
		height: 50px;
	}
}

.setup-success-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 332px;
	padding: 41px 16px;
	text-align: center;

	> h2 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 308px;
		margin: 24px 0px 42px;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> button {
		text-transform: none !important;
		max-width: 304px;
		height: 50px;
	}
}

.setup-toast {
	position: fixed;
	z-index: 3000;
	top: 20px;
	right: 20px;
}
