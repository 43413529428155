.bigThumb {
    width: 96px;
    height: 96px;
    border-radius: 48px;
    overflow: hidden;
    background: #21262a;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
    -webkit-appearance: none;
    img {
        width: 96px !important;
        height: 96px !important;
        object-fit: cover;
    }
}
