@import "./../../../../../assets/scss/master";

.investment {
	.investment-step {
		border-radius: 30px;
		padding: 7px 20px;
		font-size: 13px;
		border: 2px solid $main-fill-normal;
		color: $main-fill-normal;
		background: rgba(209, 162, 60, 0.1);
	}
	ul {
		padding: 0;
		margin-bottom: 0;

		&.full-white {
			li {
				small,
				p {
					#{$midNightselectors} {
						color: $white;
					}
				}
			}
		}

		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 25px;

			p,
			small {
				font-size: 12px;
				letter-spacing: 0.25px;
				color: #333333;
				margin-bottom: 5px;

				#{$midNightselectors} {
					color: #bbbbbb;
				}
				.full-white & {
					color: $white !important;
				}
			}

			small {
				font-size: 11px;
			}

			h4 {
				font-size: 15px;
				line-height: 27px;
				color: $black;
				margin-bottom: 0;
				font-weight: 600;
				#{$midNightselectors} {
					color: $white;
				}
			}
		}
	}
	.investment-card {
		padding: 30px;
		border-radius: 8px;
		background: $white;
		width: 400px;
		min-width: 400px;
		height: 300px;
		margin: 0 30px 30px 0;

		#{$midNightselectors} {
			background: $black-medium;
		}

		&.bg-primary,
		&.bg-success {
			p,
			h4 {
				color: $white;
			}
		}

		&.bg-primary {
			background-color: #3e62be !important;
		}

		&.bg-success {
			background-color: #4abe86 !important;
		}

		&.active {
			border: 2px solid $main-fill-normal;
		}

		&.full-width {
			width: 100%;
			height: auto !important;
			min-width: unset;
		}
		&.completed-card {
			height: auto;
			padding: 30px 30px 0;
		}
		&.details {
			background: #3e62be !important;

			li {
				color: $white !important;

				p,
				small,
				h4 {
					color: $white !important;
				}
			}

			.create-acc {
				padding: 5px;
				color: #3e62be;
				background: $white;
			}

			.btn-custom-bordered {
				color: $white;
				padding: 5px;
				border: 1.2px solid $white;

				&:disabled {
					background: rgba(255, 255, 255, 0.7);
					opacity: 0.4;
				}
			}
			.invest-icon {
				margin-right: 15px;
				circle {
					fill: $white;
					fill-opacity: 0.51;
				}
				g {
					path {
						stroke: $white;
					}
					rect {
						fill: $white;
					}
				}
			}
		}
	}
	.investment-interest {
		color: $black;
		background: $white;
		font-size: 10px;
		width: auto;
		border-radius: 8px;
		padding: 4px 10px 3px 10px;
		font-weight: 600;
		display: inline-block;
	}
	.badge {
		&.text--primary {
			background: $main-fill-normal;
			border-radius: $border-radius;
			color: $white;
			padding: 3px 15px;
			font-size: 14px;
			font-weight: 600;

			#{$midNightselectors} {
				background: rgba(208, 162, 60, 0.1);
			}
		}
	}
	.text--main {
		color: $main-fill-normal !important;
	}
}
