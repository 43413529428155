@import "../../../assets/scss/master";

.modal-content {
	margin: auto;
	box-shadow: none;
	border-radius: 6px;
	border: 0px !important;
	overflow: hidden;
	background: $white;
	.midnight & {
		background: $black-medium;
	}
}

.modal-backdrop.show {
	opacity: 0.8 !important;
}

.modal-open .modal {
	display: flex !important;
	align-items: center !important;

	.modal-dialog {
		flex-grow: 1;
	}
}

.modal-header {
	border: 0px;

	.rel-button {
		display: flex;
		align-items: center;
		justify-content: space-between;

		h6 {
			margin: 0;
		}
		button.close {
			position: relative;
			margin: 0;
			padding: 0;
			top: 0;
			right: 0;
			text-shadow: 0 1px 0 $main-fill-normal;
			color: $main-fill-normal;
			opacity: 1;
		}
	}
}

.drive-head {
	border-bottom: 1px solid #0000000f;
	#{$modalMidNightselectors} {
		border-bottom: 1px solid #222222;
	}
	h6 {
		font-family: "TG-Medium";
		font-weight: 100;
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.02em;
		color: #37474f;
		.midnight & {
			color: $white;
		}
	}
}
.midnight {
	.sub-heading {
		color: $white;
	}

	.RadioCheckmark__container {
		background: $dark-input;
		border-color: $dark-input;
	}

	.broad {
		background: #4f4b4c;
	}
	.myText {
		color: $white;
	}
	.frownCenter {
		svg path {
			fill: #c4c4c4;
		}
		h6 {
			color: #c4c4c4;
		}
	}
	.big-lock svg {
		circle {
			fill: $dark-input;
		}
	}
	.smile h4 {
		color: #c4c4c4;
	}
}

button.close {
	position: absolute;
	top: 16px;
	right: 16px;
	background-color: transparent !important;
	font-size: 32px;
	z-index: 1000;
	color: $main-fill-normal !important;
	text-shadow: 0 1px 0 $main-fill-normal;
	opacity: 1 !important;
	// .midnight & {
	// 	color: $white;
	// }
}

button.close.darey {
	padding: 8px 12px;
	font-size: 35px !important;
	color: gainsboro;
	position: absolute;
	right: 16px;
	top: 15px;
	opacity: 1;
	box-shadow: none;
	background: transparent;
	border-radius: 0px 6px 0px 0px;
	outline: none;
	box-shadow: none;
}
