@import "../../../../../assets/scss/master";

.spend-and-save-page {
	.sas-loading {
		min-height: 400px;
		margin-top: 33px;
	}

	.sas-groom {
		border-radius: 8px;
		border: 1px solid #eee;
		margin-top: 35px;

		#{$midNightselectors} {
			border-color: $black-stroke;
		}

		.sas-empty {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			padding: 20px;
			height: 100%;
			min-height: inherit;

			h3 {
				font-size: 16px;
				color: $black;
				font-family: TG-Semibold;
				text-align: center;
				margin-top: 16px;
				#{$midNightselectors} {
					color: $white;
				}
			}

			p {
				font-size: 14px;
				color: $black;
				text-align: center;
				max-width: 296px;
				margin: 16px 0px 32px;

				#{$midNightselectors} {
					color: $gray;
				}
			}

			> button {
				width: 100%;
				max-width: 300px;
				text-transform: capitalize !important;
				font-family: TG-Light;
				height: 50px !important;
			}
		}

		&__form {
			max-width: 488px;
			min-height: calc(100vh - 250px);
			margin: 0 auto;
			padding: 79px 16px;

			> h2 {
				color: $black;
				font-size: 16px;
				margin: 0px;
				font-family: TG-Semibold;
				#{$midNightselectors} {
					color: $white;
				}
			}

			> p {
				color: $black;
				font-size: 15px;
				margin: 8px 0px 36px;
				#{$midNightselectors} {
					color: $gray;
				}
			}
		}
	}

	.sas-list-wrapper {
		display: flex;

		@media (max-width: 1024px) {
			flex-direction: column;
		}

		.sas-item {
			border: 1px solid #eee;
			max-height: 500px;
			border-radius: 8px;
			padding: 35px 30px;
			max-width: 50%;
			flex: 1;
			background-color: $white;
			#{$midNightselectors} {
				background-color: $black-medium;
				border-color: $black-stroke;
			}

			@media (max-width: 1024px) {
				max-width: 100%;
				max-height: 100%;
			}

			@media (max-width: 700px) {
				padding: 35px 16px;
			}

			&__header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding-bottom: 11px;
				border-bottom: 1px solid #eee;
				#{$midNightselectors} {
					border-color: $border-medium;
				}

				@media (max-width: 500px) {
					flex-direction: column;
					align-items: flex-start;
				}

				> div {
					width: fit-content;
					display: flex;
					flex-direction: column;

					&:last-child {
						@media (max-width: 500px) {
							margin-top: 16px;
						}
					}
				}

				.btn-green {
					width: 135px !important;
					cursor: default;
				}

				h3 {
					margin: 0px;
					margin-top: 12px;
					color: $main-fill-normal;
					font-size: 24px;
					font-family: TG-Semibold;
					text-align: center;
				}

				.gradient {
					border: none !important;
					width: 192px;
					max-width: 100%;
					height: 50px;
					font-family: TG-Semibold;

					~ span {
						font-size: 10px;
						color: $black;
						margin-top: 10px;
						color: $gray;
						line-height: 0px;
					}
				}
			}

			&__info-container {
				margin-top: 44px;

				> div {
					display: flex;
					justify-content: space-between;
					margin-bottom: 32px;

					&:last-child {
						margin-bottom: 0px;
					}

					.sas-item-info {
						&:last-child {
							> * {
								text-align: right;
							}
						}

						h4 {
							color: $black;
							margin: 0px;
							margin-bottom: 8px;
							font-size: 14px;
							#{$midNightselectors} {
								color: $white;
							}
						}

						h3 {
							color: $black;
							margin: 0px;
							font-size: 20px;
							font-family: TG-Semibold;
							#{$midNightselectors} {
								color: $white;
							}
						}
					}
				}
			}

			&__footer {
				display: flex;
				margin-top: 34px;
				width: 100%;
				align-items: center;
				justify-content: space-between;

				@media (max-width: 400px) {
					flex-direction: column;
				}

				> button {
					width: 49%;
					text-transform: capitalize !important;
					height: 50px !important;

					&:first-child {
						@media (max-width: 400px) {
							margin-bottom: 10px;
						}
					}

					@media (max-width: 400px) {
						width: 100%;
					}
				}
			}
		}

		.sas-list-transactions {
			flex: 1;
			margin-top: 0px;
			margin-left: 24px;
			border: 1px solid #eee;
			background-color: $white;
			display: flex;
			flex-direction: column;
			padding-bottom: 0px;
			border-radius: 8px;
			max-height: 500px;
			max-width: 50%;
			#{$midNightselectors} {
				background-color: $black-medium;
				border-color: $black-stroke;
			}

			@media (max-width: 1024px) {
				margin-left: 0px;
				margin-top: 24px;
				max-width: 100%;
				// max-height: 100%;
			}

			> h2 {
				color: $main-fill-normal;
				font-size: 18px;
				font-family: TG-Semibold;
				padding: 39px 36px 28px;
				border-bottom: 1px solid #eee;
				margin-bottom: 0px;
				#{$midNightselectors} {
					border-color: $border-medium;
				}

				@media (max-width: 400px) {
					padding: 39px 16px 28px;
				}
			}

			&__table {
				overflow-y: scroll;
				flex: 1;
				padding-bottom: 41px;

				.sas-list-transactions-table-row {
					display: flex;
					justify-content: space-between;
					// align-items: center;
					margin-top: 42px;
					padding: 0px 30px;

					@media (max-width: 400px) {
						padding: 0px 16px;
					}

					> div {
						p {
							font-family: TG-Semibold;
							font-size: 18px;
							color: $black;
							margin: 8px 0px 0px;
							#{$midNightselectors} {
								color: $white;
							}

							~ span {
								color: $gray;
								font-size: 12px;
								font-family: TG-Semibold;
								#{$midNightselectors} {
									color: rgba(255, 255, 255, 0.4);
								}
							}
						}

						&:last-child {
							display: flex;
							flex-direction: column;
							align-items: center;

							p {
								font-size: 18px;
								font-family: TG-Semibold;
								border-left: none !important;
								// margin: 0px;
								// // margin-bottom: 8px;

								&.credit {
									color: $credit;
								}

								&.debit {
									color: $debit;
								}
							}

							> span {
								width: 86px;
								height: 28px;
								border-radius: 8px;
								border: 1px solid transparent !important;
								color: $white;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 10px;
								font-family: TG-Semibold;

								&.success {
									background-color: $credit;
								}

								&.failed {
									background-color: #cc2500;
								}

								&.pending {
									background-color: $main-fill-normal;
								}
							}
						}
					}
				}
			}
		}
	}
}

.sas-modal,
.create-sas-page {
	width: 100%;
	padding-top: 32px;
	padding-bottom: 32px;

	&__footer {
		margin-top: 48px;
		display: flex;
		justify-content: space-between;

		> button {
			width: 49% !important;
			text-transform: capitalize !important;
			border-radius: 15px !important;
			height: 50px !important;
		}

		@media (max-width: 500px) {
			flex-direction: column;

			> button {
				width: 100% !important;
				&:first-child {
					margin-bottom: 16px;
				}
			}
		}
	}

	form {
		width: 100%;
		padding: 40px 0px;

		label,
		.custom-control-label {
			color: $black !important;
			font-family: TG-Light;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: $white !important;
				border-color: transparent !important;
			}

			#{$midNightselectors} {
				color: $white !important;
				border-color: transparent !important;
			}
		}

		.form-control {
			height: 50px;
			font-size: 14px;
		}

		.form-group {
			margin-bottom: 0px;
		}

		.sas-input {
			height: 50px;
			font-size: 14px;
			margin-bottom: 0px;

			#{$modalMidNightselectors} {
				background-color: $dark-input !important;
				color: $white !important;
				border-color: transparent !important;
			}

			#{$midNightselectors} {
				background-color: $dark-input !important;
				color: $white !important;
				border-color: transparent !important;
			}

			&.has-error {
				border-color: #a94442 !important;
			}

			&.disabled {
				cursor: not-allowed;
			}

			&.percentage {
				&::placeholder {
					text-align: center;
				}
			}
		}

		.sas-frequency {
			display: flex;
			width: 100%;
			justify-content: space-between;

			@media (max-width: 899px) {
				flex-wrap: wrap;
			}

			> * {
				flex: 1;

				&:last-child {
					margin-right: 0px;
					margin-bottom: 0px;
				}

				@media (max-width: 400px) {
					flex: auto;
					margin-right: 0px;
				}

				@media (min-width: 900px) {
					margin-bottom: 0px;
				}
			}
		}

		.sas-form-info {
			color: $gray;
			font-size: 14px;
			font-family: TG-Medium;

			&__large {
				font-size: 18px;
				margin: 0px;
				margin-bottom: 30px;
				color: $black;
				max-width: 493px;
				#{$modalMidNightselectors} {
					color: $gray;
				}

				#{$midNightselectors} {
					color: $gray;
				}
			}
		}

		> button {
			border-radius: 15px !important;
			margin-top: 32px;
			height: 50px !important;
			text-transform: capitalize !important;
		}

		.react-datepicker__input-container {
			> input {
				height: 50px !important;
				font-size: 14px;
				margin-bottom: 0px;

				// #{$modalMidNightselectors} {
				// 	background-color: $dark-input !important;
				// 	color: $white !important;
				// 	border-color: $border-medium !important;
				// }

				&.disabled {
					cursor: not-allowed;
				}
			}

			.datepicker--has-error {
				border-color: #a94442 !important;
				#{$modalMidNightselectors} {
					border-color: #a94442 !important;
				}
				#{$midNightselectors} {
					border-color: #a94442 !important;
				}
			}
		}
	}
}

.sas-success-modal {
	margin-top: 24px;
	width: 100%;
	max-width: 340px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding: 41px 16px 55px;
	text-align: center;

	> h3 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 308px;
		margin-top: 24px;
		text-align: center;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> button {
		text-transform: capitalize !important;
		border-radius: 15px !important;
		height: 50px !important;
		margin-top: 42px;
	}
}
