@import "../../../../assets/scss/master";

.congrats-mssg {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 54px 16px 0px;

	color: #37474f;
	font-family: "TG-Medium";
	text-align: center;

	&.no-bg {
		background: none;
	}

	#{$modalMidNightselectors} {
		color: $white;
	}

	h6 {
		font-weight: 100;
		font-size: 14px;
		letter-spacing: 0.02em;
		margin: 0px;
		margin-bottom: 4px;
	}

	p {
		font-size: 10px;
		letter-spacing: 0.25px;
		margin: 0px;
	}
}
