@import "../../../../../assets/scss/master";

.ft-success-modal-body {
	margin-top: 32px;

	.ft-success-modal-info {
		display: flex;
		justify-content: space-between;
		padding-bottom: 12px;
		padding-top: 12px;
		border-bottom: 1px solid #eee;
		#{$modalMidNightselectors} {
			border-color: #333333;
		}

		&:first-of-type {
			padding-top: 0;
		}

		span {
			color: #1b1b1b;
			font-family: TG-Medium;
			#{$modalMidNightselectors} {
				color: #fff !important;
			}

			&:last-of-type {
				text-align: right;
			}
		}
	}

	a {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 54px;
	}
}
