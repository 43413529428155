@import "../../../assets/scss/master";

.switch-account-menu {
	background-color: $white;
	position: absolute;
	top: 25px;
	right: 0;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all 0.3s ease-in-out;
	box-shadow: 0px 4px 10px rgba(232, 241, 248, 0.5);
	#{$midNightselectors} {
		background: $black-medium;
		box-shadow: 0px 0px 4px $black;
	}
	border-radius: 4px;
	z-index: 300;
	border: 1px solid rgba(255, 255, 255, 0.1);
	width: 330px;
	cursor: default;

	@media screen and (max-width: 330px) {
		width: 270px;
	}

	&.show {
		top: 45px;
		opacity: 1;
		visibility: visible;
		pointer-events: all;
	}

	.switch-account-menu-header {
		border-bottom: 1px solid #eee;
		padding-bottom: 11px;
		padding: 12px 16px;
		#{$midNightselectors} {
			border-bottom: 1px solid #2b2b2b;
		}

		h6 {
			color: #111;
			font-family: TG-Medium;
			font-size: 12px;
			margin: 0;
			#{$midNightselectors} {
				color: #cccccc;
			}
		}

		> button {
			background-color: #ccc;
			border: none;
			outline: none;
			padding: 0px;
			border-radius: 50%;
			width: 20px;
			height: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			#{$midNightselectors} {
				background-color: rgba(255, 255, 255, 0.4);
			}

			img {
				width: 10px;
				height: 10px;
			}
		}
	}

	.switch-account-menu-accounts {
		padding: 0px;

		> li {
			padding: 16px 16px;
			border-bottom: 1px solid #eee;
			#{$midNightselectors} {
				border-bottom: 1px solid #2b2b2b;
			}

			&:last-of-type {
				border-bottom: none;
				#{$midNightselectors} {
					border-bottom: none;
				}
			}

			.switch-account-account-details {
				cursor: pointer;

				.switch-account-account-info {
					h6 {
						margin: 0px;
						color: #111111;
						font-size: 14px;
						font-family: TG-Medium;
						margin-bottom: 2px;
						#{$midNightselectors} {
							color: $white;
						}
					}

					> span {
						display: block;
						color: #808080;
						font-size: 12px;
						text-transform: capitalize;
					}
				}
			}
		}
	}
}
