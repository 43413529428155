@import "../../../assets/scss/master";

.custom-large-badge {
	color: $black;
	font-size: 14px;
	font-family: TG-Medium;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 170px;
	height: 50px;
	border-radius: 30px;
	border: 1px solid #eee;
	margin-right: 16px;
	flex-shrink: 0;
	margin-bottom: 16px;
	cursor: pointer;

	#{$midNightselectors} {
		background-color: $dark-input;
		border-color: $dark-input;
		color: $white;
	}

	#{$modalMidNightselectors} {
		background-color: $dark-input;
		border-color: $dark-input;
		color: $white;
	}

	&.selected {
		background-color: rgba(62, 98, 190, 1) !important;
		border-color: rgba(62, 98, 190, 1) !important;
		color: $white !important;
	}

	&:last-child {
		margin-right: 0px;
		// margin-bottom: 0px;
	}

	// @media (min-width: 900px) {
	// 	margin-bottom: 0px;
	// }
}
