@import "../../../../../../assets/scss/master";

.SavingGoalsCat {
	padding: 0 40px 20px;
	.savings-box {
		box-shadow: none;
		margin-bottom: 18px;
		.ground {
			color: $white !important;
		}
		&.danger {
			background: #d03c6a;
		}
		&.success {
			background: #558760;
		}
		&.primary {
			background: #3e62be;
		}
	}
}

@media (max-width: 768px) {
	.SavingGoalsCat {
		.savings-box {
			height: auto;
		}
	}
}
