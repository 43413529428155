@import "../master";

.form-control {
	border-radius: $border-radius;
	&.bolden {
		font-family: "TG-Medium";
		font-size: 15px;
	}
}

input.passwordType {
	font-family: text-security-disc;
	// -webkit-touch-callout: none; /* iOS Safari */
	// -webkit-user-select: none; /* Safari */
	// -khtml-user-select: none; /* Konqueror HTML */
	// -moz-user-select: none; /* Old versions of Firefox */
	// -ms-user-select: none; /* Internet Explorer/Edge */
	// user-select: none; /* Non-prefixed version, currently
	//                               supported by Chrome, Edge, Opera and Firefox */
	font-size: 18px !important;
	letter-spacing: 2px;
	-webkit-text-security: square !important;
}

input.transfew,
select.smartSec {
	#{$midNightselectors} {
		background: transparent !important;
		color: #c4c4c4;
	}
}

input:disabled {
	cursor: not-allowed !important;
}

// input[disabled]::before{
//     display: block;
//     background: #fafafac2 url(https://res.cloudinary.com/dswxtf2ay/image/upload/v1587871348/Mint_InternetBanking/icons/Vector_cpnstn.svg) no-repeat !important;
//     background-position:  right 20px bottom 10px;
// }
