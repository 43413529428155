// @import url("../img/9mobile_logo.svg");

.auth-container {
	background-color: #f4f4f4;
	min-height: 100vh;
	padding: 0px 6px 93px;

	@media (max-width: 499px) {
		padding-bottom: 50px;
	}

	.biz-reg-link {
		width: 100%;
		height: 88px;
		display: flex;
		justify-content: flex-end;
		position: fixed;
		z-index: 100;
		padding: 24px 77px;
		left: 0;
		top: 0;

		@media (max-width: 767px) {
			justify-content: center;
			align-items: center;
			padding: 24px 16px;
		}
	}

	.mintyn-logo {
		display: flex;
		justify-content: center;
		margin-top: 93px;

		@media (max-width: 499px) {
			margin-top: 50px;
		}

		a {
			width: fit-content;
			height: fit-content;

			img {
				width: 135px;
				height: 53px;
			}
		}
	}

	.auth-tabs {
		width: 100%;
		display: flex;
		border-radius: 32px;
		height: 55px;
		max-width: 395px;
		margin: 65px auto 0px;
		background-color: #ebebeb;
		padding: 5px;

		@media (max-width: 1119px) {
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: none;
			scrollbar-width: none;
			max-width: 100%;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		li {
			height: 100%;
			flex: 1;

			a {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 32px;
				font-size: 18px;
				color: $black;
				font-weight: 500;
				text-align: center;

				@media (max-width: 767px) {
					font-size: 14px;
				}

				&.active {
					background-color: $white;
				}
			}
		}
	}

	.auth-tab-content {
		margin: 40px auto 0px;
		max-width: 497px;
	}

	.login-form {
		display: flex;
		flex-direction: column;
		gap: 32px;

		&.basic-information-form {
			max-width: 503px;
			margin: 56px auto 0px;
		}

		.form-footer {
			max-width: 486px;
			text-align: center;
			font-size: 16px;
			font-weight: 500;

			a {
				color: $primary;
				font-weight: 500;
				font-size: 18px;

				@media (max-width: 767px) {
					font-size: 16px;
				}
			}
		}

		.error-msg {
			color: $error !important;
			font-size: 14px;
			margin-top: 4px;
		}
	}

	.info-heading {
		font-weight: 500;
		font-size: 18px;

		@media (max-width: 767px) {
			font-size: 16px;
		}
	}

	.info-text {
		text-align: center;
		font-size: 16px;

		&.gradient {
			font-weight: 500;
			background: linear-gradient(
				95.09deg,
				#d0a23c -10.51%,
				#77502f 109.52%
			);
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	}

	.bottom_links a {
		// background: linear-gradient(95.09deg, #d0a23c -10.51%, #77502f 109.52%);
		// -webkit-background-clip: text;
		// background-clip: text;
		// -webkit-text-fill-color: transparent;
		color: $primary;
		font-size: 16px;
		font-weight: 500;
		font-family: inherit;
	}

	.info-alert {
		p {
			margin: 0px;
		}
	}

	// Account Verification
	.account-verification-panel {
		margin-top: 56px;

		&.centered {
			max-width: 600px;
			margin: 56px auto 0px;
		}

		.account-options {
			display: flex;
			flex-direction: column;
			gap: 24px;
			margin: 49px auto 0px;
			max-width: 491px;

			&.grid {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 16px;
				max-width: 998px;
				margin-top: 40px;

				@media (max-width: 499px) {
					grid-template-columns: 1fr;
				}
			}

			&__option-card {
				border-radius: 15px;
				background-color: $white;
				padding: 17px 16px 18px;
				display: flex;
				// align-items: center;
				gap: 16px;
				justify-content: space-between;
				cursor: pointer;
				min-height: 150px;

				&.loading {
					cursor: not-allowed;
				}

				h6 {
					font-size: 16px;
					font-weight: 600;
					color: $primary;
					line-height: 1.6;
				}

				p {
					font-size: 16px;
					color: #9e9e9e;
					font-weight: 300;
					max-width: 400px;
				}

				&::after {
					content: "";
					flex-shrink: 0;
					width: 10px;
					height: 15px;
					background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxMCAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuNjc0MjI3IDE0LjM2NkMwLjQ2ODc3OSAxNC41NTA5IDAuMzUzMzU5IDE0LjgwMTcgMC4zNTMzNTkgMTUuMDYzMkMwLjM1MzM1OSAxNS4zMjQ4IDAuNDY4Nzc5IDE1LjU3NTYgMC42NzQyMjcgMTUuNzYwNUMwLjg3OTY3NiAxNS45NDU0IDEuMTU4MzIgMTYuMDQ5MyAxLjQ0ODg3IDE2LjA0OTNDMS43Mzk0MiAxNi4wNDkzIDIuMDE4MDcgMTUuOTQ1NCAyLjIyMzUyIDE1Ljc2MDVMOS41MTQyOCA5LjE5OEM5LjYxNjI0IDkuMTA2NTQgOS42OTcxMyA4Ljk5Nzg4IDkuNzUyMzMgOC44NzgyMkM5LjgwNzUyIDguNzU4NTcgOS44MzU5NCA4LjYzMDI5IDkuODM1OTQgOC41MDA3M0M5LjgzNTk0IDguMzcxMTcgOS44MDc1MiA4LjI0Mjg5IDkuNzUyMzMgOC4xMjMyNEM5LjY5NzEzIDguMDAzNTggOS42MTYyNCA3Ljg5NDkyIDkuNTE0MjggNy44MDM0NkwyLjIyMzUyIDEuMjQwOTdDMi4wMTgwNyAxLjA1NjA0IDEuNzM5NDIgMC45NTIxNDggMS40NDg4NyAwLjk1MjE0OEMxLjE1ODMyIDAuOTUyMTQ4IDAuODc5Njc2IDEuMDU2MDQgMC42NzQyMjcgMS4yNDA5N0MwLjQ2ODc3OSAxLjQyNTg5IDAuMzUzMzU5IDEuNjc2NzEgMC4zNTMzNTkgMS45MzgyM0MwLjM1MzM1OSAyLjE5OTc2IDAuNDY4Nzc5IDIuNDUwNTcgMC42NzQyMjcgMi42MzU1TDcuMTg5NDQgOC40OTk5MUwwLjY3NDIyNyAxNC4zNjZaIiBmaWxsPSIjOUU5RTlFIi8+Cjwvc3ZnPgo=")
						no-repeat;
					background-size: contain;
					display: flex;
					align-items: center;
					justify-content: center;
					align-self: center;
				}
			}
		}

		.completion-guardlines {
			width: 100%;
			max-width: 497px;
			margin: 41px auto 24px;
			background-color: $white;
			border-radius: 20px;
			padding: 24px 27px;
			display: flex;
			flex-direction: column;
			gap: 12px;

			@media (max-width: 767px) {
				padding: 20px 16px;
			}

			> p {
				margin: 0px;
				color: $primary;
				font-size: 16px;
				font-weight: 300;
			}
		}

		.completion-download-buttons {
			display: flex;
			justify-content: center;
			margin-top: 32px;
			gap: 12px;
			flex-wrap: wrap;

			a {
				width: fit-content;
				height: fit-content;

				&:last-of-type {
					img {
						width: 148.5px;
					}
				}

				img {
					object-fit: contain;
					height: 44px;
					width: 132px;
				}
			}
		}

		.completion-footer-text {
			margin: 0px;
			margin-top: 32px;
			font-size: 16px;
			line-height: 32px;
			text-align: center;
			color: $primary;
			font-weight: 300;
		}
	}
}
