.switch-modes {
    position: fixed;
    top: 80%;
    right: 90%;
    display: block;
    width: 60px;
    height: 60px;
    background: #333333;
    box-shadow: 0px 5px 65px 18px rgba(0, 0, 0, 0.21);
    border-radius: 50%;
    z-index: 2147483647;
    transition: all 1.2s;
}

.switch-modes.midnight {
    background: var(--main-fill-normal);
    box-shadow: 4px 4px 18px 3px rgba(208, 162, 60, 0.47);
}
