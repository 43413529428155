:root {
	/* PRMARY COLORS*/
	--main-fill-normal: #d1a23c;
	--main-fill-light: #5ceea7;
	--mint-underlining: #d1a23c;
	--mint-midnight: #000000;
	/* SECONDARY COLORS */
	--sub-fill-normal: #070345;
	--sub-fill-dark: #77502f;
	--sub-fill-light: #d1a23c;
	/* GRAY SCALE */
	--gray-fill-normal: #37474f;
	--gray-fill-light: #69747a;
	/* OTHERS */
	--mint-dark-blue: #d1a23c;
	--mint-fill-darker: #d1a23c;
	--page-title-text: #dfdfdf;
	--page-lead-text: #808080;
}

@font-face {
	font-family: "FontAwesome";
	src: url(../fonts/FontAwesome.otf) format("otf");
}

html {
	min-height: 100%;
	position: relative;
	scroll-behavior: smooth;
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
}

body {
	font-family: "TG-Light";
	background: white;
	height: 100%;
}

html._fixed,
body._fixed {
	touch-action: none;
	-webkit-overflow-scrolling: none;
	overflow: hidden;
	overscroll-behavior: none;
}

b,
strong {
	font-family: "TG-Medium";
	font-weight: bolder;
}

main.full-height {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-x: hidden;
	z-index: -1;
}

.btn,
.pointer {
	cursor: pointer;
}

/* Errors */

.error-text {
	font-size: 13px;
	letter-spacing: 0.25px;
	color: #804c4c;
}

.error-text a {
	font-weight: bolder;
	color: #9d1e1e;
}

#mintLogo {
	width: 30%;
}

.mintBank {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}

.mintBank.midnight {
	background: #000000;
}

.mintBank .biz-reg-link {
	width: 100%;
	height: 88px;
	display: flex;
	justify-content: flex-end;
	position: fixed;
	z-index: 100;
	padding: 24px 77px;
	left: 0;
	top: 0;
}

@media (max-width: 767px) {
	.mintBank .biz-reg-link {
		justify-content: center;
		align-items: center;
		padding: 24px 16px;
	}
}

.mintBank .biz-reg-link > a {
	width: auto;
	height: 40px;
	font-size: 12px;
	text-transform: none;
	padding: 8px 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
}

.cls-1 {
	fill: #231f20 !important;
}

.mintBank.midnight .cls-1,
.logoWhite .cls-1 {
	fill: #ffffff !important;
}

.mt-5x {
	margin-top: 6rem;
}

.mt-45 {
	margin-top: 2rem;
}

.btn:focus {
	outline: 0;
	box-shadow: none;
}

.not-active {
	pointer-events: none;
	cursor: no-drop;
	text-decoration: none;
	color: grey;
}

.mint-sign {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-x: hidden;
	z-index: -1;
	background-image: url(../img/faint-logo.svg);
	background-repeat: no-repeat;
	background-position: 102% 104%;
	background-size: 250.67px 188px;
	background-attachment: fixed;
}

/* Mint Center Logo */

.center-logo {
	text-align: center;
	margin: 4rem 0rem;
	margin-bottom: 3rem;
	min-height: 67px;
}

.center-logo img {
	width: 30%;
}

/*  Forms */

/* Sign up and Login Styles */

.low-form .form-group {
	margin-bottom: 25px;
}

.low-form label {
	color: var(--gray-fill-normal);
	font-family: "TG-Medium";
	font-size: 13.5px;
	line-height: 21px;
	letter-spacing: 0.15px;
	text-transform: capitalize;
}

.low-form span.input-group-text {
	background: #fafafa;
	color: #36474f;
	font-size: 14px;
	/* border: 1px solid red; */
	border-radius: 15px 0px 0px 15px;
	border: 1px solid #eaecf0;
}

.low-form .form-control,
.form-control.primary {
	font-size: 13px;
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #fafafac2;
	border: 1px solid #eaecf0;
	border-radius: 15px;
	box-shadow: none;
	outline: none;
	transition: all 0.3s linear;
	-webkit-appearance: none;
}

.low-form .form-control:focus,
.form-control.primary:focus {
	background-color: #ffffff;
	border-color: var(--sub-fill-dark);
}

.form-control.has-error {
	border-color: #a94442 !important;
}

.form-control.money {
	font-family: "TG-Medium";
	font-size: 15px;
}

.form-control.has-error:focus {
	border-color: #a94442 !important;
}

.form-control.has-error::placeholder {
	color: #a94442 !important;
}
.react-select__control {
	border-radius: 15px !important;
}
.react-select__menu {
	z-index: 2 !important;
}

.bolder {
	font-weight: bolder;
}

span.error-msg {
	color: #a94442 !important;
	font-size: 12px;
	margin-top: 8px;
}

.form-control.user-icon {
	background-image: url(../img/user-icon.svg);
	background-repeat: no-repeat;
	background-position: center right 0.75rem;
	background-size: 14px 14px;
}

.form-control.search {
	background-image: url(../img/search.svg);
	width: 100%;
	background-repeat: no-repeat;
	background-position: center left 0.75rem;
	background-size: 20px 20px;
	padding-left: 45px;
}

.form-control.search:focus {
	background-color: #fafafa;
	border: 1px solid #eaecf0;
}

.form-control.mail {
	background-image: url(../img/mail.svg);
	background-repeat: no-repeat;
	background-position: center right 0.75rem;
	background-size: 14px 14px;
}

.form-control.lock {
	background-image: url(../img/lock.svg);
	background-repeat: no-repeat;
	background-position: center right 0.75rem;
	background-size: 14px 14px;
}

.btn.edit-dets {
	width: 100%;
	padding: 5px;
	font-family: TG-Medium;
	color: white;
	font-size: 14px;
	text-align: center;
	background: var(--main-fill-normal);
	box-shadow: 0px 5px 65px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
}

/* NavTab */

#myTab.nav-tabs .nav-item {
	width: 50%;
	text-align: center;
}

#myTab.nav-tabs .nav-link {
	font-family: "TG-Medium";
	color: var(--gray-fill-normal);
	border: 2px solid transparent;
	letter-spacing: 0.15px;
	font-size: 15px;
}

.mintBank.midnight #myTab.nav-tabs .nav-link {
	color: rgba(255, 255, 255, 0.8);
}

#myTab.nav-tabs .nav-link.active {
	font-family: "TG-Medium";
	color: var(--mint-underlining) !important;
	background-color: transparent;
	border-color: transparent transparent var(--mint-underlining) !important;
}

#myTab.nav-tabs .nav-link:focus,
#myTab.nav-tabs .nav-link:hover {
	color: var(--gray-fill-light);
	border-color: transparent;
}

.absolute-center {
	height: 100vh;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
}

/* Custom Check box */

.specialLink {
	color: #d0a23b !important;
	text-decoration: none;
}

.custom-control-label {
	line-height: 18px !important;
	color: #37474f !important;
	letter-spacing: 0.25px;
	font-weight: 900;
	/* font-family: 'TG-Light' !important; */
	font-size: 13px !important;
}

.custom-control-label span {
	margin-top: 2px;
}

.custom-control-label:before {
	top: 0px !important;
	background: transparent;
	border-color: var(--main-fill-normal) !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	/* border-color: var(--main-fill-normal) ; */
	background-color: transparent;
	border-color: red;
	display: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background: transparent url("../img/checked.svg") no-repeat;
	content: "";
	z-index: 1;
	border: 0;
	border-color: transparent;
}

.custom-control-label::after,
.custom-control-label::before {
	top: 0.15rem;
}

.custom-control-label::after {
	position: absolute;
	top: -0.01rem !important;
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none;
}

/* COMPLETE WAVE STYLING AND ANIMATION */

@keyframes move_wave {
	0% {
		transform: translateX(0) translateZ(0) scaleY(1);
	}
	50% {
		transform: translateX(-10%) translateZ(0) scaleY(0.55);
	}
	70% {
		transform: translateX(-5%) translateZ(0) scaleY(0.75);
	}
	100% {
		transform: translateX(0) translateZ(0) scaleY(1);
	}
}

.waveWrapperInner {
	position: absolute;
	width: 100%;
	overflow: hidden;
	height: 200px;
	top: -100px;
}

.bgTop {
	/* z-index: 15; */
	opacity: 0.5;
}

.bgMiddle {
	/* z-index: 10; */
	opacity: 0.75;
}

.bgBottom {
	/* z-index: 5; */
}

.wave {
	position: absolute;
	left: 0;
	width: 200%;
	height: 100%;
	background-repeat: repeat no-repeat;
	background-position: 0 bottom;
	transform-origin: center bottom;
}

.waveTop {
	background-image: url(../img/mi-wave.svg);
	background-size: auto;
}

.waveAnimation .waveTop {
	animation: move-wave 3s;
	-webkit-animation: move-wave 3s;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.waveBottom {
	background-image: url(../img/mi-wave.svg);
	background-size: auto;
}

.waveAnimation .waveBottom {
	animation: move_wave 30s linear infinite;
}

/* END OF WAVES ANIMATION */

/* GOOD IMAGE IN CENTER */

img.check-good {
	display: block;
	margin: auto;
	margin-bottom: 20px;
}

/* Verify Account Center Box Style */

.center-center {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100%;
}

div.panel.verify-account {
	height: 825px;
	padding: 6% 0;
	background: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	overflow-x: hidden;
}

div.verif-center h6 {
	text-align: center;
	text-transform: capitalize;
	font-family: TG-Medium;
	font-style: normal;
	font-size: 22px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.25px;
	color: #37474f;
	margin-bottom: 0px;
}

.step-box {
	padding: 25px 65px;
	border-radius: 4px;
	margin-bottom: 20px;
	border: 1px solid #faf5eb;
}

.step-box p {
	color: #37474f;
	font-family: "TG-Medium";
	font-size: 13px;
	font-weight: 100;
	letter-spacing: 0.25px;
	margin-bottom: 0px;
}

.step-box.light-green {
	background: linear-gradient(0deg, #e2fcef, #e2fcef);
}

.step-box.deep-yellow {
	background: linear-gradient(0deg, #ffebb7, #ffebb7);
}

.step-box.dead-skyblue {
	background: linear-gradient(0deg, #d1e6ef, #d1e6ef);
}

.all-fet {
	font-size: 14px;
	line-height: 26px;
	text-align: center;
	letter-spacing: 0.25px;
	color: #37474f;
}

.all-fet b.cPhone {
	color: #d1a23c;
	letter-spacing: 2.5px;
}

.desct {
	margin: 10px 0px;
	width: 100%;
	background: rgb(248, 243, 233);
	padding: 10px;
}

.desct.error {
	background: #f8d7daa8;
	/* text-transform: uppercase; */
}

.desct.error p {
	color: #721c24a1;
}

.desct p {
	text-align: center;
	margin-bottom: 0px;
	font-weight: 500;
	font-size: 13px;
	line-height: 18px;
	color: #37474f;
}

.bottom_links {
	margin-top: 20px;
	text-align: center;
}

.bottom_links a {
	font-family: "TG-Medium";
	/* font-weight: 500; */
	font-size: 12px;
	line-height: 21px;
	text-align: center;
	color: var(--mint-dark-blue);
	padding: 0px 10px;
}

/*--------------------------------- 
-----------------------------------
-----------------------------------
--------BREADCRUMB ---------------
-----------------------------------
-----------------------------------
----------------------------------*/

h6.blon {
	text-align: center;
	font-family: "TG-Medium";
	/* font-weight: 600; */
	font-size: 23px;
	line-height: 30px;
	text-align: center;
	letter-spacing: 0.02em;
	color: var(--gray-fill-normal);
	margin-bottom: 25px;
	margin-top: 10px;
}

.hold-breadcrumbs {
	background: #f8f3e9;
	padding: 10px 0px;
	width: 100%;
}

.breadcrumb-container {
	position: relative;
	margin-right: 1rem;
	margin-bottom: 0;
	margin-left: 1rem;
	display: flex;
	justify-content: center;
	padding-left: 0;
	list-style-type: none;
}

/* .breadcrumb-container:before {
    content:url('../img/dots.svg'); 
    position: absolute;
    height: 2px;
    width: calc(100% - 10px);
    top: 18px;
    left: 5px;
    background-color:#C4C4C4;
} */

.breadcrumb-container li {
	z-index: 1;
	display: flex;
	align-items: center;
}

.breadcrumb-container > li:not(:last-child):after {
	display: block;
	content: "";
	width: 23px;
	height: 5px;
	background: transparent url("../img/dots.svg") no-repeat;
	z-index: 1;
	margin: 0px 30px;
}

.breadcrumb-container .steps {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;
}

.breadcrumb-container .step-number {
	width: 2rem;
	height: 2rem;
	display: flex;
	justify-content: center;
	color: var(--gray-fill-normal);
	align-items: center;
	font-size: 14px;
	font-family: "TG-Medium";
	text-align: center;
	background-color: transparent;
	border: 1.5px solid var(--gray-fill-normal);
	border-radius: 1rem;
}

.step-number i {
	font-size: 12px !important;
}

.breadcrumb-container .active .step-number,
.breadcrumb-container .done .step-number {
	background-color: var(--mint-dark-blue);
	color: #fff;
	border: 2px solid var(--mint-dark-blue);
}

/* .breadcrumb-container .step-text {
    display: none;
}

@media(min-width: 568px) {
    .breadcrumb-container .step-text {
        display: flex;
        color: var(--gray-fill-normal);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.02em;
        max-width: 5rem;
        padding-top: 10px;
    }
} */

/*--------------------------------- 
-----------------------------------
-----------------------------------
--------CSS TRASITION GROUPS -----
-----------------------------------
-----------------------------------
----------------------------------*/

/* START OF HOME VIEWS TRANSITION */

.home-views-appear {
	opacity: 0;
}

.home-views-appear.home-views-appear-active {
	opacity: 1;
	transition: opacity 2s ease-in;
}

.home-views-enter {
	opacity: 0;
}

.home-views-enter.home-views-enter-active {
	opacity: 1;
	transition: opacity 1s ease-in;
}

.home-views-leave {
	opacity: 1;
}

.home-views-leave.home-views-leave-active {
	opacity: 0;
	transition: 1s ease-in;
}

/* END OF HOME VIEWS TRANSITION */

/* STEP FORMS */

.small-text {
	margin-top: 20px;
	color: var(--gray-fill-normal);
	font-size: 13px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0.25px;
	text-transform: capitalize;
}

/* CUSTOM RADIO BUTTONS */

/* The check-container */

.flex-check {
	display: flex;
	flex-direction: row;
}

.check-container {
	flex: 50%;
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	margin-bottom: 0px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.check-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

.mycheck {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 42px;
	font-size: 13px;
	line-height: 13px;
	text-align: center;
	background-color: #ffffff;
	border: 1px solid var(--sub-fill-light);
}

.flex-check .check-container:first-child .mycheck {
	border-radius: 15px 0px 0px 15px;
	border-right: 0px;
}

.flex-check .check-container:last-child .mycheck {
	border-radius: 0px 15px 15px 0px;
	border-left: 0px;
}

/* 
.mycheck.left{
    border-radius: 4px 0px 0px 4px;
    border-right: 0px;
}
.mycheck.right{
    border-radius: 0px 4px 4px 0px;
    border-left: 0px;
} */

/* On mouse-over */

.check-container:hover input ~ .mycheck {
	background-color: #f8f3e9;
}

/* Checked Input field */

.check-container input:checked ~ .mycheck {
	background-color: var(--mint-dark-blue) !important;
	border-color: var(--mint-dark-blue) !important;
	color: #ffffff;
}

/* Custom File and Capture Toggle */

/* custom input file stylining */

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
	text-align: center;
	width: 100%;
	height: 100%;
}

.upload-btn-wrapper input[type="file"] {
	/* font-size: 100px; */
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	height: 100%;
	width: 100%;
	cursor: pointer;
}

.file-container {
	border: 1px solid #e8f1f8;
	background: transparent;
	border-radius: 4px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.file-container p.text-center {
	font-size: 14px;
	font-weight: bolder;
	font-size: 14px;
	line-height: 21px;
	text-align: center;
	letter-spacing: 0.35px;
	color: #d1a23c;
	margin-top: 10px;
	margin-bottom: 0px;
}

p.has {
	margin-top: 1.2rem;
}

.sweet {
	font-family: TG-Medium;
	font-weight: 600;
	font-size: 16px;
	text-align: center;
	letter-spacing: 0.25px;
	color: #d0a23b;
	margin-bottom: 0px;
}

.my-deet {
	font-weight: normal;
	font-size: 14px;
	line-height: 21px;
	display: flex;
	letter-spacing: 0.02em;
	color: #37474f;
}

/* Small Effects */

.fadeOut {
	opacity: 0;
	transition: opacity 0.5s;
}

.fadeIn {
	opacity: 1;
	transition: opacity 0.5s 0.5s;
}

.blue-link {
	font-family: "TG-Medium";
	font-size: 13.5px;
	line-height: 21px;
	letter-spacing: 0.25px;
	text-align: center;
	color: var(--mint-dark-blue);
}

.blue-link:hover {
	text-decoration: none;
	color: var(--mint-dark-blue);
}

.harbour {
	/* font-family: "TG-Medium"; */
	/* font-weight: 900; */
	color: var(--gray-fill-normal);
	font-size: 13px;
	letter-spacing: 0.25px;
	text-align: left;
	font-style: italic;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker__input-container {
	width: 100%;
	position: relative;
}

.react-datepicker__input-container::after {
	content: "";
	background: url("../img/date_input.png") no-repeat center;
	background-size: contain;
	width: 18px;
	height: 18px;
	position: absolute;
	top: 50%;
	right: 0.75rem;
	transform: translateY(-50%);
}

span.error {
	color: #a94442;
	font-size: 12px;
	font-weight: bold;
}

@media (max-width: 768px) {
	#myTab.nav-tabs .nav-link {
		font-size: 14px;
	}
	.low-form .form-control,
	span.input-group-text {
		font-size: 13px;
	}
	.blue-link {
		font-size: 12px;
	}
	.low-form label {
		font-size: 13px;
	}
	.btn.withAction {
		font-size: 12px !important;
	}
}

@media (max-width: 587px) {
	.breadcrumb-container {
		justify-content: space-evenly;
	}
	.breadcrumb-container > li:not(:last-child):after {
		display: none;
	}
}

@media (max-width: 375px) {
	#myTab.nav-tabs .nav-link {
		font-size: 13px;
	}
	.low-form label {
		font-size: 12.5px;
	}
	.low-form .form-control,
	span.input-group-text {
		font-size: 12.5px;
	}
}

.flex-star {
	display: flex;
	justify-content: center;
	align-items: center;
}

.regComplete {
	height: 100%;
	width: 100%;
	padding: 50px 0;
	background: #ffffff;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #dffbee;
	/* background: #dffbee; */
}

.regComplete h1.body-dd {
	font-weight: 600;
	font-size: 20px;
	text-align: center;
	letter-spacing: 0.35px;
	color: #37474f;
	text-transform: uppercase;
}

.regComplete p {
	margin-top: 8px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	text-align: center;
	letter-spacing: 0.02em;
	color: #69747a;
}

.regComplete i {
	font-size: 14px;
	letter-spacing: 0.25px;
}

.gray-out {
	opacity: 0.6;
	cursor: not-allowed;
}

.gray-out:hover {
	cursor: not-allowed;
}

#grass.dropdown-menu {
	margin-top: 5px;
	left: -110px;
	border: 0px;
	border-radius: 0px;
}

#grass.dropdown-menu .dropdown-item {
	padding: 0.8rem 1rem;
	font-size: 13px;
	letter-spacing: 0.2px;
	/* font-family: TG-Medium; */
	font-weight: 900;
}

/* No more parties in LA */

input::disabled,
button:disabled {
	cursor: not-allowed !important;
	pointer-events: all !important;
}

.gray-out input {
	pointer-events: none;
}

img.myIdenti:hover {
	opacity: 0.8 !important;
	cursor: pointer !important;
}

.btn-camera {
	background: #0b0909;
	color: #ffffff !important;
	border: 0px;
	outline: none;
	box-shadow: none;
	font-size: 14px;
	letter-spacing: 0.25px;
}

.threeScale {
	transform: scale(1.15);
}

.twoScale {
	transform: scale(2);
}
.flex__between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.flex__center {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Toasts */
.right-toast {
	z-index: 3000;
	top: 20px;
	right: 20px;
	position: fixed;
}

.left-toast {
	z-index: 3000;
	top: 20px;
	left: 20px;
	position: fixed;
}

/* Modal */
.modal-header ~ div {
	overflow-y: auto;
}

.modal-content > div {
	overflow-y: auto;
}

.hide-captcha {
	display: none !important;
}
