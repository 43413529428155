@import "./../../../../../../../assets/scss/master";

.balance-box {
	/* height: 117px; */
	padding: 22px 30px;
	width: 100%;
	background: url(https://res.cloudinary.com/dswxtf2ay/image/upload/v1582742829/Mint_InternetBanking/faint-logo_kwq5gj.svg)
			no-repeat center right,
		linear-gradient(96.81deg, #77502f -31.12%, $main-fill-normal 109.6%);
	background-size: contain;
	box-shadow: 2px 1px 20px #c79a3b6b;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	min-height: 223px;
}

.btn.fundAcc-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 20px;
	text-align: center;
	background: #1a1919;
	border-radius: 9px;
	font-weight: bolder;

	svg.plusMe {
		circle,
		path {
			stroke: $white;
		}
	}

	span {
		color: $white;
		font-weight: 900;
		font-size: 14px;
		line-height: 21px;
	}
}

.ledger-balance {
	h6 {
		color: $white;
		font-weight: 500;
	}

	h4 {
		color: $white;
		font-weight: 400;
	}
}

.balance-toggle {
	display: flex;
	align-items: center;
	width: fit-content;
	margin-left: auto;

	@media (max-width: 575px) {
		margin-left: unset;
		margin-bottom: 16px;
	}

	> img {
		width: 20px;
		height: 20px;
	}

	> span {
		margin-left: 10px;
		font-size: 14px;
		color: $white;
	}
}
