@import "../../../assets/scss/newDesignStyles/general/variables.scss";

.custom-check-box-container {
	width: 100%;

	> label {
		font-size: 16px;
		width: fit-content;
		font-weight: 500;
	}
}

.custom-check-box {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;

	.custom-check-box-input {
		display: flex;
		align-items: center;

		label {
			margin: 5px 7px 0 0 !important;
		}
	}

	input {
		height: 20px;
		width: 20px;
		padding: 0;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		appearance: none;
		border-radius: 50%;
		outline: none;
		transition-duration: 0.3s;
		cursor: pointer;
		background-color: inherit;
		border: 1.5px solid #9e9e9e;

		&:checked {
			border-color: #3eed00;
		}

		&:checked + span::before {
			content: "\2713";
			display: block;
			text-align: center;
			color: #3eed00;
			position: absolute;
			left: -25px;
			top: -8px;
			font-size: 0.7rem;
		}
	}

	span {
		position: relative;
		font-weight: 600;
		margin-left: 10px;
		// margin-top: 2px;
		font-size: 1.2rem;
		color: #3eed00;
	}
}
