@import "../../../../../../assets/scss/master";

.topup-success-modal {
	display: flex;
	flex-direction: column;
	min-height: 332px;
	padding: 41px 16px;
	text-align: center;
	align-items: center;

	> h2 {
		color: $black;
		font-size: 14px;
		font-family: TG-Medium;
		margin: 29px 0px 0px;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	> p {
		font-size: 12px;
		line-height: 20px;
		color: $black;
		max-width: 308px;
		margin-top: 10px;
		#{$modalMidNightselectors} {
			color: $white;
		}
	}

	.topup-success-modal-body {
		margin-top: 24px;
		width: 100%;
		max-width: 340px;

		.topup-success-modal-info {
			display: flex;
			justify-content: space-between;
			padding-bottom: 12px;
			padding-top: 12px;
			border-bottom: 1px solid #eee;
			#{$modalMidNightselectors} {
				border-color: #333333;
			}

			&:first-of-type {
				padding-top: 0;
			}

			&.no-border {
				border: none;
				margin-top: 24px;
				padding-bottom: 0px;

				span {
					display: flex;
					align-items: center;
					line-height: 0;
					color: var(--main-fill-normal) !important;
					font-family: TG-Medium;

					#{$modalMidNightselectors} {
						color: var(--main-fill-normal) !important;
					}
				}
			}

			span {
				color: #1b1b1b;
				font-size: 13px;
				display: block;
				#{$modalMidNightselectors} {
					color: #fff !important;
				}

				&:last-of-type {
					text-align: right;
				}
			}
		}
	}

	> button {
		text-transform: none !important;
		max-width: 304px;
		height: 50px;
	}
}
