@import "../../../../../assets/scss/master";

.ft-form {
	width: 100%;
	max-width: 520px;
	margin: 0 auto;
	padding: 0px 16px;

	.select-beneficiary-button {
		height: 50px;
		font-size: 14px;
		background-color: transparent;
		border: 1px solid #eee;
		border-radius: 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.375rem 0.75rem;
		color: #000;
		#{$midNightselectors} {
			color: #fff;
			background-color: #000;
			border-color: #333333;
		}

		> img {
			flex-shrink: 0;
			width: 14px;
			height: 14px;
			filter: brightness(0);
			#{$midNightselectors} {
				filter: unset;
			}
		}
	}

	label {
		color: #000;
		font-size: 14px;

		#{$midNightselectors} {
			color: #fff;
		}

		&.other-bank-label {
			#{$midNightselectors} {
				color: #bbbbbb;
			}
		}
	}

	.ft-currency {
		position: absolute;
		color: $black;
		left: 12px;
		top: 50%;
		transform: translateY(-50%);
		font-size: 14px;
		font-family: TG-Light;
		#{$midNightselectors} {
			color: $white;
		}
	}

	input {
		font-size: 14px !important;
		margin-bottom: 0px;

		&.ft-recipient-input {
			height: 50px;

			&::placeholder {
				#{$midNightselectors} {
					color: var(--page-lead-text) !important;
					font-family: TG-Light;
				}
			}
		}

		#{$midNightselectors} {
			color: #fff !important;
			background-color: $dark-input !important;
			border-color: #333333 !important;
		}
	}

	.react-select__control {
		height: 50px;
		outline: none;
		font-size: 14px;

		#{$modalMidNightselectors} {
			border-color: $dark-input;
		}
	}

	.ft-submit-btn {
		text-transform: none !important;
		height: 50px;
	}

	.spending-tags-lead {
		font-size: 10px;
		line-height: 16px;
		max-width: 311px;
		display: block;
		margin-bottom: 8px;
		#{$modalMidNightselectors} {
			color: #fff;
		}
	}

	.add-as-beneficiary {
		background-color: transparent;

		> .flex-star {
			justify-content: flex-start;

			label {
				color: #000;
				font-family: TG-Light;
				#{$modalMidNightselectors} {
					color: #fff !important;
				}
			}

			.custom-control-label:before {
				#{$modalMidNightselectors} {
					border-color: #fff !important;
				}
			}

			.custom-control-input:checked ~ .custom-control-label::before {
				#{$modalMidNightselectors} {
					background-color: transparent !important;
				}
			}
		}
	}
}
