@import "../../../../../../../assets/scss/master";

.mixed-chart {
	background: $white;
	width: 100%;
	padding: 55px 0;
	padding-bottom: 0px;
	display: flex;
	justify-content: center;
	// overflow-x: scroll;
	#{$midNightselectors} {
		background: $black-medium;
		box-shadow: 0px 0px 4px $black;
	}
	::-webkit-scrollbar {
		width: 2px !important;
		/* background: #d3e8de; */
	}
}

.apexcharts-text tspan {
	#{$midNightselectors} {
		fill: $white;
		fill-opacity: 0.8;
	}
}

.income-expenses-card-header {
	height: 50px !important;
	margin-bottom: 0px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	// border-bottom: 1px solid #eee;
	background: $white;
	border-radius: 3px 3px 0px 0px;
	#{$midNightselectors} {
		background: $black-medium;
		// border-bottom: 1px solid #333;
	}

	> h4 {
		color: var(--main-fill-normal) !important;
	}
}
