@import "../../../../../../../../../assets/scss/master";

.transcation-table {
	tr {
		td {
			padding: 20px;
			border-color: transparent;
		}
	}
}

.trans-dsc {
	p {
		font-family: "TG-Medium";
		font-size: 14px;
		text-transform: capitalize;
		line-height: 21px;
		letter-spacing: 0.02em;
		color: #37474f;
		margin-bottom: 0px;
		#{$midNightselectors} {
			color: $white;
			opacity: 0.85;
		}
	}

	span {
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.02em;
		color: #828282;
	}
}

.trans-res {
	font-family: "TG-Medium";
	font-weight: 100;
	font-size: 13px;
	line-height: 24px;
	letter-spacing: 0.02em;
	color: #4abe86;
	text-align: right;

	&.profit {
		color: $credit;
	}

	&.loss {
		color: $debit;
	}
}
