@import "../scss/master";

#BigsidebarCollapse,
#sidebarCollapse {
	padding: 10px !important;
	outline: none !important;
	span {
		display: block;
		background-color: $black;
		height: 2px;
		width: 24px;
		margin-top: 4px;
		margin-bottom: 4px;
		position: relative;
		left: 0;
		opacity: 1;
		#{$midNightselectors} {
			background-color: $white;
		}
	}
}

#setTab {
	#{$midNightselectors} {
		border-color: #4f4b4c;
	}
}

#socials {
	a {
		width: fit-content;
		height: fit-content;

		svg {
			height: 24px;
			width: 24px;
		}
	}
}

#socials a svg path {
	fill: #37474f;
	#{$midNightselectors} {
		fill: $main-fill-normal;
	}
}

#beneficiary.table {
	thead tr th {
		#{$midNightselectors} {
			background: #393536;
			color: #d3d2d2;
		}
	}
	tbody tr td {
		color: #d3d2d2 !important;
	}
}

.pointer {
	cursor: pointer;
}
.text--primary {
	color: $black;
	#{$midNightselectors} {
		color: $main-fill-normal !important;
	}
}

.switch-account-container {
	background: $white;
	border-radius: 24px;
	padding: 8px;
	position: relative;

	#{$midNightselectors} {
		background: #1a1919;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
	}

	> img {
		width: 24px !important;
		height: 24px !important;
		object-fit: cover;
		border-radius: 18px;
	}

	&__account-info {
		font-size: 12px;
		font-weight: 500;
		color: #111;
		margin-left: 8px;
		font-family: TG-Medium;
		text-transform: capitalize;

		#{$midNightselectors} {
			color: $white;
		}

		> span {
			font-family: TG-Regular;
			color: #808080;
			// #{$midNightselectors} {
			// 	color: $gray;
			// }
		}
	}

	> .chevron-down {
		display: inline-block;
		width: 20px;
		height: 20px;
		background-color: #111;
		margin-bottom: 3px;
		margin-left: 8px;

		#{$midNightselectors} {
			background-color: $white;
		}
	}
}

.page-title-text {
	color: var(--mint-midnight);
	font-family: TG-Medium;
	font-size: 18px;
	margin-bottom: 4px;

	#{$midNightselectors} {
		color: var(--page-title-text);
	}
}

.page-lead-text {
	color: var(--gray-fill-normal);
	font-size: 12px;

	#{$midNightselectors} {
		color: var(--page-lead-text);
	}
}

.is-approval-warning {
	color: var(--gray-fill-normal);
	font-size: 16px;
	text-align: center;
	font-family: TG-Medium;
	max-width: 250px;
	margin: 0 auto;

	#{$midNightselectors} {
		color: var(--page-lead-text);
	}
}

.setup-modals-header {
	border-bottom: 1px solid #eee;
	#{$modalMidNightselectors} {
		border-bottom: 1px solid #272626;
	}

	> h6 {
		margin: 5px 0px 0px;
		font-size: 18px;
	}
}

.ft-currency {
	position: absolute;
	color: $black;
	left: 12px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 14px;
	font-family: TG-Light;

	#{$midNightselectors} {
		color: $white;
	}
}

.mintyn-logo-container {
	height: 90px;
}

.tooltip-inner {
	font-family: TG-Regular !important;
}
