@import "../../../../assets/scss/master";

.split-payment {
	width: 100%;

	#go-back {
		cursor: pointer;
		margin: 0 auto;
		display: flex;
		align-items: flex-start;
		border: none;
		max-width: 60px;
		margin-top: 20px;
		padding: 0px;
		font-size: 14px !important;
		font-weight: 500;

		#{$midNightselectors} {
			color: #d1a23c !important;
		}
	}

	.title-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		@media (max-width: 399px) {
			flex-direction: column;
		}

		button {
			max-width: 300px;
			height: 48px;
			text-transform: none;
			font-weight: 400;
		}
	}
	.request-empty-state {
		height: 50vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		button {
			margin-top: 32px;
			max-width: 300px;
			height: 48px;
			text-transform: none;
			font-weight: 400;
		}

		p {
			max-width: 296px;
			padding: 0;
			margin: 0;
		}
		.title {
			margin-top: 20px;
			font-family: TG-Semibold;
			font-weight: 600;
			font-size: 16px;

			#{$midNightselectors} {
				color: $white;
			}
		}
		.sub-title {
			margin-top: 16px;
			font-size: 14px;
			#{$midNightselectors} {
				color: #bbbbbb;
			}
		}
	}

	.split-rquests-panel {
		background: $white;
		border-radius: 8px;
		padding: 0px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #eee;
		min-height: 467px;
		width: 100%;

		#{$midNightselectors} {
			background: $black-medium;
			border: 1px solid #212121;
		}
		.order-history-card {
			align-items: center;
			display: flex;
			flex-direction: row;
			border: 1px solid #eee;
			width: 100%;
			max-width: 875px;
			height: auto;
			padding: 10px 40px;
			border-radius: 5px;
			margin-bottom: 10px;
			cursor: pointer;

			#{$midNightselectors} {
				background-color: $black-light;
				border: 1px solid #212121;
			}
			.details {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;

				div {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
				}

				.title {
					font-family: TG-Light;
					color: $black;
					font-weight: 500;
					font-size: 14px;

					#{$midNightselectors} {
						color: $white;
					}
				}
				.sub-title {
					font-family: TG-Light;
					color: $black;
					font-weight: 500;
					font-size: 12px;

					#{$midNightselectors} {
						color: $white;
					}
				}
				.icon {
					margin-right: 15px;
				}
				.status-badge {
					display: flex;
					padding-top: 1px;
					padding-left: 10px;
					padding-right: 10px;
					border-radius: 2px;
					height: 20px;
					font-weight: 500;
					font-size: 12px;
					font-family: TG-Light;
					margin-top: 4px;
				}

				.pending {
					background: #999999;
					color: $white;
				}

				.processing {
					background: #359be0;
					color: $white;
				}

				.delivered {
					background: #4abe86;
					color: $white;
				}

				.cancelled {
					background: #f24a51;
					color: $white;
				}
			}
			.amount {
				font-family: TG-Light;
				color: #d0a23c;
				font-weight: 600;
				font-size: 14px;
			}
		}

		.order-loading {
			margin-top: 32px;
			min-height: 300px;
		}
	}

	.split-rquests-container {
		&__header {
			padding: 24px 34px 23px 34px;
			border-bottom: 1px solid #eee;
			text-transform: capitalize;

			#{$midNightselectors} {
				border-color: $black-stroke;
			}

			h2 {
				margin: 0px;
				font-size: 16px;
				color: $black;
				font-family: TG-Semibold;

				#{$midNightselectors} {
					color: $white;
				}
			}
		}

		&__content {
			padding: 32px 36px 21px;
			flex: 1;

			@media (max-width: 500px) {
				padding: 20px 16px 21px;
			}

			.split-payment-form {
				max-width: 534px;
				margin: 0 auto;

				label {
					color: $black;
					font-family: TG-Light;
					font-size: 14px;

					#{$midNightselectors} {
						color: $white;
					}
				}

				.form-control {
					height: 50px;
					font-size: 14px;
				}

				.form-group {
					margin-bottom: 0px;
				}

				.split-request-input {
					height: 50px;
					font-size: 14px;
					margin-bottom: 0px;

					#{$midNightselectors} {
						background-color: $dark-input !important;
						color: $white !important;
						border-color: $border-medium !important;
					}
				}
				.btn-mint-default {
					border-radius: 15px !important;
					height: 50px !important;
					font-weight: 400;
					margin-top: 10px;
				}

				.split-options {
					display: flex;
					flex-direction: row;
					width: 100%;
					height: 50px;
					border: solid 1px;
					border-radius: 15px;
					border-color: #dfdfdf !important;
					justify-content: space-between;
					align-items: center;
					padding-left: 5px;
					padding-right: 5px;
					#{$midNightselectors} {
						background-color: $dark-input !important;
						color: $white !important;
						border-color: $border-medium !important;
					}
					button {
						padding: 0;
						box-shadow: none !important ;
						border: none;
						border-radius: 9px !important;
						max-width: 229px !important ;
						height: 38px !important;
						margin-top: 0px;
					}
					.in-active {
						background-color: $white;
						color: $black;

						#{$midNightselectors} {
							background-color: $dark-input !important;
							color: $white !important;
						}
					}
				}
			}

			.add-participants {
				max-width: 488px;
				margin: 0 auto;
				margin-top: 20px;
				> p {
					font-weight: 400;
					font-size: 14px;
					line-height: 17px;
					margin-bottom: 8px;
					#{$midNightselectors} {
						color: $white;
					}
				}
				.participants-card-container {
					display: flex;
					width: 528px;
					justify-content: space-between;
					flex-direction: row;
					margin-bottom: 32px;
					align-items: center;
					@media (max-width: 500px) {
						width: 100%;
						gap: 10px;
					}

					.delete {
						padding-top: 10px;
						height: 24px;
						width: 24px;
						cursor: pointer;
					}
				}
				.participants-card {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-start;
					width: 488px;
					padding-top: 20px;
					padding-bottom: 20px;
					padding-left: 16px;
					padding-right: 16px;
					border: 1px solid #dfdfdf;
					border-radius: 15px;

					@media (max-width: 500px) {
						width: 100%;
					}

					#{$midNightselectors} {
						color: $white;
						background: #1a1919;
						border-color: #1a1919;
					}
					.split-request-input {
						max-width: 164px;
						height: 36px;
						font-size: 14px;
						margin-bottom: 0px;
						border-radius: 7px;
						@media (max-width: 500px) {
							max-width: 100px;
						}

						#{$modalMidNightselectors} {
							background-color: $dark-input !important;
							color: $white !important;
							border-color: $border-medium !important;
						}
					}

					> div {
						justify-content: space-between;
						display: flex;
						width: 100%;
						margin: 0;
						flex-direction: row;
						font-size: 14px !important;

						#{$midNightselectors} {
							color: $white;
						}
						.name {
							margin: 0;
							font-weight: 600;
						}
						.amt {
							margin: 0;
							font-weight: 400;
						}
					}
					.user-state {
						margin: 0;
						margin-top: 8px;
						font-size: 14px;
						color: #d1a23c;
					}
				}

				.add-participants-btn {
					gap: 10px;
					margin-top: 30px;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: row;
					height: 50px;
					width: 100%;
					border-radius: 15px;
					border: 1px solid $main-fill-normal !important;
					background-color: transparent !important;
					font-size: 14px;
					font-weight: 500;
					color: $main-fill-normal;
				}
				button {
					margin-top: 90px;
					height: 50px;
					text-transform: none;
					font-weight: 400;
					border-radius: 15px;
				}

				label {
					color: $black;
					font-family: TG-Light;
					font-size: 14px;

					#{$midNightselectors} {
						color: $white;
					}
				}
			}

			.manage-participants {
				max-width: 488px;
				margin: 0 auto;
				margin-top: 20px;

				@media (max-width: 500px) {
					width: 100%;
				}
				> p {
					font-weight: 400;
					font-size: 14px;
					line-height: 17px;
					margin-bottom: 8px;
					color: #808080;

					#{$midNightselectors} {
						color: #bbbbbb;
					}
				}
				button {
					border-radius: 15px !important;
					height: 50px !important;
					font-weight: 400;
					margin-top: 32px;
					text-transform: none;
				}
				.participants {
					width: 100%;
					border-radius: 15px;
					padding-top: 23px;
					padding-bottom: 22px;
					padding-left: 16px;
					padding-right: 16px;
					margin-bottom: 10px;

					border: 1px solid $main-fill-normal;
					border-radius: 15px;

					#{$midNightselectors} {
						color: $white;
						background: #1a1919;
						border-color: #1a1919;
					}
					.content {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						margin-bottom: 8px;

						p {
							margin: 0;
						}
						.name {
							font-family: TG-Semibold;
							font-size: 14px;
							#{$midNightselectors} {
								color: $white;
							}
						}
						.amt {
							font-weight: 400;
							font-size: 14px;
							#{$midNightselectors} {
								color: $white;
							}
						}
					}
					.status {
						margin-bottom: 16px;
						font-size: 14px;
						margin: 0;
						color: #d1a23c;
					}
				}

				.remind-btn {
					margin-top: 30px;
					height: 50px;
					width: 100%;
					border-radius: 15px;
					border: 0px solid !important;
					background: rgba(209, 162, 60, 0.3);
					font-size: 14px;
					font-weight: 700;
					color: #d1a23c;
					text-transform: none;
				}
				.delete-btn {
					margin-top: 20px;
					height: 50px;
					width: 100%;
					border-radius: 15px;
					border: 0px solid !important;
					background: rgba(204, 0, 51, 0.3);
					font-size: 14px;
					font-weight: 700;
					color: #cc0033;
					text-transform: none;
				}

				.participants-summary {
					max-width: 488px;
					margin: 0 auto;
					margin-top: 32px;

					@media (max-width: 500px) {
						width: 100%;
					}
					> p {
						font-weight: 400;
						font-size: 14px;
						line-height: 17px;
						margin-bottom: 8px;
						color: #808080;

						#{$midNightselectors} {
							color: #bbbbbb;
						}
					}
					.participants {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						border-radius: 15px;
						padding-top: 23px;
						padding-bottom: 22px;
						padding-left: 16px;
						padding-right: 16px;
						min-height: 375px;

						border: 1px solid $main-fill-normal;
						border-radius: 15px;

						#{$midNightselectors} {
							color: $white;
							background: #1a1919;
							border-color: #1a1919;
						}

						.content {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							margin-bottom: 0px;
							p {
								font-size: 14px;
								margin: 0;
							}
							.desc {
								#{$midNightselectors} {
									color: #c4c4c4;
								}
							}
							.title {
								color: #808080;
								#{$midNightselectors} {
									color: #808080;
								}
							}
							.details {
								.status {
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
									padding: 0 8px;
									min-width: 46px;
									height: 24px;
									background: #f3f3f3;
									border-radius: 100px;
									font-size: 12px;

									#{$midNightselectors} {
										background: #282727;
									}
								}
								.sent {
									color: $black;
									#{$midNightselectors} {
										color: $white;
									}
								}
								.active {
									color: #d1a23c;
								}
								.completed {
									color: #09bf67;
								}
							}
						}
					}

					.pending-participants {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						border-radius: 15px;
						padding-top: 23px;
						padding-bottom: 22px;
						padding-left: 16px;
						padding-right: 16px;
						min-height: 273px;

						border: 1px solid $main-fill-normal;
						border-radius: 15px;

						#{$midNightselectors} {
							color: $white;
							background: #1a1919;
							border-color: #1a1919;
						}

						.content {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							margin-bottom: 0px;
							p {
								font-size: 14px;
								margin: 0;
							}
							.desc {
								#{$midNightselectors} {
									color: #c4c4c4;
								}
							}
							.title {
								color: #808080;
								#{$midNightselectors} {
									color: #808080;
								}
							}
							.details {
								.status {
									display: flex;
									flex-direction: column;
									justify-content: center;
									align-items: center;
									width: 46px;
									height: 24px;
									background: #f3f3f3;
									border-radius: 100px;
									font-size: 12px;

									#{$midNightselectors} {
										background: #282727;
									}
								}
								.sent {
									color: $black;
									#{$midNightselectors} {
										color: $white;
									}
								}
								.active {
								}
							}
						}
					}
				}

				.info-container {
					max-width: 488px;
					margin: 0 auto;
					margin-top: 32px;
					padding: 18px 16px;
					border: 1px solid $main-fill-normal;
					border-radius: 15px;

					@media (max-width: 500px) {
						width: 100%;
					}

					#{$midNightselectors} {
						color: $white;
						background: #1a1919;
						border-color: #1a1919;
					}
					.info {
						width: 100%;
						display: flex;
						gap: 7px;
						flex-direction: row;
						align-items: center;
						font-size: 12px;
						color: #808080;

						#{$midNightselectors} {
							color: #808080;
						}
						p {
							margin: 0;
						}
					}
					.content {
						margin: 0;
						margin-top: 18px;
						font-size: 14px;
						#{$midNightselectors} {
							color: #bbbbbb;
						}
					}
				}
			}
		}
	}
}
.participants-data-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	background: $white;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}

	> .content {
		padding-top: 30px;
		width: 100%;
		padding-bottom: 70px;
		.bal {
			margin-top: 5px;
			font-size: 10px;
			#{$modalMidNightselectors} {
				color: $white;
			}
		}
		.split-payment-form {
			margin: 0 auto;
			max-width: 435px;

			label {
				color: $black;
				font-family: TG-Light;
				font-size: 14px;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}

			.form-control {
				height: 50px;
				font-size: 14px;
			}

			.form-group {
				margin-bottom: 0px;
			}

			.split-request-input {
				width: 100%;
				height: 50px;
				font-size: 14px;
				margin-bottom: 0px;

				#{$modalMidNightselectors} {
					background-color: $dark-input !important;
					color: $white !important;
					border-color: $border-medium !important;
				}
			}
			.react-select__control {
				height: 50px;
				outline: none;
				border-radius: 15px !important;

				#{$modalMidNightselectors} {
					border-color: $dark-input;
				}

				input {
					color: $black !important;
					#{$modalMidNightselectors} {
						color: $white !important;
						background-color: $dark-input !important;
					}
				}
			}
			.btn-mint-default {
				border-radius: 15px !important;
				height: 50px !important;
				font-weight: 400;
				margin-top: 10px;
			}

			.split-options {
				display: flex;
				flex-direction: row;
				width: 100%;
				height: 50px;
				border: solid 1px;
				border-radius: 15px;
				border-color: #dfdfdf !important;
				justify-content: space-between;
				align-items: center;
				padding-left: 5px;
				padding-right: 5px;
				#{$modalMidNightselectors} {
					background-color: $dark-input !important;
					color: $white !important;
					border-color: $border-medium !important;
				}
				button {
					padding: 0;
					box-shadow: none !important ;
					border: none;
					border-radius: 9px !important;
					max-width: 229px !important ;
					height: 38px !important;
					margin-top: 0px;
				}
				.in-active {
					background-color: $white;
					color: $black;

					#{$modalMidNightselectors} {
						background-color: $dark-input !important;
						color: $white !important;
					}
				}
			}
		}
	}
}
.request-submitted-modal {
	padding: 20px 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	background: $white;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}

	button {
		margin-bottom: 35px;
		height: 50px;
		border-radius: 15px !important;
		font-weight: 400 !important;
		text-transform: capitalize !important;
		font-weight: 400 !important;
	}

	> .content {
		padding-top: 30px;
		text-align: center;
		max-width: 376px;
		padding-bottom: 42px;

		.icon {
			width: 53.33px;
			height: 53.33px;
			margin: 0 auto;
			padding-top: -30px;
			border-radius: 100px;
			background: rgba(208, 162, 60, 0.1);
			#{$modalMidNightselectors} {
				background: rgba(208, 162, 60, 0.1);
			}
		}
		> h4 {
			margin-top: 30px;
			font-family: TG-Light;
			font-weight: 700;
			font-size: 20px;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}

		> p {
			margin: 0 auto;
			padding-top: 20px;
			max-width: 300px !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 12px;

			#{$modalMidNightselectors} {
				color: $white;
			}

			> span {
				color: #4e4e4e;
				font-weight: 600;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}

		> .sub-text {
			margin: 0 auto;
			max-width: 100% !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}
	}
}
.request-reject-modal {
	padding: 20px 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	background: $white;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}

	button {
		margin-bottom: 25px;
		height: 50px;
		border-radius: 15px !important;
		font-weight: 400 !important;
		text-transform: capitalize !important;
		font-weight: 400 !important;
	}
	.border-button {
		background-color: transparent;
		color: #d1a23c !important ;
		border: solid 1px #d1a23c !important;
		max-width: 440px !important;
		width: 100% !important;
	}
	> .content {
		padding-top: 30px;
		text-align: center;
		max-width: 376px;
		padding-bottom: 42px;

		.icon {
		}

		> h4 {
			margin-top: 30px;
			font-family: TG-Light;
			font-weight: 700;
			font-size: 20px;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}

		> p {
			margin: 0 auto;
			padding-top: 20px;
			max-width: 300px !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 12px;

			#{$modalMidNightselectors} {
				color: $white;
			}

			> span {
				color: #4e4e4e;
				font-weight: 600;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}

		> .sub-text {
			margin: 0 auto;
			max-width: 100% !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}
	}
}

.request-container {
	max-width: 488px;
	margin: 0 auto;
	margin-top: 60px;
	margin-bottom: 60px;

	@media (max-width: 500px) {
		width: 100%;
		margin-top: 40px;
		padding: 0 16px;
	}

	.request-card {
		margin-bottom: 32px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 488px;
		max-height: 148px;
		padding-top: 12px;
		padding-bottom: 12px;
		padding-left: 16px;
		padding-right: 16px;
		border: 1px solid #dfdfdf;
		border-radius: 15px;
		&:hover {
			border-color: $main-fill-normal !important;
		}

		@media (max-width: 500px) {
			width: 100%;
		}

		#{$midNightselectors} {
			color: $white;
			background: #1a1919;
			border-color: #1a1919;
		}
		.pointer {
			cursor: pointer;
		}
		.top {
			width: 100%;
			display: flex;
			align-items: center !important;
			flex-direction: row;
			justify-content: space-between;
			border-bottom: solid 1px #eee;
			padding-bottom: 12px;

			#{$midNightselectors} {
				color: $white;
				border-color: #333333;
			}
			.date {
				margin: 0;
				width: 85px;
				display: flex;
				font-size: 12px;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				#{$midNightselectors} {
					color: #808080;
					border-color: #333333;
				}
			}
			.participants-count {
				display: flex;
				flex-direction: row;
				gap: 4px;
				p {
					margin: 0;
					padding: 0;
					font-size: 12px;
					font-weight: 600;
				}
				.text {
					#{$midNightselectors} {
						color: #808080;
					}
				}
				.count {
					#{$midNightselectors} {
						color: $white;
					}
				}
			}
			.status {
				margin: 0;
				font-size: 12px;
			}
			.sent {
				color: #808080;
			}
			.active {
				color: #d1a23c;
			}
			.completed {
				color: #09bf67;
			}
		}
		> .title-section {
			justify-content: space-between;
			display: flex;
			width: 100%;
			margin: 0;
			margin-top: 12px;

			flex-direction: row;
			font-size: 12px !important;

			#{$midNightselectors} {
				color: $white;
			}
			.title {
				margin: 0;
				font-weight: 600;
			}
			.amt {
				margin: 0;
				font-weight: 600;
				color: #e4af3c;
			}
		}
		.content {
			margin: 0;
			margin-top: 1px;
			font-size: 12px;
			#{$midNightselectors} {
				color: #c4c4c4;
			}
		}
		.footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.sender-section {
				display: flex;
				flex-direction: column;
				width: 100%;
				margin: 0;
				margin-top: 12px;
				font-size: 12px !important;
				p {
					padding: 0;
				}
				.title {
					margin: 0;
					font-weight: 600;
					#{$midNightselectors} {
						color: $white;
					}
				}
				.sender {
					margin: 0;
					margin-top: 1px;
					font-size: 12px;
					#{$midNightselectors} {
						color: #c4c4c4;
					}
				}
			}
			.button-section {
				display: flex;
				flex-direction: row;
				gap: 10px;

				button {
					padding: 0;
					font-size: 12px !important;
					font-weight: 100 !important;
					border-radius: 15px !important;
					width: 93px !important ;
					height: 30px !important;
					text-transform: capitalize !important;
					margin-top: 0px;
				}
				.border-button {
					background-color: transparent;
					color: #d1a23c !important ;
					border: solid 1px #d1a23c !important;
				}
			}
		}
	}
}

.transaction-pin-data-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 300px;
	background: $white;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}

	> .content {
		padding-top: 30px;
		width: 100%;
		padding-bottom: 30px;

		.split-payment-form {
			margin: 0 auto;
			max-width: 435px;

			label {
				color: $black;
				font-family: TG-Light;
				font-size: 14px;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}

			.form-control {
				height: 50px;
				font-size: 14px;
			}

			.form-group {
				margin-bottom: 0px;
			}

			.split-request-input {
				width: 100%;
				height: 50px;
				font-size: 14px;
				margin-bottom: 0px;

				#{$modalMidNightselectors} {
					background-color: $dark-input !important;
					color: $white !important;
					border-color: $border-medium !important;
				}
			}
			.btn-mint-default {
				border-radius: 15px !important;
				height: 50px !important;
				font-weight: 400;
				margin-top: 10px;
			}
			.tip-container {
				font-size: 14px;
				margin: 0 auto;
				margin-bottom: 15px;
				padding: 0 16px;
				#{$modalMidNightselectors} {
					color: $white !important;
				}

				.title {
					margin: 0;
					padding-bottom: 8px;
					font-weight: 400;
					font-size: 16px;
				}
				p {
					span {
						font-family: TG-Semibold;
					}
				}
			}

			.tag-container {
				font-size: 14px;
				margin: 0 auto;
				padding: 0 16px;
				#{$modalMidNightselectors} {
					color: $white !important;
				}

				.title {
					font-weight: 400;
					font-size: 16px;
					margin: 0;
					margin-bottom: 8px;
				}
			}
			.tag-list {
				margin-bottom: 32px;
				width: 100%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				gap: 7px;

				.tag-card {
					display: flex;
					cursor: pointer;
					margin-bottom: 2px;
					flex-wrap: wrap;
					flex-direction: column;
					font-size: 12px;
					justify-content: center;
					align-items: center;
					height: 40px;
					width: 92px;
					border: 1px solid #dfdfdf;
					border-radius: 15px;

					#{$modalMidNightselectors} {
						color: $white !important;
						background: #282727;
						border-radius: 8px;
						border-color: #282727 !important;
					}
					&:hover {
						border-color: $main-fill-normal !important;
					}
				}
			}
		}
	}
}
.loading-requests {
	height: 467px;
	flex-direction: column;
}
.split-payment-modal {
	display: flex;
	flex-direction: column;
	min-height: 500px;
	width: 100%;
	max-width: 433px;
	padding-bottom: 32px;
	margin: 0 auto;
	justify-content: space-between;

	.content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin: 0 auto;
		margin-top: 24px;
		padding: 0 16px;

		border-bottom: 1px solid #dfdfdf;
		padding-bottom: 25px;

		#{$modalMidNightselectors} {
			color: $white;
			border-bottom: 0.2px solid #333333;
		}
		p {
			margin: 0;
			font-size: 18px;
			font-weight: 400;
		}
	}
	button {
		width: 100%;
		margin-top: 32px;
		border-radius: 15px;
		height: 50px;
		text-transform: none !important;
	}
}

.request-accepted-modal {
	display: flex;
	flex-direction: column;
	min-height: 200px;
	width: 100%;
	max-width: 433px;
	margin: 0 auto;

	padding: 20px 60px;
	align-items: center;
	background: $white;

	#{$modalMidNightselectors} {
		background-color: #111111 !important;
	}
	> .content {
		padding-top: 30px;
		text-align: center;

		.icon {
			width: 53.33px;
			height: 53.33px;
			margin: 0 auto;
			padding-top: -30px;
			border-radius: 100px;
			background: rgba(208, 162, 60, 0.1);
			#{$modalMidNightselectors} {
				background: rgba(208, 162, 60, 0.1);
			}
		}
		> h4 {
			margin-top: 30px;
			font-family: TG-Light;
			font-weight: 700;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: $white;
			}
		}

		> p {
			margin: 0 auto;
			padding-top: 0px;
			max-width: 308px !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 10px;

			#{$modalMidNightselectors} {
				color: $white;
			}

			> span {
				color: #4e4e4e;
				font-weight: 600;

				#{$modalMidNightselectors} {
					color: $white;
				}
			}
		}

		> .sub-text {
			margin: 0 auto;
			max-width: 100% !important;
			font-family: TG-Light;
			font-weight: 400;
			font-size: 14px;

			#{$modalMidNightselectors} {
				color: #c4c4c4;
			}
		}
	}
	.content-data {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 304px;
		margin-top: 20px;
		padding: 0 16px;

		border-bottom: 1px solid #dfdfdf;
		padding-bottom: 12px;

		#{$modalMidNightselectors} {
			color: $white;
			border-bottom: 0.2px solid #333333;
		}
		p {
			margin: 0;
			font-size: 12px;
		}
	}
	button {
		width: 304px !important;
		margin-bottom: 30px;
		margin-top: 10px;
		border-radius: 15px !important;
		height: 50px;
		text-transform: none !important;
		font-weight: 400 !important;
	}
}

.range-container {
	width: 100%;
	height: 5px !important;
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	p {
		margin: 0;
		padding: 0;
		font-size: 8px;
		font-weight: 700;
		#{$midNightselectors} {
			color: $white !important;
		}
	}
	.range {
		max-width: 337px;
		width: 100%;
		height: 5px !important;
		border-radius: 100px;

		#{$midNightselectors} {
			background-color: #1a1919 !important;
			color: $white !important;
			border-color: $border-medium !important;
		}
	}
	.range-inner {
		margin: 0;
		max-width: 337px;
		padding: 0;
		height: 100%;
		background-color: #d1a23c;
		border-radius: 100px !important;
	}
}
