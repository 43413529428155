@import "../../../../../../assets/scss/master";

.boxxie {
	/* width: 362px; */
	min-height: 440px;
	border: 2px solid #e8f1f8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	border-radius: 6px;
	.midnight & {
		border-color: #4f4b4c;
	}
}
.seriesA {
	padding: 40px 10px;
	padding-top: 20px;

	.text-center {
		h6 {
			font-weight: 100;
			font-family: "TG-Medium";
			font-size: 18px;
			letter-spacing: 0.25px;
			color: #37474f;
			margin-bottom: 6px;
			.midnight & {
				color: $white;
			}
		}

		p {
			font-size: 13px;
			line-height: 21px;
			color: #37474f;
			text-transform: capitalize;
			letter-spacing: 0.25px;
			.midnight & {
				color: $white;
			}
		}
	}
}

.small-talk {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	svg circle {
		.midnight & {
			fill: #4f4b4c;
		}
	}

	img {
		display: block;
		margin: auto;
	}

	h6 {
		font-family: "TG-Medium";
		font-weight: 100;
		font-size: 14px;
		line-height: 21px;
		text-align: center;
		color: #37474f;
		text-transform: capitalize;
		margin: 10px 0;
		.midnight & {
			color: $white;
		}
	}

	p {
		font-size: 13px;
		line-height: 21px;
		text-align: center;
		letter-spacing: 0.02em;
		color: #69747a;
		.midnight & {
			color: $white;
		}
	}
}

.small-tool {
	background: transparent;
	// box-shadow: 0px 4px 4px rgb(232, 241, 248);
	border: 0.5px solid $main-fill-normal;
	color: $main-fill-normal;
	font-size: 12px;
	text-align: center;
	padding: 5px;
	position: absolute;
	top: -12px;
	left: 60px;
	z-index: 1;
	margin-left: auto;
	margin-right: auto;
	width: 117px;
	animation: pop 0.5s linear 1;
}

.small-form {
	height: 80px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 0px 16px !important;

	.input-group {
		height: 48px;
		tinapp &.bordered {
			background: transparent;
			border: 1px solid $main-fill-normal;
			border-style: dashed;
			border-radius: $border-radius;
			overflow: hidden;
		}
	}

	label,
	label.mkTell {
		font-weight: 100;
		font-size: 11px;
		font-family: "TG-Medium";
		line-height: 15px;
		color: #37474f;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		display: block;
		.midnight & {
			color: $white;
		}
	}

	input.bordered {
		height: 48px;
		border: 0px;
		background-color: transparent;
		color: $main-fill-normal;
		border-radius: 0px;
		outline: none;
		box-shadow: none;
		text-align: center;
	}

	.card-btn {
		background: $main-fill-normal;
		text-align: center;
		border-radius: $border-radius;
		height: 48px;
		width: 100%;
		min-width: 150px;
		outline: none;
		box-shadow: none;
		font-weight: 100;
		font-family: "TG-Medium";
		font-size: 14px;
		line-height: 21px;
		letter-spacing: 0.02em;
		color: $white !important;
	}
}

@keyframes pop {
	50% {
		transform: scale(1.2);
	}
}

.flexxxxx {
	display: flex !important;
	margin: 0 !important;
	flex-wrap: nowrap !important;
	align-items: center !important;
	justify-content: center !important;

	input {
		width: fit-content !important;
		padding: 0px !important;
		width: 95px !important;
	}

	button {
		flex-shrink: 0 !important;
		padding: 0px !important;
		width: fit-content !important;
		height: fit-content !important;
	}
}
