@import "../../../../../../../assets/scss/master";

.lt-dashboard {
	max-height: 492.66px;
}

.transcations-card {
	border-radius: 0px 0px 3px 3px;
	background: $white;
	max-height: calc(100% - 75px);
	overflow-y: scroll;
	#{$midNightselectors} {
		background: $black-medium;
	}
}

.transactions-card-header {
	height: 75px !important;
	margin-bottom: 0px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	border-bottom: 1px solid #eee;
	background: $white;
	border-radius: 3px 3px 0px 0px;
	padding-top: 16px;
	padding-bottom: 16px;
	#{$midNightselectors} {
		background: $black-medium;
		border-bottom: 1px solid #333;
	}

	> h4 {
		color: var(--main-fill-normal) !important;
	}
}
